import { Close } from "@mui/icons-material";
import { Link } from "@mui/material";
import { Box, Drawer, Select, Slider, styled, TextField } from "@mui/material";

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.surface.bg,
    color: theme.palette.text.primary,
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
}));

export const StyledSearchBarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "19px 25px",
  backgroundColor: theme.palette.surface.container,
}));

export const StyledCloseButton = styled(Close)(({ theme }) => ({
  cursor: "pointer",
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.surface.container,
  borderRadius: "12px",
  width: "100%",

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "& .MuiInputBase-input": {
    padding: "8px",
    ...theme.typography["body-md"],

    "&::placeholder": {
      color: theme.palette.text.secondary,
    },
  },

  "& .MuiSvgIcon-root": {
    color: theme.palette.text.secondary,
  },
}));

export const StyledSelect = styled(Select)<{ noPointer?: boolean }>(({ theme, noPointer }) => ({
  pointerEvents: noPointer ? "none" : "auto",
  borderRadius: theme.spacing(1.5),
  overflow: "hidden",

  ".MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.surface.silver,
    pointerEvents: noPointer ? "none" : "auto",
    width: "100%",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: theme.spacing(1.5),
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.border.dark}`,
    },
  },
}));

export const StyledCallItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
  border: `1px solid ${theme.palette.border.dark}`,
  borderRadius: "18px",
  backgroundColor: theme.palette.surface.silver,
}));

export const StyledLink = styled(Link)(({ theme }) => ({
  all: "unset",
  cursor: "pointer",
  maxWidth: "100%",
  width: "100%",
}));
