import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loader-cabal_town.json";

import { ReactNode } from "react";
import { LoadingViewContainer, LoadingViewType } from "./styled";
import { Box } from "@mui/material";

export interface LoadingScreenProps {
  isLoading: boolean;
  children: ReactNode;
}

export const LoadingScreen = ({ isLoading, children }: LoadingScreenProps) => {
  return (
    <ConditionalSuspense
      condition={isLoading}
      fallback={<LoadingView variant="full-screen" isLoading={true} />}>
      {children}
    </ConditionalSuspense>
  );
};

interface ConditionalSuspenseProps {
  condition: boolean;
  children: ReactNode;
  fallback: ReactNode;
}

const ConditionalSuspense = ({ condition, children, fallback }: ConditionalSuspenseProps) => {
  return condition ? <>{children}</> : <>{fallback}</>;
};

export const LoadingView = ({
  variant = "overlay",
  isLoading,
  children,
}: {
  variant?: LoadingViewType;
  isLoading: boolean;
  children?: ReactNode;
}) => {
  return isLoading ? (
    <LoadingViewContainer $variant={variant}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "136px",
          height: "99px",
        }}>
        <LoadingLottie />
      </Box>
      {children}
    </LoadingViewContainer>
  ) : (
    <></>
  );
};

export const LoadingLottie = () => {
  return <Lottie animationData={loadingAnimation} />;
};

export const LoadingBlock = ({
  isLoading,
  children,
}: {
  isLoading: boolean;
  children: ReactNode;
}) => {
  return isLoading ? (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "20px",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "136px",
          height: "99px",
        }}>
        <LoadingLottie />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "-8px",
        }}>
        {children}
      </Box>
    </Box>
  ) : null;
};
