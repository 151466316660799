import { Box, Typography, useTheme } from "@mui/material";
import { CabalGroupRowContainer, OwnerImage, PerformanceHeaderText, StyledLink } from "./styled";
import { Jetton } from "types";
import { PercentageBlock } from "components/PercentageBrick";
import OwnerBadgeIcon from "assets/icons/owner-badge.png";
import DefaultAvatarIcon from "assets/icons/default-avatar.png";
import GroupImg from "assets/icons/group.svg";
import { ReactComponent as GroupIcon } from "assets/icons/group.svg";
import { useCallback } from "react";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { StyledImg } from "pages/jetton/dataRow/trade/styled";

type CabalGroupRowProps = {
  jetton: Jetton;
};

const CabalGroupHeader = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: "10px",
      }}>
      {/* NOTE: Align the flex col ratio */}
      <Box
        sx={{
          minWidth: {
            xs: "100px",
            sm: "100px",
            "cabal-list-sm": "180px",
          },
          display: "flex",
          gap: theme.spacing(1.5),
          alignItems: "center",
        }}>
        <Typography
          variant="pre-title-2"
          sx={{
            width: {
              xs: "100px",
              sm: "180px",
              "cabal-list-sm": "140px",
            },
          }}>
          Cabal Owner
        </Typography>
        <Box
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            width: {
              xs: "24px",
              "cabal-list-sm": "48px",
            },
          }}
        />
      </Box>
      <Typography
        variant="pre-title-2"
        sx={{
          width: "40px",
        }}>
        Win %
      </Typography>
      <PerformanceHeaderText variant="pre-title-2">Performance</PerformanceHeaderText>
    </Box>
  );
};

const CabalGroupRow = ({ jetton }: CabalGroupRowProps) => {
  const {
    totalCalls,
    totalWins,
    day1Performance,
    tgGroupId,
    imageUri,
    name,
    isUserOwned,
    isUserJoined,
  } = jetton;
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();
  const handleOnError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = DefaultAvatarIcon;
  };

  const navigateToCabalDetailPage = useCallback(() => {
    navigate(`/cabal/${tgGroupId}`);
  }, [tgGroupId]);

  return (
    <StyledLink onClick={navigateToCabalDetailPage}>
      <CabalGroupRowContainer>
        <Box
          sx={{
            minWidth: {
              xs: "100px",
              "cabal-list-sm": "180px",
            },
            display: "flex",
            gap: theme.spacing(1.5),
            alignItems: "center",
          }}>
          <Box position="relative" display="flex" alignItems="center">
            <OwnerImage
              sx={{ width: "32px", height: "32px" }}
              src={imageUri}
              alt={name}
              onError={handleOnError}
            />
            {isUserOwned || isUserJoined ? (
              <img
                src={OwnerBadgeIcon}
                alt="owner"
                style={{
                  width: "16px",
                  height: "16px",
                  position: "absolute",
                  top: "-4px",
                  right: "-8px",
                }}
              />
            ) : null}
          </Box>
          <Typography
            variant="subtitle"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: {
                xs: "100px",
                "cabal-list-sm": "180px",
              },
            }}>
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "32px",
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.spacing(1),
            justifyContent: "flex-end",
          }}>
          <Typography variant="body-default" color={theme.palette.text.secondary}>
            {totalWins}/{totalCalls}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "120px",
            display: "flex",
            justifyContent: "flex-end",
          }}>
          <PercentageBlock percentage={day1Performance} />
        </Box>
      </CabalGroupRowContainer>
    </StyledLink>
  );
};

const CabalGroupRowV2 = ({ jetton }: CabalGroupRowProps) => {
  const { totalCalls, totalWins, day7Performance, tgGroupId, imageUri, name } = jetton;
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();
  const handleOnError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = DefaultAvatarIcon;
  };

  const navigateToCabalDetailPage = useCallback(() => {
    navigate(`/cabal/${tgGroupId}`);
  }, [tgGroupId]);

  return (
    <StyledLink onClick={navigateToCabalDetailPage}>
      <CabalGroupRowContainer>
        <Box
          display="flex"
          gap="12px"
          justifyContent="space-between"
          alignItems="center"
          maxWidth="66%">
          <Box position="relative" display="flex" paddingY="4px" width="34px">
            <OwnerImage
              sx={{ width: "34px", height: "34px" }}
              src={imageUri}
              alt={name}
              onError={handleOnError}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            maxWidth="100%"
            overflow="hidden">
            <Box
              sx={{
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                alignItems: "center",
                display: "flex",
              }}>
              <GroupIcon style={{ paddingRight: "6px" }} />
              <Typography
                variant="subtitle"
                sx={{
                  maxWidth: "100%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}>
                {name}
              </Typography>
            </Box>
            <Typography variant="body-sm">
              {totalCalls === 0 ? "--" : Math.round((+totalWins * 100) / +totalCalls)}% win on alpha
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              alignItems: "center",
              justifyContent: "center",
              whiteSpace: "nowrap",
              padding: "4px",
              border: `1px solid ${theme.palette.border.dark}`,
              borderRadius: "8px",
              backgroundColor: theme.palette.surface.container,
              width: "fit-content",
            }}>
            <Typography variant="body-sm">7-day Gain</Typography>
            <PercentageBlock percentage={day7Performance} />
          </Box>
        </Box>
      </CabalGroupRowContainer>
    </StyledLink>
  );
};

export { CabalGroupRow, CabalGroupRowV2, CabalGroupHeader };
