import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { FullPageHeader } from "components/Fullpage";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { CHAIN_CHECK_LIST, ROUTES } from "consts";
import { useParams } from "react-router-dom";
import { RetroCard } from "components/RetroCard";
import { useCallback, useEffect, useRef, useState } from "react";
import { RetroButton } from "components/RetroButton";
import { PercentageBrick } from "components/PercentageBrick";
import { ContentCard } from "components/ContentCard";
import axiosService from "services/axios";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GenericEmptyView } from "components/EmptyView";

import SolanaIcon from "assets/icons/chains/solana.png";
import EthereumIcon from "assets/icons/chains/ethereum.png";
import TonIcon from "assets/icons/chains/ton.png";
import BaseIcon from "assets/icons/chains/base.png";
import BeraIcon from "assets/icons/chains/bera.png";
import CoinAvatar from "components/CoinAvatar";
import {
  formatDistanceStrict,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfWeek,
} from "date-fns";
import { Chain, Symbol, TokenPrice } from "types";
import { TokenDetailChart } from "components/Chart/TokenDetailChart";
import { useWsPriceData } from "./hook";
import { CrossChainDepositModal } from "components/Deposit";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { WalletAddresses } from "store/user-store-v2";
import { abbreviateNumber, copyToClipboard, toFixedIfNecessary } from "utils";
import FullPageWrapper from "components/FullPageWrapper";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import useToastStore from "store/toast-store/useToastStore";
import { Cached, Share, Telegram } from "@mui/icons-material";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { CrossChainTradeDrawer } from "components/CrossChainSwapDrawer";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { NATIVE_TOKEN_ADDRESS_MAP, TradeProvider, useTradeContext } from "contexts/TradeContext";
import { LoadingBlock, LoadingView } from "components/LoadingScreen";
import { checkIsMobile, getTgShareLinkWithParams } from "lib/utils";

const CHAIN_BADGE_MAP: Record<string, string> = {
  solana: SolanaIcon,
  ethereum: EthereumIcon,
  ton: TonIcon,
  base: BaseIcon,
  berachain: BeraIcon,
};

const CHAIN_NATIVE_TOKEN_MAP: Record<string, string> = {
  solana: "SOL",
  ethereum: "ETH",
  ton: "TON",
  base: "BASE",
  berachain: "BERA",
};

const CHAIN_WALLET_MAP: Record<string, keyof WalletAddresses> = {
  solana: "solAddress",
  ethereum: "ethAddress",
  ton: "tonAddress",
  base: "ethAddress",
  berachain: "ethAddress",
};

const groupingFunction = {
  minute: startOfMinute,
  hour: startOfHour,
  day: startOfDay,
  week: startOfWeek,
};

const TokenDetailsRenderer = ({
  id,
  tokenData,
}: {
  id: string;
  tokenData: any | undefined;
  chain: string;
}) => {
  const { search, state: navStates } = useLocation();
  const groupId = new URLSearchParams(search).get("groupId");
  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);
  const [timeInterval, setTimeInterval] = useState<"minute" | "hour" | "day" | "week">("hour");
  const [priceDataConsolidated, setPriceDataConsolidated] = useState<TokenPrice[]>([]);
  const [isCrossChainDepositModalOpen, setIsCrossChainDepositModalOpen] = useState(false);
  const [isReadyToTrade, setIsReadyToTrade] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [tempBalanceData, setTempBalanceData] = useState<any | null>(null);
  const [latestPrice, setLatestPrice] = useState<TokenPrice | null>(null);
  const [isLoadedBalance, setIsLoadedBalance] = useState(false);
  const [isCreatingWallet, setIsCreatingWallet] = useState(false);

  const queryClient = useQueryClient();

  const { walletAddresses, tgUserId, tgUserName, getTgUserIdV2, createWallet, hasWallet } =
    useUserStoreV2();

  const { isTrading } = useTradeContext();

  const { getUserBalance, updateUserBalance } = useTradeStore();

  const { showToast } = useToastStore();

  const priceDataRef = useRef<TokenPrice[]>([]);

  const isTradeInitingRef = useRef(false);

  const {
    data: priceData,
    isLoading: isPriceDataLoading,
    isSuccess: isPriceDataSuccess,
  } = useQuery({
    queryKey: ["token-price", { id, chain: tokenData?.chain!, timeInterval }],
    queryFn: () =>
      axiosService.getCabalCallsPrice({
        pairAddress: tokenData?.tokenAddress!,
        chain: tokenData?.chain!,
        interval: timeInterval,
      }),
    enabled: !!tokenData?.chain,
  });

  const { data: tokenNativePrice } = useQuery({
    queryKey: ["tokenNativePrice"],
    queryFn: () =>
      axiosService.getNativePrice({
        tokenAddress: tokenData?.tokenAddress!,
        chain: tokenData?.chain!,
      }),
    enabled: !!tokenData?.tokenAddress && !!tokenData?.chain,
  });

  const {
    data: balanceData,
    isLoading: isBalanceLoading,
    isRefetching: isBalanceRefetching,
    refetch: refetchBalanceData,
  } = useQuery({
    queryKey: ["token-balance", id, hasWallet],
    queryFn: () => {
      if (!id || !tokenData) return;
      if (!hasWallet || !walletAddresses) {
        setIsLoadedBalance(true);
        return;
      }

      const result = axiosService.getTokenBalance({
        tokenAddress: id,
        walletAddress: walletAddresses[CHAIN_WALLET_MAP[tokenData!.chain as Chain]]!,
        chain: tokenData.chain,
      });
      setIsLoadedBalance(true);
      return result;
    },
    enabled: !!id && !!tokenData,
  });

  // TEMP FIX to also invalidate data in the trade logic
  const refetchBalance = () => {
    queryClient.invalidateQueries({ queryKey: ["token-balance"] });
  };

  useEffect(() => {
    if (balanceData) {
      setTempBalanceData({ ...balanceData });
    }
  }, [balanceData]);

  useEffect(() => {
    if (tokenData && isLoadedBalance) {
      setIsReadyToTrade(true);
    } else {
      setIsReadyToTrade(false);
    }
  }, [tokenData, isLoadedBalance]);

  useEffect(() => {
    if (priceData && priceData.length > 0) {
      priceDataRef.current = [];
      setPriceDataConsolidated([]);
      const groupedData: TokenPrice[] = priceData.reduce((acc: TokenPrice[], cur: TokenPrice) => {
        // 1st data price is null, ignore
        if (!acc.length && cur.price === null) {
          return acc;
        }

        // 1st data
        let roundedTime = groupingFunction[timeInterval](new Date(cur.updatedAt)).toISOString();
        if (!acc.length) {
          acc.push({
            updatedAt: roundedTime,
            price: cur.price,
          } as TokenPrice);
          return acc;
        }

        const latestData = acc[acc.length - 1];

        if (cur.price === null) {
          roundedTime = groupingFunction[timeInterval](latestData.updatedAt).toISOString();
        } else {
          roundedTime = groupingFunction[timeInterval](cur.updatedAt).toISOString();
        }

        const roundedTimeData = {
          updatedAt: roundedTime,
          price: cur.price,
        } as TokenPrice;

        if (latestData.updatedAt === roundedTime) {
          acc[acc.length - 1] = roundedTimeData;
          acc = [...acc];
        } else {
          acc = [...acc, roundedTimeData];
        }

        return acc;
      }, []);

      priceDataRef.current = groupedData;
      setPriceDataConsolidated(groupedData);
    }
  }, [priceData, timeInterval]);

  const transformPriceData = (data: TokenPrice) => {
    return {
      time: new Date(data.updatedAt).getTime(),
      value: data.price,
    };
  };

  const handleOnPriceDataV2 = useCallback(
    (priceData: TokenPrice) => {
      setLatestPrice(priceData);

      let roundedTime: string;
      const fullPriceData = priceDataRef.current;
      const latestData = fullPriceData[fullPriceData.length - 1];

      if (!latestData || !priceDataRef.current.length) {
        return null;
      }

      if (priceData.price === null) {
        roundedTime = groupingFunction[timeInterval](latestData.updatedAt).toISOString();
      } else {
        roundedTime = groupingFunction[timeInterval](priceData.updatedAt).toISOString();
      }

      const roundedTimeData = {
        updatedAt: roundedTime,
        price: priceData.price,
      };

      if (latestData.updatedAt === roundedTime) {
        setPriceDataConsolidated((prev) => {
          prev[prev.length - 1] = roundedTimeData;
          priceDataRef.current = [...prev];
          return [...prev];
        });
      } else {
        setPriceDataConsolidated((prev) => {
          priceDataRef.current = [...prev, roundedTimeData];
          return [...prev, roundedTimeData];
        });
      }
    },
    [timeInterval],
  );

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        await getTgUserIdV2();
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  useWsPriceData({
    chain: tokenData?.chain!,
    masterAddress: tokenData?.tokenAddress!,
    onPriceData: handleOnPriceDataV2,
  });

  const theme = useTheme();

  return (
    <>
      <RetroCard>
        <Box
          sx={{
            padding: "20px 20px 0",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: "21px",
              }}>
              <Box sx={{ width: "fit-content" }}>
                <CoinAvatar iconUri={tokenData.imageUrl} chain={tokenData.chain as Chain} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}>
                <Typography variant="pre-title" color="text.secondary">
                  {tokenData.chain?.toUpperCase()}
                </Typography>
                <Typography variant="title-h3">{tokenData.symbol}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "42px",
                width: "40px",
              }}>
              <RetroButton
                onClick={async () => {
                  const shareLink = getTgShareLinkWithParams({
                    tokenAddress: tokenData.tokenAddress,
                    chain: tokenData.chain,
                    ...(groupId ? { groupId: groupId } : {}),
                  });

                  const isMobile = checkIsMobile();
                  if (!!navigator.share && isMobile) {
                    try {
                      await navigator.share({
                        url: shareLink,
                      });
                    } catch (e) {
                      console.error("Error sharing the link", e);
                    }
                  } else {
                    copyToClipboard(shareLink);
                    showToast({
                      variant: "success",
                      message: "Copied shared link to clipboard.",
                      duration: 3000,
                    });
                  }
                }}>
                <Share />
              </RetroButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "13px",
              padding: "12px 0 12px",
            }}>
            <Typography variant="display-sm">
              {latestPrice && latestPrice.price !== null
                ? `$${toFixedIfNecessary(latestPrice.price.toString(), 6)}`
                : tokenNativePrice && tokenNativePrice.price
                ? `$${toFixedIfNecessary(tokenNativePrice.price, 6)}`
                : "Loading..."}
            </Typography>
          </Box>
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            padding: theme.spacing(2.5),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <Typography variant="title-h3">Chart</Typography>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "6/5",
              borderRadius: theme.spacing(3),
              border: `1px solid ${theme.palette.border.dark}`,
              overflow: "hidden",
            }}>
            <Box
              sx={{
                width: "100%",
                aspectRatio: "6/5",
                overflow: "hidden",
              }}>
              {isPriceDataSuccess && !isPriceDataLoading && priceDataConsolidated.length > 0 ? (
                <TokenDetailChart data={priceDataConsolidated.map(transformPriceData)} />
              ) : isPriceDataLoading ? (
                <LoadingBlock
                  isLoading={
                    isPriceDataSuccess &&
                    !isPriceDataLoading &&
                    priceDataConsolidated.length > 0 &&
                    isPriceDataLoading
                  }>
                  <Typography variant="title-h2">Loading...</Typography>
                </LoadingBlock>
              ) : (
                <Box height={"100%"}>
                  <Box
                    padding={theme.spacing(2)}
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <LogoIcon />
                    <Box
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginTop: "24px",
                      }}>
                      <Typography variant="title-h2">No data yet</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              width: "100%",
              justifyContent: "center",
            }}>
            {[
              { label: "1m", value: "minute" },
              { label: "1h", value: "hour" },
              { label: "1d", value: "day" },
            ].map((item) => (
              <RetroButton
                size="sm"
                onClick={() => setTimeInterval(item.value as "minute" | "hour" | "day" | "week")}
                disabled={timeInterval === item.value}>
                <Box paddingX={2}>
                  <Typography variant="body-default">{item.label}</Typography>
                </Box>
              </RetroButton>
            ))}
          </Box>
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            padding: theme.spacing(2.5),
          }}>
          <Box display={"flex"} alignItems={"center"} gap={theme.spacing(1)}>
            <Typography variant="title-h3">Your {tokenData.symbol} holding</Typography>
            <Cached
              onClick={() => refetchBalance()}
              sx={{
                cursor: "pointer",
                ...((isBalanceLoading || isBalanceRefetching) && {
                  animation: "spin 2s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>

          <ContentCard title="USD Value" sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
              <Typography variant="title-h2">
                <SmallNumberView value={balanceData?.usdValue ?? 0} variants="title-h2" isUSD />
              </Typography>
              {!balanceData?.quantity ||
              balanceData?.quantity === 0 ||
              balanceData?.usdValue < 0.01 ? null : (
                <PercentageBrick percentage={balanceData?.performance ?? 0} />
              )}
            </Box>
          </ContentCard>
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Quantity" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {isBalanceLoading
                      ? 0
                      : balanceData?.quantity
                      ? balanceData?.quantity.toFixed(2)
                      : 0}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="P&L" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {balanceData?.profit?.toFixed(2) ?? 0}
                  </span>
                  <span style={{ marginLeft: "4px" }}>USD</span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            padding: theme.spacing(2.5),
          }}>
          <Typography variant="title-h3">More Info</Typography>

          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Network" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                <img src={CHAIN_BADGE_MAP[tokenData.chain as Chain]} alt={tokenData.chain} />
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {tokenData.chain}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="Market Cap" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {abbreviateNumber(tokenData.marketCap ?? 0)}
                  </span>
                  <span style={{ marginLeft: "4px" }}>USD</span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>

          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Holders" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {tokenData.holders ?? "Loading..."}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="Created At" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {formatDistanceStrict(tokenData.pairCreatedAt, new Date(), {
                      addSuffix: true,
                    })}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>
      <Box height="100px" />

      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 3,
          paddingBottom: theme.spacing(1),
        }}>
        <RetroCard narrowOuter size="sm">
          <Box
            sx={{
              padding: theme.spacing(1, 1.5),
              height: "80px",
              display: "flex",
              gap: theme.spacing(1),
            }}>
            {!tgUserId || !tgUserName ? (
              <RetroButton onClick={handleLogin}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: `${theme.spacing(1)}`,
                  }}>
                  <Telegram />
                  <Typography variant="title-h3">LOGIN</Typography>
                </Box>
              </RetroButton>
            ) : (
              <RetroButton
                disabled={isTrading || !isReadyToTrade || isProcessing || isCreatingWallet}
                onClick={async () => {
                  try {
                    if (isProcessing || isTradeInitingRef.current) return;

                    setIsProcessing(true);
                    isTradeInitingRef.current = true;

                    if (!hasWallet) {
                      setIsCreatingWallet(true);
                      await createWallet();
                      setIsCreatingWallet(false);
                    }

                    setShouldTradeDrawerOpen(true);
                    const { amount } = (await updateUserBalance()) ?? {};

                    const isAllBalanceEmpty =
                      amount &&
                      CHAIN_CHECK_LIST.every(
                        (key) =>
                          !amount[key as keyof typeof amount] ||
                          amount[key as keyof typeof amount] <= 0,
                      );

                    if (!hasWallet || isAllBalanceEmpty) {
                      updateUserBalance();
                      setShouldTradeDrawerOpen(false);
                      setIsCrossChainDepositModalOpen(true);
                    }
                  } catch (error) {
                    console.error("Error while initing trade drawer", error);
                  } finally {
                    if (isCreatingWallet) {
                      setIsCreatingWallet(false);
                    }
                    setIsProcessing(false);
                    isTradeInitingRef.current = false;
                  }
                }}>
                <Typography variant="title-h3">Trade</Typography>
              </RetroButton>
            )}
          </Box>
        </RetroCard>
      </Box>
      <CrossChainTradeDrawer
        open={shouldTradeDrawerOpen}
        groupId={parseInt(groupId ?? "0")}
        chain={tokenData.chain as Chain}
        isFromTrendingCall={navStates?.isFromTrendingCall}
        onConfirm={() => {
          setShouldTradeDrawerOpen(false);
        }}
        onFail={() => {
          setIsProcessing(false);
          setShouldTradeDrawerOpen(false);
        }}
        onProcessing={() => {
          setIsProcessing(true);
        }}
        onProcessed={() => {
          refetchBalanceData();
          setIsProcessing(false);
        }}
        onClose={() => {
          setIsReadyToTrade(true);
          setShouldTradeDrawerOpen(false);
          refetchBalanceData();
        }}
      />
      <CrossChainDepositModal
        defaultChain={tokenData.chain as Chain}
        chains={CHAIN_CHECK_LIST}
        isOpen={isCrossChainDepositModalOpen}
        close={() => setIsCrossChainDepositModalOpen(false)}
        onCopySuccess={() => {
          setIsCrossChainDepositModalOpen(false);
          showToast({
            variant: "success",
            message: "Copied address to clipboard",
            duration: 3000,
          });
        }}
      />
    </>
  );
};

const TokenIdPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const { search } = useLocation();
  const chain = new URLSearchParams(search).get("chain");
  const isFromTrade = new URLSearchParams(search).get("from") === "trade";
  const { showToast } = useToastStore();
  const navigate = useNavigate();

  const { data: tokenData, isLoading } = useQuery({
    queryKey: ["token", id],
    queryFn: () => axiosService.getToken({ tokenAddress: id!, chain: chain as Chain }),
    enabled: !!id,
  });

  if (!chain) {
    showToast({
      variant: "error",
      message: "Chain is required for token detail page.",
      duration: 3000,
    });
    navigate(ROUTES.call);
    return <></>;
  }

  return (
    <FullPageWrapper>
      <FullPageHeader>
        <Container>
          <Button
            sx={{ padding: "0", cursor: "pointer" }}
            onClick={isFromTrade ? () => navigate(ROUTES.trade) : () => navigate(ROUTES.call)}>
            <BackArrow />
            <Typography variant="button-md" color="text.secondary">
              {isFromTrade ? "Token Trade" : "Alpha"}
            </Typography>
          </Button>
        </Container>
      </FullPageHeader>
      <Container sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
        {isLoading ? (
          <LoadingView isLoading={isLoading}>
            <Typography variant="title-h2">Loading...</Typography>
          </LoadingView>
        ) : !tokenData ? (
          <GenericEmptyView />
        ) : id && chain ? (
          <TradeProvider
            chain={chain as Chain}
            nativeTokenConfig={{
              solana: {
                chain: "solana",
                symbol: "SOL",
                token: NATIVE_TOKEN_ADDRESS_MAP["solana"],
              },
              ethereum: {
                chain: "ethereum",
                symbol: "ETH",
                token: NATIVE_TOKEN_ADDRESS_MAP["ethereum"],
              },
              base: {
                chain: "base",
                symbol: "ETH",
                token: NATIVE_TOKEN_ADDRESS_MAP["base"],
              },
              berachain: {
                chain: "berachain",
                symbol: "BERA",
                token: NATIVE_TOKEN_ADDRESS_MAP["berachain"],
              },
            }}
            targetTokenConfig={{
              [chain]: {
                chain: tokenData?.chain! as Chain,
                symbol: tokenData?.symbol!,
                token: tokenData?.tokenAddress!,
                imageUrl: tokenData?.imageUrl!,
              },
            }}>
            <TokenDetailsRenderer id={id} tokenData={tokenData} chain={chain} />
          </TradeProvider>
        ) : (
          <></>
        )}
      </Container>
    </FullPageWrapper>
  );
};

export { TokenIdPage };
