import axios from "axios";
import { getAccessToken } from "./auth";
// import { getAccessToken, refreshAccessToken, onRefreshed, addRefreshSubscriber } from "./auth";

let isRefreshing = false;

axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    // console.debug("Request", config, token);
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // console.debug("Error response", originalRequest, error.response, error.response.status);

    // if (!originalRequest._retry) {
    //   if (error.response.status === 401) {
    //     if (window.localStorage.getItem("logged_in_user")) {
    //       window.localStorage.removeItem("logged_in_user");
    //     }
    //     return axiosInstance(originalRequest)
    //   } else {
    //     return axiosInstance(originalRequest)
    //   }
    // }

    // console.log(originalRequest._retry);

    // return Promise.reject(error);
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;
        try {
          isRefreshing = false;
          return axiosInstance(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          return Promise.reject(refreshError);
        }
      } else {
        return axiosInstance(originalRequest);
      }
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
