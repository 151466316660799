import { Box, styled } from "@mui/material";

export const TooltipTrigger = styled(Box)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "end",
  border: `1px solid transparent`,
  cursor: "pointer",
}));
