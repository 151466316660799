import React, { forwardRef, useRef, useState, ChangeEvent, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import {
  CabalIcon,
  CreateCabalButtonWrapper,
  Curve,
  CurveSteepnessHeaderWrapper,
  CurveSteepnessOption,
  CurveSteepnessOptionRadio,
  CurveSteepnessOptionRadioContainer,
  CurveSteepnessOptionsWrapper,
  CurveSteepnessOptionText,
  HeaderLogo,
  StyledInput,
  StyledLabel,
  StyledOption,
  StyledSelect,
  UploadButtonWrapper,
  UploadImageContainer,
  ViewButtonWrapper,
} from "./styles";
import { RetroButton } from "components/RetroButton";
import { ReactComponent as ImageIcon } from "../../assets/icons/image.svg";
import { SteepnessPreview } from "./steepnessPreview/SteepnessPreview";
import axiosService from "services/axios";
import { CreateCabalError, CreateCabalSuccess, CreateCabalSuccessV2 } from "./createCabalModal";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { ROUTES } from "consts";
import { PreviewChart } from "components/Chart/PreviewChart";
import useToastStore from "store/toast-store/useToastStore";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import CabalTownLogo from "../../assets/icons/cabal-town-logo.png";
import { useTheme } from "@mui/material";
import { OTP } from "components/OTP";
import { useRedeemCode } from "hooks/useRedeemCode";
import { ReactComponent as BenefitA } from "assets/icons/redeem-benefit-1.svg";
import { ReactComponent as BenefitB } from "assets/icons/redeem-benefit-2.svg";
import { useInView } from "react-intersection-observer";
import { LoadingView } from "components/LoadingScreen";

const initialData = {
  Casual: require("./data/casual.json"),
  Standard: require("./data/standard.json"),
  Exclusive: require("./data/exclusive.json"),
};

// Define the ref type
type CreateCabalData = {
  cabalName: string;
  cabalSize: number;
  cabalImageUrl: string;
};
export interface CreateCabalRef {
  getValue: () => CreateCabalData;
  deployContract: () => Promise<void>;
}

export type Steepness = "Casual" | "Standard" | "Exclusive";
export type Language = "English" | "Chinese" | "Korean" | "Russian";

type CreateCabalProps = {};

const SteepnessRR = {
  Casual: "7500",
  Standard: "420",
  Exclusive: "190",
};

const SteepnessMaxMemberPreview = {
  Casual: "2000",
  Standard: "200",
  Exclusive: "100",
};

const LanguageShortForm = {
  English: "en",
  Chinese: "zh",
  Korean: "ko",
  Russian: "ru",
};

const benefitConfigs: BenefitItemConfig[] = [
  {
    title: "Benefit 1",
    description: "Share your alpha trades and earn 50% of trading fees that your group generates.",
    icon: <BenefitA />,
  },
  {
    title: "Benefit 2",
    description:
      "As you give great alpha, more people will buy group tokens for your alpha chat on the bonding curve, allowing you to capture the upside of your token.",
    icon: <BenefitB />,
  },
];

export const CreateCabalV2 = forwardRef<CreateCabalRef, CreateCabalProps>(() => {
  const { tgUserId, tgUserName, walletAddresses, tgPhotoUrl, hasWallet, createWallet } =
    useUserStoreV2();

  const navigate = useNavigatePreserveQuery();
  const { showToast } = useToastStore();
  const { setIsShowHeader } = useLayoutConfigStore();
  const theme = useTheme();

  // Icon
  const [icon, setIcon] = useState<File | undefined>();
  const cabalIconRef = useRef<HTMLInputElement>(null);

  // Name, Token ticker, Language
  const [name, setName] = useState<string | undefined>();
  const [tokenTicker, setTokenTicker] = useState<string | undefined>();
  const [language, setLanguage] = useState<Language>("English");

  // Curve Steepness
  const [curveSteepness, setCurveSteepness] = useState<Steepness>("Standard");

  // Submition
  const [isValidToSubmit, setIsValidToSubmit] = useState<boolean>(false);

  // Preview
  const [isOpennedPreview, setIsOpennedPreview] = useState(false);

  // Drawer
  const [isOpennedModal, setIsOpennedModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Created cabal id
  const [cabalId, setCabalId] = useState<string | undefined>();

  // Loading State
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef(false);

  // Code
  const [otp, setOtp] = useState("");

  // Benefit
  const [slide, setSlide] = useState(0);

  const [isSkipOnboard, setIsSkipOnboard] = useState(
    !!localStorage.getItem("isSkipCreateCabalOnboard"),
  );

  useEffect(() => {
    if (name && tokenTicker && language && curveSteepness) {
      setIsValidToSubmit(true);
    } else {
      setIsValidToSubmit(false);
    }
  }, [icon, name, tokenTicker, language, curveSteepness]);

  const handleUpload = () => {
    cabalIconRef.current?.click();
  };

  const handleIconSelected = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setIcon(file);
    }
  };

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTokenTickerChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTokenTicker(event.target.value);
  };

  const handleSelectLanguage = (event: any) => {
    setLanguage(event.target.value);
  };

  const handleOpenPreview = () => {
    setIsOpennedPreview(true);
  };

  const handleClosePreview = () => {
    setIsOpennedPreview(false);
  };

  const handleOpenSuccessModal = () => {
    setErrorMessage("");
    setIsOpennedModal(true);
  };

  const handleOpenErrorModal = (message: string) => {
    setErrorMessage(message);
    setIsOpennedModal(true);
  };

  const handleCloseModal = () => {
    setIsOpennedModal(false);
  };

  const handleSelectCurveSteepness = (steepness: Steepness) => {
    setCurveSteepness(steepness);
  };

  const handleDone = async () => {
    if (!cabalId) return;
    navigate(ROUTES.cabalId.replace(":id", cabalId));
  };

  // Form submition
  const handleOnSubmit = async () => {
    let res: {
      status: number | null;
      message: string;
    } = {
      status: null,
      message: "",
    };

    try {
      loadingRef.current = true;
      setIsLoading(true);

      let tonWallet = walletAddresses?.tonAddress;
      if (!hasWallet) {
        const wallet = await createWallet();
        tonWallet = wallet?.tonAddress;
      }

      // Validation
      if (
        !name ||
        !tgUserId ||
        !tokenTicker ||
        !tonWallet ||
        !tgUserName ||
        !curveSteepness ||
        !tokenTicker ||
        !language
      ) {
        throw new Error("Failed to create cabal.");
      }

      // Upload icon
      let iconUrl;
      showToast({ variant: "info", message: `Uploading Cabal Icon...` });
      if (icon) {
        iconUrl = await axiosService.uploadFile(icon);
      } else {
        iconUrl = tgPhotoUrl;
      }

      if (!iconUrl) {
        handleOpenErrorModal("Failed to upload image.");
        return setIsLoading(false);
      }

      // Create cabal
      showToast({ variant: "info", message: `Creating Cabal...` });
      const body = {
        image: iconUrl,
        name: name,
        tgUserId: tgUserId,
        tgUserName: tgUserName,
        reserveRate: SteepnessRR[curveSteepness],
        description: "Cabal",
        symbol: tokenTicker || "",
        decimals: "9",
        language: LanguageShortForm[language],
      };

      const response = await axiosService.insertJettonV2(body);

      const { tgGroupId } = response.res;

      // Check response payload
      if (!tgGroupId) {
        res.message = response.res.message;
        res.status = response.res.status;
        throw new Error("Failed to get tgGroupId");
      }

      // Throw error if status code is not 2xx
      if (response.status > 299) {
        res.message = response.res.message;
        res.status = response.res.status;
        throw new Error(res.message);
      }

      setCabalId(tgGroupId);
      showToast({
        variant: "success",
        message: `Success to create Cabal, Telegram Group ID: ${tgGroupId}`,
        duration: 5000,
      });
      handleOpenSuccessModal();
    } catch (error) {
      if (res.status! >= 400) {
        showToast({ variant: "error", message: res.message, duration: 5000 });
      } else {
        showToast({ variant: "info", message: res.message, duration: 5000 });
      }
      console.error("Failed to create cabal:", error);
      setCabalId(undefined);
      handleOpenErrorModal("Failed to create cabal.");
    } finally {
      setIsLoading(false);
      loadingRef.current = false;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // console.debug("event: ", event.key);
    if (event.key === "Enter") {
      event.preventDefault();
      event.currentTarget.blur();
      // Force any active element to lose focus
      const activeElement = document.activeElement as HTMLElement;
      activeElement?.blur();
    }
  };

  const handleSlideChange = (index: number) => {
    setSlide(index);
  };

  const navigateToExplorer = () => {
    navigate(ROUTES.explorer);
  };

  // if (isCheckingRedeemed) {
  //   return (
  //     <LoadingView isLoading={isCheckingRedeemed}>
  //       <Typography variant="title-h2">Loading...</Typography>
  //     </LoadingView>
  //   );
  // }

  if (isOpennedModal && errorMessage === "" && cabalId) {
    return (
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "20px",
            gap: "12px",
            "&:focus-visible": {
              outline: "none",
            },
          }}>
          <CreateCabalSuccessV2 cabalId={cabalId!} />
        </Box>
      </RetroCard>
    );
  }

  return isSkipOnboard ? (
    <>
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "20px",
            gap: "16px",
          }}>
          <UploadImageContainer>
            <CabalIcon src={icon ? URL.createObjectURL(icon) : tgPhotoUrl}>
              <ImageIcon />
            </CabalIcon>
            <UploadButtonWrapper>
              <RetroButton variant="white" onClick={handleUpload}>
                <Typography variant="title-h3">CHANGE</Typography>
              </RetroButton>
              <input
                ref={cabalIconRef}
                type="file"
                onChange={handleIconSelected}
                accept="image/png, image/jpeg"
                hidden
              />
            </UploadButtonWrapper>
          </UploadImageContainer>
          <StyledLabel>
            <Typography variant="body-md" color="text.secondary">
              Alpha group name
            </Typography>
            <StyledInput
              id="name"
              placeholder="Full name"
              onChange={handleNameChange}
              onKeyDown={handleKeyDown}
            />
          </StyledLabel>
          <StyledLabel>
            <Typography variant="body-md" color="text.secondary">
              Group token symbol
            </Typography>
            <StyledInput
              id="name"
              placeholder="e.g. HELIUM"
              onChange={handleTokenTickerChange}
              onKeyDown={handleKeyDown}
            />
          </StyledLabel>
          <StyledLabel>
            <Typography variant="body-md" color="text.secondary">
              Language
            </Typography>
            <StyledSelect id="name" defaultValue="English" onChange={handleSelectLanguage} required>
              <StyledOption value="English">English</StyledOption>
              <StyledOption value="Chinese">中文</StyledOption>
              <StyledOption value="Korean">한국어</StyledOption>
              <StyledOption value="Russian">русский</StyledOption>
            </StyledSelect>
          </StyledLabel>
          <CurveSteepnessHeaderWrapper>
            <Typography variant="body-md" color="text.secondary">
              Price Curves
            </Typography>
            <ViewButtonWrapper>
              <RetroButton variant="white" onClick={handleOpenPreview}>
                <Typography variant="button-md">PREVIEW</Typography>
              </RetroButton>
            </ViewButtonWrapper>
          </CurveSteepnessHeaderWrapper>
          <CurveSteepnessOptionsWrapper>
            {(["Casual", "Standard", "Exclusive"] as Steepness[]).map((option) => (
              <CurveSteepnessOptionRadioContainer
                $selected={curveSteepness === option}
                key={option}
                onClick={() => handleSelectCurveSteepness(option as Steepness)}>
                <CurveSteepnessOption>
                  <PreviewChart
                    variant={curveSteepness === option ? "active" : "non-active"}
                    data={initialData[option as Steepness].slice(
                      0,
                      option === "Standard" ? 75 : SteepnessMaxMemberPreview[option],
                    )}
                  />
                  <CurveSteepnessOptionText variant="body-md">{option}</CurveSteepnessOptionText>
                </CurveSteepnessOption>
                <CurveSteepnessOptionRadio $selected={curveSteepness === option} />
              </CurveSteepnessOptionRadioContainer>
            ))}
          </CurveSteepnessOptionsWrapper>
          <SteepnessPreview
            isOpenned={isOpennedPreview}
            close={handleClosePreview}
            selectedSteepness={curveSteepness}
            handleSelectCurveSteepness={handleSelectCurveSteepness}
          />
          <CreateCabalError
            isOpen={errorMessage !== "" && isOpennedModal}
            close={handleCloseModal}
            errorMessage={errorMessage}
          />
        </Box>
      </RetroCard>
      <Box height="72px" />
      <CreateCabalButtonWrapper $valid={isValidToSubmit && !isLoading} onClick={handleOnSubmit}>
        <RetroButton
          variant={isValidToSubmit && !isLoading ? "primary" : "disabled"}
          disabled={isLoading}>
          <Typography
            variant="title-h3"
            sx={{ paddingY: "12px" }}
            color={isValidToSubmit && !isLoading ? "text.brand" : "text.secondary"}>
            Create Group
          </Typography>
        </RetroButton>
        <Box sx={{ height: "20px", width: "100%" }} />
      </CreateCabalButtonWrapper>
    </>
  ) : (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "fit-content",
        }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "8px 24px",
              gap: "12px",
            }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                // flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "0 20px",
              }}>
              <Typography variant="title-h2">WHY BUILD</Typography>
              <Typography variant="title-h2">A CABAL?</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                overflowX: "scroll",
                background: theme.palette.surface.bg,
                borderRadius: "16px",
                border: `1px solid ${theme.palette.border.dark}`,
              }}>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                  overflowX: "scroll",
                  scrollSnapType: "x mandatory",
                  msScrollSnapPointsX: "repeat(100%)",
                }}>
                {benefitConfigs.map((config) => {
                  return (
                    <BenefitItem
                      config={config}
                      index={benefitConfigs.indexOf(config)}
                      slid={slide}
                      handleSlideChange={handleSlideChange}
                    />
                  );
                })}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
              }}>
              {[0, 1].map((pageIndex) => (
                <Box
                  onClick={() => handleSlideChange(pageIndex)}
                  sx={{
                    border: `1px solid ${theme.palette.border.dark}`,
                    borderRadius: "50%",
                    width: "12px",
                    height: "12px",
                    background:
                      slide === pageIndex
                        ? theme.palette.brand.primary
                        : theme.palette.surface.silver,
                  }}
                />
              ))}
            </Box>
          </Box>
        </RetroCard>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "8px",
              gap: "24px",
            }}>
            <Box
              sx={{
                width: "100%",
              }}>
              <RetroButton
                onClick={() => {
                  if (slide === 0) {
                    setSlide((pre) => pre + 1);
                  } else {
                    localStorage.setItem("isSkipCreateCabalOnboard", "true");
                    setIsSkipOnboard(true);
                  }
                }}>
                <Box
                  sx={{
                    padding: "12px 24px",
                  }}>
                  <Typography variant="title-h3">NEXT</Typography>
                </Box>
              </RetroButton>
            </Box>
          </Box>
        </RetroCard>
      </Box>
    </>
  );
});

interface BenefitItemConfig {
  title: string;
  description: string;
  icon: JSX.Element;
}

const BenefitItem = ({
  config,
  index,
  slid,
  handleSlideChange,
}: {
  config: BenefitItemConfig;
  index: number;
  slid: number;
  handleSlideChange: (slide: number) => void;
}) => {
  const { title, description, icon } = config;

  const { ref, inView } = useInView();
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inView) {
      handleSlideChange(index);
    }
  }, [inView]);

  useEffect(() => {
    if (slid === index && !inView) {
      scrollRef.current?.scrollIntoView();
    }
  }, [slid]);

  return (
    <Box
      ref={scrollRef}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        gap: "18px",
        minWidth: "100%",
        scrollSnapAlign: "start",
      }}>
      {icon}
      <Typography ref={ref} variant="title-h2">
        {title}
      </Typography>
      <Typography variant="body-md" color="text.secondary">
        {description}
      </Typography>
    </Box>
  );
};
