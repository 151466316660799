import { Box, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { CreateCabalModal } from "./CreateCabalModal";
import { ReactComponent as ShareToFriendIcon } from "assets/icons/redeem-benefit-1.svg";
import { styled } from "@mui/material";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";

interface CreateCabalSuccessProps {
  isOpen: boolean;
  close: () => void;
  done: () => void;
}

// TODO: Refactor into styled-components
export const CreateCabalSuccess = ({ isOpen, close, done }: CreateCabalSuccessProps) => {
  return (
    <CreateCabalModal
      isOpen={isOpen}
      close={close}
      type="Success"
      description="Your cabal is created!">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "6px",
          marginY: "12px",
        }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "363px",
            height: "63px",
          }}>
          <RetroButton variant="primary" onClick={done}>
            <Typography variant="title-h3">DONE</Typography>
          </RetroButton>
        </Box>
      </Box>
    </CreateCabalModal>
  );
};

export const CreateCabalSuccessV2 = ({ cabalId }: { cabalId: string }) => {
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();

  const navigateToCabalDetail = () => {
    navigate({
      pathname: `/cabal/${cabalId}`,
      search: "isShowBuyNowModal=true",
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "16px",
        outline: "none",
      }}>
      <ShareToFriendIcon />
      <Typography variant="title-h2">YOUR CABAL STARTS NOW</Typography>
      <Typography variant="body-default" color="text.secondary">
        Learn these useful tips to start your journey at Cabal Town!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}>
        <ContentContainer>
          <Box
            sx={{
              width: "fit-content",
              padding: "4px",
              borderRadius: "8px",
              border: `1px solid ${theme.palette.border.dark}`,
              backgroundColor: theme.palette.system["green"],
            }}>
            <Typography variant="body-sm" color="text.system-green">
              {`/call {contract address}`}
            </Typography>
          </Box>
          <Typography variant="body-default" color="text.secondary">
            Telegram command to recommend a token in group
          </Typography>
        </ContentContainer>
        <ContentContainer>
          <Box
            sx={{
              width: "fit-content",
              padding: "4px",
              borderRadius: "8px",
              border: `1px solid ${theme.palette.border.dark}`,
              backgroundColor: theme.palette.system["red"],
            }}>
            <Typography variant="body-sm" color="text.system-red">
              {`/exit {contract address}`}
            </Typography>
          </Box>
          <Typography variant="body-default" color="text.secondary">
            Telegram command to indicate that you close your recommendation in group
          </Typography>
        </ContentContainer>
        <ContentContainer>
          <Typography variant="body-default" color="text.secondary">
            When your community members trade alongside your alpha, you will receive 0.5% of their
            trading volume as fees!
          </Typography>
        </ContentContainer>
      </Box>
      <Box sx={{ width: "100%" }}>
        <RetroButton variant="primary" onClick={navigateToCabalDetail}>
          <Box sx={{ padding: "12px" }}>
            <Typography variant="title-h3">VIEW GROUP DETAIL</Typography>
          </Box>
        </RetroButton>
      </Box>
    </Box>
  );
};

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: "12px",
  borderRadius: "12px",
  border: `1px solid ${theme.palette.border.dark}`,
  backgroundColor: theme.palette.surface["container-bg"],
  gap: "8px",
}));
