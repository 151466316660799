import { Select, styled } from "@mui/material";

export const StyledSelect = styled(Select)<{ noPointer?: boolean }>(({ theme, noPointer }) => ({
  pointerEvents: noPointer ? "none" : "auto",
  borderRadius: theme.spacing(1.5),
  overflow: "hidden",

  ".MuiSelect-select": {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    padding: theme.spacing(0.5, 2),
    borderRadius: theme.spacing(1.5),
    backgroundColor: theme.palette.surface.silver,
    pointerEvents: noPointer ? "none" : "auto",
    width: "100%",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: `1px solid ${theme.palette.border.dark}`,
    borderRadius: theme.spacing(1.5),
  },

  "& .MuiSelect-icon": {
    color: theme.palette.text.secondary,
    fontSize: "24px",
  },

  "&:hover": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.border.dark}`,
    },
  },
}));
