import { Box, Typography, useTheme } from "@mui/material";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { Chain, TradeCall } from "types";
import { StyledCallItem, StyledLink } from "./styles";
import CoinAvatar from "components/CoinAvatar";
import { PercentageBlock, PercentageBrick } from "components/PercentageBrick";
import { abbreviateNumber } from "utils";

type TradeCallRowProps = {
  tradeCall: TradeCall;
};

export const TradeCallRow = ({ tradeCall }: TradeCallRowProps) => {
  const {
    name,
    chain,
    symbol,
    imageUrl,
    decimals,
    tokenAddress,
    tgGroupId,
    performance,
    marketCap,
    liquidity,
    price,
  } = tradeCall;
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();

  const navigateToTokenDetailPage = () => {
    navigate({
      pathname: `/token/${tokenAddress}`,
      search: `from=trade&chain=${chain}${tgGroupId ? `&groupId=${tgGroupId}` : ""}`,
    });
  };

  return (
    <StyledLink onClick={navigateToTokenDetailPage}>
      <StyledCallItem>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "12px",
          }}>
          <CoinAvatar
            style={{
              width: "32px",
              height: "32px",
            }}
            badgeStyle={{
              width: "16px",
              height: "16px",
            }}
            iconUri={imageUrl as string}
            chain={chain as Chain}
            size="sm"
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: theme.spacing(0.5),
              textAlign: "left",
            }}>
            <Typography variant="subtitle">{symbol}</Typography>
            <Typography variant="body-sm" color="text.secondary">
              ${abbreviateNumber(marketCap)} MKT CAP
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "end",
            gap: "4px",
          }}>
          <Typography variant="body-default">${price.toPrecision(2)}</Typography>
          <PercentageBlock percentage={performance} />
        </Box>
      </StyledCallItem>
    </StyledLink>
  );
};
