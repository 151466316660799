import React, { ReactNode, useEffect, useState } from "react";
import { StyledCloseButton, StyledDrawer, StyledSearchBarWrapper, StyledTextField } from "./styles";
import { Search } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { checkAddressChain } from "lib/utils";
import { useQuery } from "@tanstack/react-query";
import { ScrollableBox } from "components/ScrollableBox";
import { TradeCallRow } from "./TradeCallRow";
import { LoadingBlock, LoadingLottie } from "components/LoadingScreen";
import axiosService from "services/axios";
import { TradeCall } from "types";
import { ReactComponent as DarkLogoIcon } from "assets/icons/logo-dark.svg";

export const SearchDrawer = ({
  children,
  isOpenned,
  close,
}: {
  children?: ReactNode;
  isOpenned: boolean;
  close: () => void;
}) => {
  const [search, setSearch] = useState("");
  const [isValidAddress, setIsValidAddress] = useState(false);

  const { data, isLoading } = useQuery<TradeCall[]>({
    queryKey: ["trade-target-token", isValidAddress, search],
    queryFn: () => {
      if (!isValidAddress) {
        return [];
      }

      return axiosService.getTradeToken({
        tokenAddress: search,
      });
    },
  });

  useEffect(() => {
    if (search) {
      const chain = checkAddressChain(search);
      if (chain) {
        setIsValidAddress(true);
      } else {
        setIsValidAddress(false);
      }
    }
  }, [search]);

  return (
    <React.Fragment key={"bottom"}>
      {children}
      <StyledDrawer anchor={"bottom"} open={isOpenned}>
        <StyledSearchBarWrapper>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Search />
            <StyledTextField
              placeholder="Search by token address"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" gap="16px" height="100%">
            <StyledCloseButton onClick={close} />
          </Box>
        </StyledSearchBarWrapper>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: "8px 10px",
          }}>
          {search !== "" ? (
            !isValidAddress || !data || (data && data.length <= 0) ? (
              <Box
                sx={{
                  margin: "auto auto",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  textAlign: "center",
                  maxWidth: "282px",
                }}>
                <DarkLogoIcon />
                <Typography variant="title-h3">Token not found</Typography>
                <Typography variant="body-sm">Please try again.</Typography>
              </Box>
            ) : (
              <ScrollableBox>
                {isLoading ? (
                  <LoadingBlock isLoading={isLoading}>
                    <Typography variant="title-h2">Loading...</Typography>
                  </LoadingBlock>
                ) : !data || data.length <= 0 ? null : (
                  data.map((token: any, index: number) => {
                    const formattedCall = {
                      ...token,
                      performance: parseFloat(token.performance),
                      marketCap: parseFloat(token.marketCap),
                      liquidity: parseFloat(token.liquidity),
                      price: parseFloat(token.price),
                    };
                    return <TradeCallRow key={`search-${index}`} tradeCall={formattedCall} />;
                  })
                )}
              </ScrollableBox>
            )
          ) : null}
        </Box>
      </StyledDrawer>
    </React.Fragment>
  );
};
