import { EVM_CHAINS, useTradeContext } from "contexts/TradeContext";
import { useEffect, useState } from "react";
import useToastStore from "store/toast-store/useToastStore";
import { useSignalAbort } from "./useSignalAbort";
import { useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { NumpadKey } from "components/TradeDrawer";
import { InsufficientPriceHint } from "components/CrossChainSwapDrawer/priceHint";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { Chain } from "types";

export const MINIMUM_BALANCE_FOR_GAS_MAP: Record<string, number> = {
  solana: 1,
  base: 1,
  ethereum: 10,
  berachain: 1,
  ton: 1,
};

export const NATIVE_TOKEN_ADDRESS_MAP: Record<string, string> = {
  solana: "So11111111111111111111111111111111111111112",
  base: "0x4200000000000000000000000000000000000006",
  ethereum: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
  berachain: "0xEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE",
  ton: "EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c",
};

export const useTradeDrawer = ({
  tgGroupId,
  isFromTrendingCall,
  defaultInputUsd,
  onConfirm,
  onProcessing,
  onProcessed,
  onClose,
  onFail,
  propsMode,
}: {
  tgGroupId?: number;
  isFromTrendingCall?: boolean;
  defaultInputUsd?: number;
  onConfirm?: (message?: string) => void;
  onProcessing?: () => void;
  onProcessed?: () => void;
  onClose?: () => void;
  onFail?: (message?: string) => void;
  propsMode?: "BUY" | "SELL" | "SWAP" | "UNWRAP";
}) => {
  const {
    // Mode
    mode: tradeMode,
    // Native Token
    selectedNativeChain,
    nativeTokenConfig,
    nativeTokenPrice,
    nativeBalanceUsd,
    // Target Token
    selectedTargetChain,
    targetTokenConfig,
    targetTokenPrice,
    targetBalanceUsd,
    nativeToTargetTokenRatio,
    // Others
    senderAddress,
    isTrading,
    destinationAddress,
    getNativePriceByChain,
    refetchTargetTokenBalance,
    handleSetMode,
    handleSetIsTrading,
    targetBalanceAmount,
    // Polling
    setIsPolling,
    setIsTonBoardcasting,
    setTxnHash,
  } = useTradeContext();

  // Input Value
  const [inputUsdString, setInputUsdString] = useState("0"); // (USD)
  const [targetAmount, setTargetAmount] = useState(0); // (amount)
  const [nativeAmount, setNativeAmount] = useState(0); // (amount)

  // Reminder Drawer
  const [isOpenConfirmDrawer, setIsOpenConfirmDrawer] = useState(false);

  // Flags
  const [isInsufficientBalance, setIsInsufficientBalance] = useState(false);
  const [isSellAll, setIsSellAll] = useState(false);

  // Toast
  const { showToast } = useToastStore();
  const {
    isLoading: isNativeBalanceLoading,
    updateUserBalance,
    getUserBalance,
    nativeToken,
  } = useTradeStore();

  const mode = tradeMode === "SWAP" ? "BUY" : tradeMode;

  // Init
  useEffect(() => {
    if (mode === "BUY") {
      setInputUsdString(defaultInputUsd ? defaultInputUsd.toString() : "0");
    } else {
      setInputUsdString("0");
    }

    setIsSellAll(false);
    setIsInsufficientBalance(false);
  }, [mode, selectedNativeChain, defaultInputUsd]);

  // Update Input Amount from USD
  useEffect(() => {
    if (targetTokenPrice) {
      setTargetAmount(+inputUsdString / targetTokenPrice);
    }
    if (nativeTokenPrice) {
      setNativeAmount(+inputUsdString / nativeTokenPrice);
    }
  }, [inputUsdString, targetTokenPrice, nativeTokenPrice]);

  // Check Insufficient Balance
  useEffect(() => {
    if (mode === "BUY") {
      setIsInsufficientBalance(
        +inputUsdString !== 0 && +inputUsdString > getUserBalance(selectedNativeChain, "usd")!,
      );
    }

    if (mode === "SELL") {
      setIsInsufficientBalance(+inputUsdString !== 0 && +inputUsdString > +targetBalanceUsd!);
    }
  }, [selectedNativeChain, inputUsdString, nativeToken]);

  // Start Trading
  const handleConfirm = (slippage?: number) => {
    // Checks
    if (!slippage && selectedTargetChain !== "ton") {
      console.error("Slippage is not set");
      return;
    }

    handleConfirmClick?.(slippage);
    setIsOpenConfirmDrawer(false);
    handleSetMode("BUY");
  };

  // Confirm
  const handleOpenConfirmDrawer = (isOpen: boolean) => {
    setIsOpenConfirmDrawer(isOpen);
  };

  // Close Drawer
  const handleOnClose = () => {
    if (mode === "BUY") {
      setInputUsdString(defaultInputUsd ? defaultInputUsd.toString() : "0");
    } else {
      setInputUsdString("0");
    }
    setTargetAmount(0);
    setNativeAmount(0);
    setIsSellAll(false);
    setIsInsufficientBalance(false);
    handleSetMode("BUY");
    onClose?.();
  };

  // Numpad Press
  const handleNumpadPress = (key: NumpadKey) => {
    setIsSellAll(false);
    if (key === "delete") {
      setInputUsdString("0");
    } else if (key === ".") {
      if (!inputUsdString.includes(".")) {
        setInputUsdString(inputUsdString + key);
      }
    } else {
      setInputUsdString(inputUsdString + key);
    }
  };

  // Percent Press
  const handlePercentPress = (percent: number) => {
    if (mode === "BUY") {
      const gasBuffer = MINIMUM_BALANCE_FOR_GAS_MAP[selectedNativeChain];
      const MIN_AMOUNT = 0;
      const clampedAmount = Math.max(MIN_AMOUNT, +nativeBalanceUsd! * percent - gasBuffer);
      setInputUsdString(`${+clampedAmount}`);
    } else {
      // For handling the 18 decimal places issue on EVMs 100% sell
      if (percent === 1) {
        setIsSellAll(true);
        setInputUsdString(`${+targetBalanceUsd! * percent}`);
      } else {
        setIsSellAll(false);
        setInputUsdString(`${+targetBalanceUsd! * percent}`);
      }
    }
  };

  // Handle Confirm
  const handleConfirmClick = async (slippage?: number) => {
    try {
      const isEnoughBalance =
        getUserBalance(selectedNativeChain, "usd")! >=
        MINIMUM_BALANCE_FOR_GAS_MAP[selectedNativeChain];

      if (!isEnoughBalance) {
        onFail?.("Insufficient balance to pay for gas");

        onProcessed?.();
        onClose?.();
        return;
      }

      handleSetIsTrading(true);
      showToast({
        variant: "info",
        message:
          "Your transaction is being broadcasted. This typically takes around 30 seconds. Please hold tight!",
      });
      onProcessing?.();
      onClose?.();

      let result;
      if (selectedTargetChain === "ton" && mode === "BUY") {
        // Buy Jetton
        if (selectedTargetChain === selectedNativeChain) {
          const payload = {
            tonAmount: nativeAmount,
            masterAddress: targetTokenConfig[selectedTargetChain].token!,
            tgGroupId: tgGroupId!,
            chain: selectedNativeChain,
          };

          result = await axiosService.buyTonJetton(payload);
        } else {
          // Bridge
          const payload = {
            tonAmt: (nativeAmount * nativeTokenPrice!) / getNativePriceByChain("ton")!,
            masterAddress: targetTokenConfig[selectedTargetChain].token!,
            tgGroupId: tgGroupId!,
            chain: selectedNativeChain,
          };

          result = await axiosService.buyJettonFromBridge(payload);
        }
      } else if (selectedTargetChain === "ton" && mode === "SELL") {
        // Sell Jetton
        const payload = {
          // Ton * (Jetton/Ton) = Jetton
          jettonAmt:
            isSellAll && targetBalanceAmount
              ? targetBalanceAmount
              : nativeAmount * nativeToTargetTokenRatio!,
          masterAddress: nativeTokenConfig[selectedNativeChain].token!,
          tgGroupId: tgGroupId!,
          chain: selectedNativeChain,
        };

        result = await axiosService.sellTonJetton(payload);
      } else if (selectedTargetChain !== "ton") {
        const payload: {
          srcToken: string;
          destToken: string;
          senderAddress: string;
          nativeSpent: number;
          slippage: number;
          srcChain: string;
          destChain: string;
          tradeType: string;
          isSellAll: boolean;
          priceUsd: number;
          tgGroupId?: number;
          isFromTrendingCall?: boolean;
          destinationAddress?: string;
          dstChainOrderAuthorityAddress?: string;
          isSwap?: boolean;
        } = {
          srcToken:
            mode === "BUY"
              ? nativeTokenConfig[selectedNativeChain].token!
              : targetTokenConfig[selectedTargetChain].token!,
          destToken:
            mode === "BUY"
              ? targetTokenConfig[selectedTargetChain].token!
              : nativeTokenConfig[selectedNativeChain].token!,
          senderAddress,
          nativeSpent: mode === "BUY" ? nativeAmount : targetAmount,
          slippage: slippage!,
          tradeType: mode,
          isSellAll,
          srcChain: mode === "BUY" ? selectedNativeChain : selectedTargetChain,
          destChain: mode === "BUY" ? selectedTargetChain : selectedNativeChain,
          priceUsd: +inputUsdString,
          ...(tgGroupId ? { tgGroupId } : {}),
          ...(isFromTrendingCall ? { isFromTrendingCall } : {}),
          ...(destinationAddress && selectedTargetChain !== selectedNativeChain
            ? { destinationAddress }
            : {}),
          ...(destinationAddress && selectedTargetChain !== selectedNativeChain
            ? { dstChainOrderAuthorityAddress: destinationAddress }
            : {}),
          ...(propsMode === "SWAP" ? { isSwap: true } : {}),
        };

        result = await axiosService.crossChainTrade(payload);
      }

      if (result.status < 300) {
        showToast({ variant: "success", message: result.message });

        if (result.txnHash) {
          setTxnHash(result.txnHash);
        }

        if (result.jobId) {
          const traceJobResult = await axiosService.traceJobStatus(result.jobId, result.jobType);

          if (traceJobResult.status >= 300) {
            throw new Error("Error while updating transaction", traceJobResult.message);
          }

          showToast({ variant: "success", message: traceJobResult.message, duration: 10000 });
          onConfirm?.(traceJobResult.message);
        }

        // Add polling logic for balance update
        const initialBalance = targetBalanceAmount;
        let attempts = 0;
        const maxAttempts = 10;
        const pollInterval = 3000; // 3 seconds

        setIsPolling(true);

        if (propsMode !== "SWAP") {
          setIsTonBoardcasting(mode === "BUY");

          const pollForBalanceUpdate = async () => {
            while (attempts < maxAttempts) {
              const newBalance = await refetchTargetTokenBalance();
              if (newBalance?.data?.quantity !== initialBalance) {
                break;
              }
              attempts++;
              await new Promise((resolve) => setTimeout(resolve, pollInterval));
            }
          };

          await pollForBalanceUpdate();
          setIsPolling(false);
        }

        onProcessed?.();
      } else {
        if (result.message.includes("Execution reverted")) {
          showToast({
            variant: "error",
            message: "Broadcast unsuccessful. Please retry.",
            duration: 10000,
          });
        } else {
          showToast({ variant: "error", message: result.message, duration: 10000 });
        }
        onFail?.(result.message);
        console.error("Transaction Failed: ", result);
        onProcessed?.();
        onClose?.();
      }
    } catch (error) {
      console.error(error);
      showToast({ variant: "error", message: "Transaction Failed", duration: 10000 });
      onFail?.("Something went wrong. Please try again.");
    } finally {
      handleSetIsTrading(false);
      updateUserBalance();
    }
  };

  return {
    // Sum
    inputUsd: +inputUsdString,
    targetAmount: targetAmount,
    nativeAmount: nativeAmount,
    shouldConfirmDisabled: isInsufficientBalance || isTrading || isNativeBalanceLoading,
    isSellAll,
    isOpenConfirmDrawer,
    handleOpenConfirmDrawer: handleOpenConfirmDrawer,
    onConfirm: handleConfirm,
    onNumpadPress: handleNumpadPress,
    onClose: handleOnClose,
    onPercentPress: handlePercentPress,
    priceHint: isInsufficientBalance ? <InsufficientPriceHint /> : null,
  };
};

export const useApproval = ({ targetAmount }: { targetAmount: number }) => {
  const {
    mode,
    targetTokenConfig,
    selectedNativeChain,
    selectedTargetChain,
    nativeTokenConfig,
    senderAddress,
  } = useTradeContext();

  // Approval Logic
  const [tempAllowanceAmount, setTempAllowanceAmount] = useState(0);
  const [isApproving, setIsApproving] = useState(false);
  const [isApproveSucess, setIsApproveSucess] = useState(false);

  const { showToast } = useToastStore();

  // Check if approval is needed to update
  useEffect(() => {
    // Reset approval status if token amount changes
    if (tempAllowanceAmount !== undefined) {
      if (tempAllowanceAmount < targetAmount) {
        resetAllowance();
      } else {
        setIsApproveSucess(true);
      }
    }
  }, [mode, targetAmount, tempAllowanceAmount]);

  // Fetch Current Allowance (EVMS Only)
  const {
    data: currentAllowanceAmount,
    refetch: refetchCurrentAllowance,
    isLoading: isGetCurrentAllowanceLoading,
  } = useQuery({
    queryKey: [
      "get-current-allowance",
      mode,
      nativeTokenConfig[selectedNativeChain]?.token,
      targetTokenConfig[selectedTargetChain]?.token,
    ],
    queryFn: () =>
      axiosService.getCurrentAllowance({
        srcToken: targetTokenConfig[selectedTargetChain].token!,
        destToken: NATIVE_TOKEN_ADDRESS_MAP.ethereum,
        senderAddress: senderAddress,
        chain: selectedTargetChain,
      }),
    enabled: !!senderAddress && EVM_CHAINS.includes(selectedTargetChain) && mode === "SELL",
  });

  // Update Temp Allowance Amount from API
  useEffect(() => {
    if (currentAllowanceAmount !== undefined) {
      setTempAllowanceAmount(currentAllowanceAmount);
    } else {
      setTempAllowanceAmount(0);
    }
  }, [currentAllowanceAmount]);

  // Handle Approval
  const handleApproveAllowance = async () => {
    try {
      setIsApproving(true);
      showToast({ variant: "info", message: "Approving spending..." });

      const res = await axiosService.approveAllowance({
        chain: selectedTargetChain,
        srcToken:
          mode === "BUY"
            ? nativeTokenConfig[selectedNativeChain].token!
            : targetTokenConfig[selectedTargetChain].token!,
        destToken:
          mode === "BUY"
            ? targetTokenConfig[selectedTargetChain].token!
            : nativeTokenConfig[selectedNativeChain].token!,
        senderAddress,
        amount: targetAmount,
      });

      if (res) {
        showToast({
          variant: "success",
          message: "Spending approved. Please proceed to trade.",
          duration: 5000,
        });
        setTempAllowanceAmount(targetAmount);
        return true;
      } else {
        showToast({ variant: "error", message: res.message, duration: 5000 });
        throw new Error("Approval failed: ", res.message);
      }
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setIsApproving(false);
    }
  };

  // Reset Approval Status
  const resetAllowance = () => {
    setIsApproving(false);
    setIsApproveSucess(false);
  };

  return {
    isApproving,
    isApproveSucess,
    isGetCurrentAllowanceLoading,
    resetAllowance,
    setIsApproving,
    setIsApproveSucess,
    refetchCurrentAllowance,
    handleApproveAllowance,
  };
};

export const usePriceImpact = ({
  isSellAll,
  targetAmount,
  nativeAmount,
}: {
  isSellAll: boolean;
  targetAmount: number;
  nativeAmount: number;
}) => {
  const {
    mode: tradeMode,
    nativeTokenPrice,
    targetTokenPrice,
    shouldTradeDrawerOpen,
    selectedNativeChain,
    selectedTargetChain,
    nativeTokenConfig,
    targetTokenConfig,
    senderAddress,
    tgGroupId,
    destinationAddress,
  } = useTradeContext();

  const { getUserBalance } = useTradeStore();

  const { preferences = {} } = useUserStoreV2();
  const { slippage } = preferences;
  const mode = tradeMode === "SWAP" ? "BUY" : tradeMode;

  // Price Impact
  const [priceImpact, setPriceImpact] = useState<number | null>(null);
  const [noValidRouteMessage, setNoValidRouteMessage] = useState<string | null>(null);

  // Signal
  const { getSignal } = useSignalAbort();

  const {
    data: priceImpactData,
    refetch: refetchPriceImpact,
    isLoading: isLoadingPriceImpact,
    isRefetching: isRefetchingPriceImpact,
  } = useQuery({
    queryKey: ["priceImpact", mode, shouldTradeDrawerOpen, nativeAmount, targetAmount, slippage],
    queryFn: () => {
      if ((mode === "BUY" && nativeAmount === 0) || (mode === "SELL" && +targetAmount === 0)) {
        return {
          inputAmount: 0,
          inputDecimals: 0,
          outputAmount: 0,
          outputDecimals: 0,
        };
      }

      let spent;
      if (mode === "BUY") {
        spent = nativeAmount;
      } else {
        spent = +targetAmount;
      }

      const srcChain = mode === "BUY" ? selectedNativeChain : selectedTargetChain;
      const destChain = mode === "BUY" ? selectedTargetChain : selectedNativeChain;

      const tokenTradePayload = {
        srcToken:
          mode === "BUY"
            ? nativeTokenConfig[selectedNativeChain].token!
            : targetTokenConfig[selectedTargetChain].token!,
        destToken:
          mode === "BUY"
            ? targetTokenConfig[selectedTargetChain].token!
            : nativeTokenConfig[selectedNativeChain].token!,
        senderAddress,
        nativeSpent: spent,
        slippage: slippage ?? 0,
        tradeType: mode,
        isSellAll,
        srcChain,
        destChain,
        ...(tgGroupId ? { tgGroupId } : {}),
        ...(destinationAddress && srcChain !== destChain ? { destinationAddress } : {}),
        ...(destinationAddress && srcChain !== destChain
          ? { dstChainOrderAuthorityAddress: destinationAddress }
          : {}),
      };

      return axiosService.tokenTradePreview(tokenTradePayload, getSignal());
    },
    enabled:
      !!mode && !!selectedNativeChain && !!selectedTargetChain && !!slippage && !!senderAddress,
  });

  useEffect(() => {
    if (
      priceImpactData &&
      (priceImpactData.inputAmount === 0 || priceImpactData.outputAmount === 0)
    ) {
      setPriceImpact(null);
      setNoValidRouteMessage(null);
    }

    if (priceImpactData && priceImpactData.inputAmount) {
      const { inputAmount, inputDecimals, outputAmount, outputDecimals } = priceImpactData;

      const isEnoughBalance =
        getUserBalance(selectedNativeChain, "usd")! >=
        MINIMUM_BALANCE_FOR_GAS_MAP[selectedNativeChain];

      if (!isEnoughBalance) {
        return setNoValidRouteMessage("Insufficient balance to pay for gas");
      } else if (priceImpactData.message) {
        return setNoValidRouteMessage(priceImpactData.message);
      }

      let inputPrice, outputPrice;

      const nativePrice = nativeTokenPrice!;
      const tokenPrice = targetTokenPrice!;

      if (mode === "BUY") {
        inputPrice = (inputAmount / 10 ** inputDecimals) * nativePrice;
        outputPrice = (outputAmount / 10 ** outputDecimals) * tokenPrice;
      } else {
        inputPrice = (inputAmount / 10 ** inputDecimals) * tokenPrice;
        outputPrice = (outputAmount / 10 ** outputDecimals) * nativePrice;
      }

      const priceImpact = inputPrice - outputPrice;

      setPriceImpact(priceImpact);
      setNoValidRouteMessage(null);
    } else {
      if (priceImpactData && priceImpactData.status !== 200 && priceImpactData.message) {
        console.error(priceImpactData.message);
        setNoValidRouteMessage(priceImpactData.message);
        setPriceImpact(null);
      } else {
        if (priceImpactData && priceImpactData.message) {
          console.error(priceImpactData?.message);
        }
        setPriceImpact(null);
      }
    }
  }, [priceImpactData]);

  return {
    priceImpact,
    noValidRouteMessage,
    isLoadingPriceImpact,
    isRefetchingPriceImpact,
    refetchPriceImpact,
  };
};
