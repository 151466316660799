import { Box, Typography, useTheme } from "@mui/material";

interface DefinitionTooltipProps {
  term?: string;
  children: React.ReactNode;
  isShow: boolean;
}

export const DefinitionTooltip = ({ term, children, isShow }: DefinitionTooltipProps) => {
  const theme = useTheme();
  return isShow ? (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          zIndex: 3,
          width: "100vw",
          height: "100vh",
          background: "rgba(0,0,0,0.7)",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: theme.spacing(1),
          position: "fixed",
          top: "50%",
          left: "50%",
          zIndex: 10,
          transform: "translate(-50%, -50%)",
          background: theme.palette.surface.silver,
          borderRadius: theme.spacing(2),
          padding: theme.spacing(3),
          border: `1px solid ${theme.palette.border.dark}`,
          width: "fit-content",
          minWidth: "80%",
        }}>
        {term ? (
          <Typography sx={{ width: "100%", textAlign: "center" }} variant="subtitle">
            {term}
          </Typography>
        ) : null}
        {children}
      </Box>
    </>
  ) : (
    <></>
  );
};
