import axios from "axios";
import { ILoginUser, IInsertUser, IJoinGroup, IUpdatePurge } from "store/user-store";
import { IInsertJetton } from "store/jetton-list-store";
import axiosInstance from "./axiosInstance";
import qs from "qs";
import {
  BuyTonPreviewResponse,
  CabalDetails,
  CoinPriceRatio,
  Jetton,
  JettonBalance,
  JettonPrice,
  JettonPriceRatio,
  PaginatedData,
  SellTonPreviewResponse,
  Token,
  TokenCall,
  UserPortfolioAssets,
  UserPortfolioEarning,
  UserPortfolioJoinedGroup,
  TokenHolder,
  TokenPrice,
  Chain,
  UserBalance,
  UserPortfolioAssetsPaginatedData,
} from "types";
const { REACT_APP_API_URL, REACT_APP_TON_CLIENT_API_URL } = process.env;

const isTestnet = process.env.REACT_APP_TESTNET === "1";

async function redeemCode(code: string) {
  return {
    res: (await axiosInstance.post(REACT_APP_API_URL + "/v1/utils/code_redeem", { code })).data,
  };
}

async function verifyToken(refreshToken: string) {
  return {
    res: (await axiosInstance.post(REACT_APP_API_URL + "/v1/auth/verify-tokens", { refreshToken }))
      .data,
  };
}

async function insertJetton(data: IInsertJetton) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/insert";
  const res = await axiosInstance.post(endpoint, data);

  return {
    res: res.data,
    status: res.status,
  };
}

// V2
async function insertJettonV2(data: {
  image: string;
  name: string;
  tgUserId: number;
  tgUserName: string;
  reserveRate: string;
  description: string;
  symbol: string;
  decimals: string;
  language: string;
}) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/insert";
  const res = await axiosInstance.post(
    endpoint,
    {
      ...data,
      chain: isTestnet ? "ton_testnet" : "ton",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  return {
    res: res.data,
    status: res.status,
  };
}

async function getJettonList(chain: string, field?: string) {
  let endpoint = `${REACT_APP_API_URL}/v1/jettons?limit=100&sortBy=createdAt:desc&chain=${chain}`;
  if (field) endpoint += `&field=${field}`;
  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJetton(masterAddress: string) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/" + masterAddress;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonPrice(masterAddress: string, limit: number) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/price?limit=${limit}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonHolders(masterAddress: string, chain: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/holders?chain=${chain}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonTxns(masterAddress: string, chain: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/txns?chain=${chain}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonUpdates(
  masterAddress: string,
  jettonId: string,
  lt: number,
  chain: string,
) {
  const endpoint =
    REACT_APP_API_URL + `/v1/jettons/${masterAddress}/${jettonId}?lt=${lt}&chain=${chain}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getJettonsByOwner(ownerAddress: string, network: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/${ownerAddress}/profile/list?chain=${network}`;

  return {
    res: (await axiosInstance.get(endpoint)).data,
  };
}

async function getTonPrice() {
  const endpoint = REACT_APP_TON_CLIENT_API_URL + "/v2/rates?tokens=ton&currencies=usd";

  return (await axiosInstance.get(endpoint)).data;
}

async function loginUser(data: ILoginUser) {
  const endpoint = REACT_APP_API_URL + "/v1/auth/login";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function createUser(data: IInsertUser) {
  const endpoint = REACT_APP_API_URL + "/v1/auth/register";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function joinGroup(data: IJoinGroup) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/joinGroup";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function updateJettonPurge(data: IUpdatePurge) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/updatePurge";

  return {
    res: (await axiosInstance.post(endpoint, data)).data,
  };
}

async function uploadFile(file: File): Promise<string | undefined> {
  const endpoint = REACT_APP_API_URL + "/v1/utils/upload";

  const formData = new FormData();
  formData.append("file", file);

  try {
    return (
      await axiosInstance.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
    ).data.url;
  } catch (error) {
    console.error("Error uploading file:", error);
  }
}

async function getGroupChatMsgs(groupId: string) {
  const endpoint = REACT_APP_API_URL + "/v1/jettons/groupChat?tgGroupId=" + groupId;

  try {
    return (await axiosInstance.get(endpoint)).data;
  } catch (error) {
    console.error("Error getGroupChatMsgs:", error);
  }
}

async function getTokenBalance({
  tokenAddress,
  walletAddress,
  chain,
  type = "archive",
}: {
  tokenAddress: string;
  walletAddress: string;
  chain: string;
  type?: "realTime" | "archive";
}) {
  let result;
  try {
    const endpoint =
      REACT_APP_API_URL +
      `/v1/cabal/getCabalCalls/${tokenAddress}/balance?walletAddress=${walletAddress}&chain=${chain}&type=${type}`;

    result = (
      await axiosInstance.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).data;

    return result;
  } catch (error) {
    if (result?.meesage) {
      console.error("Error getTokenBalance:", result?.meesage);
    } else {
      console.error("Error getTokenBalance:", error);
    }

    return result ?? { message: "Error" };
  }
}

async function tokenTradePreview(
  {
    srcToken,
    destToken,
    senderAddress,
    nativeSpent,
    slippage,
    srcChain,
    destChain,
    tradeType,
    tgGroupId,
    isSellAll,
    destinationAddress,
    dstChainOrderAuthorityAddress,
  }: {
    srcToken: string;
    destToken: string;
    senderAddress: string;
    nativeSpent: number;
    slippage: number;
    srcChain: string;
    destChain: string;
    tradeType: string;
    tgGroupId?: number;
    isSellAll?: boolean;
    destinationAddress?: string;
    dstChainOrderAuthorityAddress?: string;
  },
  signal: AbortSignal,
) {
  let result;
  try {
    const endpoint = REACT_APP_API_URL + `/v1/trade/tokenSwapPreview`;

    result = (
      await axiosInstance.post(
        endpoint,
        {
          srcToken,
          destToken,
          senderAddress,
          nativeSpent,
          slippage,
          srcChain,
          destChain,
          tradeType,
          ...(srcChain !== destChain ? { destinationAddress, dstChainOrderAuthorityAddress } : {}),
          ...(tgGroupId ? { tgGroupId } : {}),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          signal,
        },
      )
    ).data;

    return result;
  } catch (error) {
    if (result?.meesage) {
      console.error("Error tokenTradePreview:", result.meesage);
    } else {
      console.error("Error tokenTradePreview:", error);
    }

    return {
      data: result ?? { message: "Error" },
    };
  }
}

async function getNativePrice({ tokenAddress, chain }: { tokenAddress: string; chain: string }) {
  let result;
  try {
    const endpoint =
      REACT_APP_API_URL + `/v1/trade/nativePrice?tokenAddress=${tokenAddress}&chain=${chain}`;

    result = (
      await axiosInstance.get(endpoint, {
        headers: {
          "Content-Type": "application/json",
        },
      })
    ).data;
    return result;
  } catch (error) {
    if (result?.meesage) {
      console.error("Error getNativePrice:", error);
    } else {
      console.error("Error getNativePrice:", error);
    }
    return result;
  }
}

async function tokenTrade({
  srcToken,
  destToken,
  senderAddress,
  nativeSpent,
  priceUsd,
  slippage,
  chain,
  tradeType,
  tgGroupId,
  isSellAll,
}: {
  srcToken: string;
  destToken: string;
  senderAddress: string;
  nativeSpent: number;
  priceUsd: number;
  slippage: number;
  chain: string;
  tradeType: string;
  tgGroupId: number;
  isSellAll: boolean;
}) {
  try {
    const endpoint = REACT_APP_API_URL + `/v1/trade/tokenSwap`;

    return (
      await axiosInstance.post(
        endpoint,
        {
          srcToken,
          destToken,
          senderAddress,
          nativeSpent,
          priceUsd,
          slippage,
          chain,
          tradeType,
          isSellAll,
          ...(tgGroupId ? { tgGroupId } : {}),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
    ).data;
  } catch (error) {
    console.error("Error getTokenBalance:", error);
  }
}

async function crossChainTrade({
  srcToken,
  destToken,
  senderAddress,
  nativeSpent,
  slippage,
  srcChain,
  destChain,
  tradeType,
  isSellAll,
  priceUsd,
  tgGroupId,
  isFromTrendingCall,
  destinationAddress,
  dstChainOrderAuthorityAddress,
  isSwap,
}: {
  srcToken: string;
  destToken: string;
  senderAddress: string;
  nativeSpent: number;
  slippage: number;
  srcChain: string;
  destChain: string;
  tradeType: string;
  isSellAll: boolean;
  priceUsd: number;
  tgGroupId?: number;
  isFromTrendingCall?: boolean;
  destinationAddress?: string;
  dstChainOrderAuthorityAddress?: string;
  isSwap?: boolean;
}) {
  try {
    const chain = isTestnet ? "ton_testnet" : "ton";
    const endpoint = REACT_APP_API_URL + `/v1/trade/tokenSwap?chain=${chain}`;

    return (
      await axiosInstance.post(
        endpoint,
        {
          srcToken,
          destToken,
          senderAddress,
          nativeSpent,
          slippage,
          srcChain,
          destChain,
          tradeType,
          isSellAll,
          priceUsd,
          ...(isFromTrendingCall ? { isFromTrendingCall } : {}),
          ...(srcChain !== destChain ? { destinationAddress, dstChainOrderAuthorityAddress } : {}),
          ...(tgGroupId ? { tgGroupId } : {}),
          ...(isSwap ? { isSwap } : {}),
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      )
    ).data;
  } catch (error) {
    console.error("Error getTokenBalance:", error);
  }
}

async function getCabalCalls({
  limit = 10,
  page = 1,
  sortBy = "createdAt:desc",
  selects,
  field,
  filter,
  selectedDay,
}: {
  sortBy?: string;
  limit?: number;
  page?: number;
  selects?: string[];
  field?: string;
  filter?: Record<string, string | undefined>;
  selectedDay?: "day7Result" | "day14Result" | "day1Result";
}) {
  const queryString = qs.stringify({
    selectedDay,
    field,
    filter: Object.entries(filter ?? {})
      .filter(([k, v]) => v !== undefined)
      .filter(([k, v]) => k !== "language" || v !== "all")
      .map(([k, v]) => `${k}:${v}`)
      .join(","),
    limit,
    page,
    sortBy,
    selects: selects?.join(" "),
  });
  const endpoint = REACT_APP_API_URL + `/v1/cabal/getCabalCalls?${queryString}`;
  return (await axiosInstance.get<PaginatedData<TokenCall>>(endpoint)).data;
}
async function getJoinedCabalCalls({
  limit = 10,
  page = 1,
  sortBy = "createdAt:desc",
  selects,
  field,
  filter,
  selectedDay,
}: {
  sortBy?: string;
  limit?: number;
  page?: number;
  selects?: string[];
  field?: string;
  filter?: Record<string, string | undefined>;
  selectedDay?: "day7Result" | "day14Result" | "day1Result";
}) {
  const queryString = qs.stringify({
    selectedDay,
    field,
    chain: isTestnet ? "ton_testnet" : "ton",
    filter: Object.entries(filter ?? {})
      .filter(([k, v]) => v !== undefined)
      .filter(([k, v]) => k !== "language" || v !== "all")
      .map(([k, v]) => `${k}:${v}`)
      .join(","),
    limit,
    page,
    sortBy,
    selects: selects?.join(" "),
  });
  const endpoint = REACT_APP_API_URL + `/v1/cabal/getJoinedCabalCalls?${queryString}`;
  return (await axiosInstance.get<PaginatedData<TokenCall>>(endpoint)).data;
}

async function getCabalDetails(id: string) {
  const endpoint = REACT_APP_API_URL + `/v1/jettons/groupId/${id}`;
  return (await axiosInstance.get<CabalDetails>(endpoint)).data;
}

async function getJettons({
  limit = 10,
  page = 1,
  sortBy,
  filter,
  field,
  selects,
  selectedDay = "all",
  name,
  tonAddress,
  tgUserId,
  language,
  groupType,
}: {
  limit?: number;
  page?: number;
  sortBy?: string;
  field?: string;
  filter?: Record<string, string | undefined>;
  selects?: string[];
  selectedDay?: "day7Result" | "day14Result" | "day1Result" | "all";
  name?: string;
  tonAddress?: string;
  tgUserId?: number;
  language?: string;
  groupType: string;
}) {
  const queryObject: Record<string, string | number | undefined> = {
    selectedDay,
    name,
    tgUserId,
    field,
    selects: selects?.join(" "),
    limit,
    page,
    sortBy,
    chain: isTestnet ? "ton_testnet" : "ton",
    filter: Object.entries(filter ?? {})
      .filter(([k, v]) => v !== undefined)
      .filter(([k, v]) => k !== "language" || v !== "all")
      .map(([k, v]) => `${k}:${v}`)
      .join(","),
  };

  if (tonAddress) {
    queryObject.tonAddress = tonAddress;
  }

  if (language && language !== "all") {
    queryObject.language = language;
  }

  if (groupType) {
    queryObject.groupType = groupType;
  }

  const queryString = qs.stringify(queryObject);
  const endpoint = REACT_APP_API_URL + `/v1/jettons?${queryString}`;
  return (await axiosInstance.get<PaginatedData<Jetton>>(endpoint)).data;
}

async function getJettonPriceV1({
  masterAddress,
  interval = "minute",
  sortBy = "createdAt:desc",
  page = 1,
  limit = 10000,
  selects = ["price", "updatedAt"],
}: {
  masterAddress: string;
  interval: "minute" | "hour" | "day" | "week";
  sortBy?: string;
  page?: number;
  limit?: number;
  selects?: string[];
}) {
  const queryString = qs.stringify({
    interval,
    sortBy,
    page,
    limit,
    selects: selects.join(" "),
  });

  const endpoint = REACT_APP_API_URL + `/v1/jettons/${masterAddress}/price?${queryString}`;
  return (await axiosInstance.get<PaginatedData<JettonPrice>>(endpoint)).data;
}

async function getJettonBalance({
  masterAddress,
  walletAddress,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  walletAddress: string;
  chain?: string;
}) {
  const endpoint =
    REACT_APP_API_URL +
    `/v1/jettons/${masterAddress}/balance?chain=${chain}&walletAddress=${walletAddress}`;
  return (await axiosInstance.get<JettonBalance>(endpoint)).data;
}

async function buyTonPreview({
  masterAddress,
  priceUsd,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  priceUsd: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/buyTonPreview`;
  return (
    await axiosInstance.post<BuyTonPreviewResponse>(endpoint, {
      masterAddress,
      priceUsd,
      chain,
    })
  ).data;
}

async function sellTonPreview({
  masterAddress,
  sellAmount,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  sellAmount: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/sellTonPreview`;
  return (
    await axiosInstance.post<SellTonPreviewResponse>(endpoint, {
      masterAddress,
      sellAmount,
      chain,
    })
  ).data;
}

async function buyTonJetton({
  masterAddress,
  tonAmount,
  tgGroupId,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  tonAmount: number;
  tgGroupId: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/buyTonJetton`;
  return (
    await axiosInstance.post<{ status: number; message: string }>(endpoint, {
      masterAddress,
      tonAmt: tonAmount,
      chain,
      tgGroupId,
    })
  ).data;
}

async function buyJettonFromBridge({
  masterAddress,
  tonAmt,
  tgGroupId,
  chain,
}: {
  masterAddress: string;
  tonAmt: number;
  tgGroupId: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/buyJettonFromBridge`;

  return (
    await axiosInstance.post<{
      jobId: string;
      jobType: string;
      jobStatus: string;
      message: string;
      txnHash: String;
      status: number;
    }>(endpoint, {
      masterAddress,
      tonAmt,
      chain: chain === "ton" ? (isTestnet ? "ton_testnet" : "ton") : chain,
      tgGroupId,
    })
  ).data;
}

async function sellTonJetton({
  masterAddress,
  jettonAmt,
  tgGroupId,
  chain = isTestnet ? "ton_testnet" : "ton",
}: {
  masterAddress: string;
  jettonAmt: number;
  tgGroupId: number;
  chain?: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/sellTonJetton`;
  return (
    await axiosInstance.post<{ status: number; message: string }>(endpoint, {
      masterAddress,
      jettonAmt,
      tgGroupId,
      chain,
    })
  ).data;
}

async function getToken({ tokenAddress, chain }: { tokenAddress: string; chain: Chain }) {
  const endpoint =
    REACT_APP_API_URL + `/v1/cabal/getCabalCalls/${tokenAddress}${chain ? `?chain=${chain}` : ""}`;
  return (await axiosInstance.get<Token>(endpoint)).data;
}

async function getTokenHolders({
  tokenAddress,
  chain,
  signal,
}: {
  tokenAddress: string;
  chain: string;
  signal?: AbortSignal;
}) {
  const endpoint =
    REACT_APP_API_URL + `/v1/cabal/getCabalCalls/${tokenAddress}/holders?chain=${chain}`;
  return (await axiosInstance.get<string>(endpoint, { signal })).data;
}

async function getCabalCallsPrice({
  pairAddress,
  chain,
  interval = "minute",
}: {
  pairAddress: string;
  chain: string;
  interval: "minute" | "hour" | "day" | "week";
}) {
  const queryString = qs.stringify({
    interval,
  });

  const endpoint =
    REACT_APP_API_URL +
    `/v1/cabal/getCabalCalls/${pairAddress}/price?chain=${chain}&${queryString}`;
  return (await axiosInstance.get<TokenPrice[]>(endpoint)).data;
}

async function traceEvent({
  masterAddress,
  walletAddress,
}: {
  masterAddress: string;
  walletAddress: string;
}) {
  const endpoint = REACT_APP_API_URL + `/v1/trade/traceEvent`;
  const queryString = qs.stringify({
    masterAddress,
    walletAddress,
    chain: isTestnet ? "ton_testnet" : "ton",
  });
  return (
    await axiosInstance.get<{ status: number; message: string }>(`${endpoint}?${queryString}`)
  ).data;
}

async function joinGroupV1(data: {
  tokenAddress: string;
  walletAddress: string;
  tgUserName: string;
  txnHash: string | null;
}) {
  const chain = isTestnet ? "ton_testnet" : "ton";
  const endpoint = REACT_APP_API_URL + `/v1/jettons/joinGroup?chain=${chain}`;

  return (
    await axiosInstance.post<{ status: number; message: string; link?: string }>(endpoint, data)
  ).data;
}

async function getUserPortfolioAssets({ limit = 10, page = 1 }) {
  const url = new URL(`${REACT_APP_API_URL!}/v1/portfolio/assets`);
  url.searchParams.append("sortBy", "createdAt:desc");
  url.searchParams.append("limit", String(limit));
  url.searchParams.append("page", String(page));
  return (
    await axiosInstance.get<UserPortfolioAssetsPaginatedData<UserPortfolioAssets>>(
      url.toString(),
      {},
    )
  ).data;
}

async function getUserPortfolioGroups({ limit = 10, page = 1 }) {
  const url = new URL(`${REACT_APP_API_URL!}/v1/portfolio/joinedGroups`);
  url.searchParams.append("sortBy", "createdAt:desc");
  url.searchParams.append("limit", String(limit));
  url.searchParams.append("page", String(page));
  url.searchParams.append("chain", String(isTestnet ? "ton_testnet" : "ton"));

  return (await axiosInstance.get<PaginatedData<UserPortfolioJoinedGroup>>(url.toString(), {}))
    .data;
}

async function getUserPortfolioEarning({ limit = 10, page = 1 }) {
  const url = new URL(`${REACT_APP_API_URL!}/v1/portfolio/earnings`);
  url.searchParams.append("sortBy", "createdAt:desc");
  url.searchParams.append("limit", String(limit));
  url.searchParams.append("page", String(page));

  return (await axiosInstance.get<PaginatedData<UserPortfolioEarning>>(url.toString(), {})).data;
}

async function getUserBalance() {
  return (await axiosInstance.get<UserBalance>(`${REACT_APP_API_URL!}/v1/user/balance`, {})).data;
}

async function approveAllowance({
  chain,
  srcToken,
  destToken,
  senderAddress,
  amount,
}: {
  chain: string;
  srcToken: string;
  destToken: string;
  senderAddress: string;
  amount: number;
}) {
  return (
    await axiosInstance.post(
      `${REACT_APP_API_URL!}/v1/trade/allowanceApproval`,
      {
        chain,
        srcToken,
        destToken,
        senderAddress,
        amount,
      },
      {},
    )
  ).data;
}

async function getPrivateKey({ chain }: { chain: Chain }) {
  let endpoint;
  if (chain === "ton") {
    endpoint = REACT_APP_API_URL + `/v1/wallet/exportTonPrivateKey`;
  } else {
    endpoint = REACT_APP_API_URL + `/v1/wallet/exportWallet?chain=${chain}`;
  }

  return {
    res: (await axiosInstance.get(endpoint, {})).data,
  };
}

async function getCurrentAllowance({
  chain,
  srcToken,
  destToken,
  senderAddress,
}: {
  chain: string;
  srcToken: string;
  destToken: string;
  senderAddress: string;
}) {
  return (
    await axiosInstance.post(
      `${REACT_APP_API_URL!}/v1/trade/allowance`,
      {
        chain,
        srcToken,
        destToken,
        senderAddress,
      },
      {},
    )
  ).data;
}

async function createWallet() {
  return (await axiosInstance.post(`${REACT_APP_API_URL}/v1/user/createWallet`, {})).data;
}

async function claimReward({
  chain,
  feeToken,
  totalFeeAmounts,
}: {
  chain: string;
  feeToken: string;
  totalFeeAmounts: number;
}) {
  try {
    const endpoint = `${REACT_APP_API_URL}/v1/portfolio/claim`;
    return (
      await axiosInstance.post(
        endpoint,
        {
          chain,
          feeToken,
          totalFeeAmounts,
        },
        {},
      )
    ).data;
  } catch (error) {
    console.error("Error claimReward:", error);
  }
}

async function createSolTokenAccount({
  toAddress,
  feeToken,
}: {
  toAddress: string;
  feeToken: string;
}) {
  const endpoint = `${REACT_APP_API_URL}/v1/portfolio/createSolTokenAcc`;
  return (
    await axiosInstance.post(
      endpoint,
      {
        toAddress,
        feeToken,
      },
      {},
    )
  ).data;
}

async function txnUpdate({
  srcToken,
  destToken,
  senderAddress,
  priceUsd,
  srcChain,
  destChain,
  tradeType,
  tgGroupId,
  hash,
  amount,
  feeToken,
  quantity,
  feeAmount,
  feeChain,
  isFromTrendingCall,
}: {
  srcToken: string;
  destToken: string;
  senderAddress: string;
  priceUsd: number;
  srcChain: string;
  destChain: string;
  tradeType: string;
  tgGroupId?: number;
  hash: string;
  amount: number;
  feeToken: string;
  quantity: number;
  feeAmount: number;
  feeChain: string;
  isFromTrendingCall?: boolean;
}) {
  const endpoint = `${REACT_APP_API_URL}/v1/trade/txnUpdate`;
  return (
    await axiosInstance.post(
      endpoint,
      {
        srcToken,
        destToken,
        senderAddress,
        priceUsd,
        srcChain,
        destChain,
        tradeType,
        hash,
        amount,
        feeToken,
        quantity,
        feeAmount,
        feeChain,
        ...(isFromTrendingCall ? { isFromTrendingCall } : {}),
        ...(tgGroupId ? { tgGroupId } : {}),
      },
      {},
    )
  ).data;
}

const getJoinedCabal = async ({
  tgUserId,
  tonAddress,
  language,
}: {
  tgUserId: number;
  tonAddress: string;
  language: string;
}) => {
  const url = new URL(`${REACT_APP_API_URL!}/v1/jettons/joinedCabals`);
  if (language !== "all") {
    url.searchParams.append("language", language);
  }
  url.searchParams.append("tgUserId", tgUserId.toString());
  url.searchParams.append("tonAddress", tonAddress);
  url.searchParams.append("chain", isTestnet ? "ton_testnet" : "ton");

  return (await axiosInstance.get(url.toString())).data;
};

const traceJobStatus = async (jobId: string, jobType: string) => {
  try {
    const url = new URL(`${REACT_APP_API_URL}/v1/trade/traceJobStatus`);
    url.searchParams.append("jobId", jobId);
    url.searchParams.append("jobType", jobType);

    return (await axiosInstance.get(url.toString())).data;
  } catch (error) {
    console.error("Error traceJobStatus:", error);
  }
};

const unwrapEth = async ({
  amount,
  senderAddress,
  tokenAddress,
  chain,
}: {
  amount: number;
  senderAddress: string;
  tokenAddress: string;
  chain: string;
}) => {
  try {
    const endpoint = `${REACT_APP_API_URL}/v1/portfolio/unwrapEth`;

    return (
      await axiosInstance.post(
        endpoint,
        {
          amount,
          senderAddress,
          tokenAddress,
          chain,
        },
        {},
      )
    ).data;
  } catch (error) {
    console.error("Error unwrapEth:", error);
  }
};

const unwrapSol = async ({
  senderAddress,
  tokenAddress,
}: {
  senderAddress: string;
  tokenAddress: string;
}) => {
  try {
    const endpoint = `${REACT_APP_API_URL}/v1/portfolio/unwrapSol`;

    return (
      await axiosInstance.post(
        endpoint,
        {
          senderAddress,
          tokenAddress,
        },
        {},
      )
    ).data;
  } catch (error) {
    console.error("Error unwrapEth:", error);
  }
};

const getTradeAllTrendingTokens = async ({
  sortBy,
  limit,
  page,
  chain,
}: {
  sortBy: string;
  limit: number;
  page: number;
  chain: string;
}) => {
  try {
    const url = new URL(`${REACT_APP_API_URL!}/v1/trade/allTrendingTokens`);

    url.searchParams.append("sortBy", sortBy);
    url.searchParams.append("limit", String(limit));
    url.searchParams.append("page", String(page));
    if (chain !== "all") {
      url.searchParams.append("chain", chain);
    }

    const result = (await axiosInstance.get(url.toString())).data;

    if (result && result.totalResults > 0) {
      return result;
    } else {
      return {
        results: [],
        totalResults: 0,
        totalPages: 1,
        page: 1,
        limit: limit,
        hasNext: false,
      };
    }
  } catch (error) {
    console.error("Failed to Get All Trade Tokens", error);
    return {
      results: [],
      totalResults: 0,
      totalPages: 1,
      page: 1,
      limit: limit,
      hasNext: false,
    };
  }
};

const getTradeJoinedGroupTokens = async ({
  sortBy,
  limit,
  page,
  chain,
}: {
  sortBy: string;
  limit: number;
  page: number;
  chain: string;
}) => {
  try {
    const url = new URL(`${REACT_APP_API_URL!}/v1/trade/groupTrendingTokens`);

    url.searchParams.append("sortBy", sortBy);
    url.searchParams.append("limit", String(limit));
    url.searchParams.append("page", String(page));
    if (chain !== "all") {
      url.searchParams.append("chain", chain);
    }

    const result = (await axiosInstance.get(url.toString())).data;

    if (result && result.totalResults > 0) {
      return result;
    } else {
      return {
        results: [],
        totalResults: 0,
        totalPages: 1,
        page: 1,
        limit: limit,
        hasNext: false,
      };
    }
  } catch (error) {
    console.error("Failed to Get Trade Joined Group Tokens", error);
    return {
      results: [],
      totalResults: 0,
      totalPages: 1,
      page: 1,
      limit: limit,
      hasNext: false,
    };
  }
};

const getTradeToken = async ({ tokenAddress }: { tokenAddress: string }) => {
  try {
    const url = new URL(`${REACT_APP_API_URL!}/v1/trade/getToken`);

    url.searchParams.append("tokenAddress", tokenAddress);

    return (await axiosInstance.get(url.toString())).data;
  } catch (error) {
    console.error("Failed to get trade token:", error);
  }
};

const sendMemeToken = async ({
  receiverAddress,
  tokenAddress,
  amount,
  chain,
}: {
  receiverAddress: string;
  tokenAddress: string;
  amount: number;
  chain: string;
}) => {
  try {
    return (
      await axiosInstance.post(`${REACT_APP_API_URL!}/v1/portfolio/sendTokens`, {
        receiverAddress,
        tokenAddress,
        amount,
        chain,
      })
    ).data;
  } catch (error) {
    console.error("Failed to send meme token:", error);
  }
};

const sendNativeToken = async ({
  receiverAddress,
  amount,
  chain,
  memo,
}: {
  receiverAddress: string;
  amount: number;
  chain: string;
  memo?: string;
}) => {
  try {
    return (
      await axiosInstance.post(`${REACT_APP_API_URL!}/v1/portfolio/sendNativeTokens`, {
        receiverAddress,
        amount,
        chain,
        ...(memo ? { memo } : {}),
      })
    ).data;
  } catch (error) {
    console.error("Failed to send native token:", error);
  }
};

const axiosService = {
  getTradeAllTrendingTokens,
  getTradeJoinedGroupTokens,
  getTradeToken,
  getNativePrice,
  getJettonUpdates,
  getJettonPrice,
  getJettonList,
  getTonPrice,
  getJetton,
  insertJetton,
  insertJettonV2,
  getJettonsByOwner,
  getJettonHolders,
  getJettonTxns,
  loginUser,
  createUser,
  joinGroup,
  updateJettonPurge,
  redeemCode,
  verifyToken,
  uploadFile,
  getGroupChatMsgs,
  getCabalCalls,
  getJoinedCabalCalls,
  getCabalDetails,
  getJettons,
  getJettonPriceV1,
  getJettonBalance,
  buyTonPreview,
  buyTonJetton,
  sellTonPreview,
  sellTonJetton,
  getTokenHolders,
  getToken,
  getCabalCallsPrice,
  getTokenBalance,
  tokenTradePreview,
  tokenTrade,
  crossChainTrade,
  traceEvent,
  joinGroupV1,
  getUserPortfolioAssets,
  getUserPortfolioGroups,
  getUserPortfolioEarning,
  getUserBalance,
  approveAllowance,
  getPrivateKey,
  getCurrentAllowance,
  createWallet,
  claimReward,
  createSolTokenAccount,
  txnUpdate,
  getJoinedCabal,
  buyJettonFromBridge,
  traceJobStatus,
  unwrapEth,
  unwrapSol,
  sendMemeToken,
  sendNativeToken,
};

export default axiosService;
