import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

export interface TradeStoreState {
  nativeToken: {
    amount: {
      solana: number | null;
      ethereum: number | null;
      base: number | null;
      ton: number | null;
      // TODO: Revert for berachain
      berachain: number | null;
    };
    usd: {
      solana: number | null;
      ethereum: number | null;
      base: number | null;
      ton: number | null;
      // TODO: Revert for berachain
      berachain: number | null;
    };
  };
}

const { persistAtom } = recoilPersist({
  key: "tradeStateAtom",
});

const tradeStateAtom = atom<TradeStoreState>({
  key: "tradeStateAtom",
  default: {
    nativeToken: {
      amount: {
        solana: null,
        ethereum: null,
        base: null,
        ton: null,
        // TODO: Revert for berachain
        berachain: null,
      },
      usd: {
        solana: null,
        ethereum: null,
        base: null,
        ton: null,
        // TODO: Revert for berachain
        berachain: null,
      },
    },
  },
  effects_UNSTABLE: [persistAtom],
});

export { tradeStateAtom };
