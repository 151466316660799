import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HeaderLogo } from "./styles";
import { useRedeemCode } from "hooks/useRedeemCode";
import { RetroCard } from "components/RetroCard";
import CabalTownLogo from "assets/icons/cabal-town-logo.png";
import { OTP } from "components/OTP";
import { RetroButton } from "components/RetroButton";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";

export const GatedPageV2 = () => {
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  const { redeemCode } = useRedeemCode({});
  const theme = useTheme();

  // Code
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (isShowHeader) {
      setIsShowHeader(false);
    }

    return () => {
      setIsShowHeader(true);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // height: "100%",
        width: "100%",
        padding: "16px",
      }}>
      <Box
        sx={{
          display: "flex",
          width: "fit-content",
          margin: "0px auto 16px",
        }}>
        <RetroCard>
          <Box
            sx={{
              padding: "17px 32.5px 8px",
            }}>
            <HeaderLogo src={CabalTownLogo} alt="cabal-town-logo" />
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "100%", margin: "auto" }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              padding: "20px",
              gap: "16px",
              marginBottom: "16px",
            }}>
            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                flexWrap: "wrap",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                gap: "0 20px",
              }}>
              <Typography variant="title-h1">CATCH ALL </Typography>
              <Typography variant="title-h1">ALPHA HERE</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
              }}>
              <OTP separator={<span></span>} value={otp} onChange={setOtp} length={6} />
              <Typography variant="body-md">Enter your Secret Code</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0 20px 20px",
            }}>
            <RetroButton
              disabled={otp.length !== 6}
              variant={otp.length === 6 ? "primary" : "disabled"}
              color={otp.length === 6 ? "primary" : "text.secondary"}
              onClick={() => redeemCode(otp)}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography variant="title-h3">Confirm</Typography>
              </Box>
            </RetroButton>
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "100%", margin: "16px auto 0" }}>
        <RetroCard>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              padding: "20px",
            }}>
            <Typography variant="title-h1">WANT CODE?</Typography>
            <Typography variant="body-md" color="text.secondary">
              Follow the 2-step quide below!
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "8px",
                width: "100%",
                margin: "24px 0 0",
              }}>
              {[
                {
                  title: "Step 1",
                  button: "Follow on x",
                  navigate: () => window.open("https://x.com/cabaltown"),
                },
                {
                  title: "Step 2",
                  button: "Message us",
                  navigate: () => window.open("https://t.me/cabaltownofficial"),
                },
              ].map((step) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "16px",
                    padding: "12px",
                    width: "100%",
                    border: `1px solid ${theme.palette.border.dark}`,
                    background: theme.palette.surface.silver,
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      borderRadius: "16px",
                      padding: "12px 24px",
                      border: `1px solid ${theme.palette.border.dark}`,
                      background: theme.palette.surface["container-bg"],
                    }}>
                    <Typography variant="body-md">{step.title}</Typography>
                  </Box>
                  <Box sx={{ width: "137px" }}>
                    <RetroButton variant="white" onClick={step.navigate}>
                      <Box
                        sx={{
                          padding: "10px 12px",
                        }}>
                        <Typography variant="button-md">{step.button}</Typography>
                      </Box>
                    </RetroButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </RetroCard>
      </Box>
    </Box>
  );
};
