import { useRecoilState } from "recoil";
import { toastStateAtom } from ".";
import { useRef } from "react";

let expiredTs: number | null = null;

const useToastStore = () => {
  const [toastState, setToastState] = useRecoilState(toastStateAtom);

  const showToast = (params: {
    variant: "success" | "error" | "info";
    message?: string;
    duration?: number;
  }) => {
    if (toastState.expiredTs !== undefined) {
      expiredTs = toastState.expiredTs;
      if (toastState.expiredTs !== null) {
        if (Date.now() >= toastState.expiredTs) {
          resetToast();
        }
      }
    }

    setToastState({
      ...params,
      expiredTs: params.duration ? Date.now() + params.duration : null,
    });

    if (params.duration) {
      setTimeout(() => {
        if (expiredTs === toastState.expiredTs) {
          resetToast();
        }
      }, params.duration);
    }
  };

  const resetToast = () => {
    setToastState((prev) => ({ ...prev, message: undefined }));
  };

  return { toastState, showToast, resetToast };
};

export default useToastStore;
