import { useEffect } from "react";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { PortfolioContainer } from "./styled";
import { WalletDetail } from "./WalletDetail";
import { Profile } from "./Profile";
import { RetroCard } from "components/RetroCard";
import { Box, useTheme } from "@mui/material";
import { PortfolioDetail } from "./PortfolioDetail";
import { RetroButton } from "components/RetroButton";
import { Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "consts";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { PricePerformanceWithPercentagBlock } from "components/PercentageBrick";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";

const Portfolio = () => {
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();
  const { hasWallet } = useUserStoreV2();
  const theme = useTheme();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["portfolio-total-assets", hasWallet],
    queryFn: async () => {
      return axiosService.getUserPortfolioAssets({ limit: 10 });
    },
  });

  useEffect(() => {
    if (isShowHeader) {
      setIsShowHeader(false);
    }

    return () => {
      setIsShowHeader(true);
    };
  }, []);

  const navagteToCreateCabal = () => {
    navigate(ROUTES.create);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        paddingTop: "20px",
      }}>
      <Box sx={{ width: "100%" }}>
        <RetroCard size="sm">
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: "20px",
            }}>
            <PortfolioContainer>
              <Profile />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}>
                <RetroButton
                  variant={hasWallet ? "primary" : "white"}
                  onClick={navagteToCreateCabal}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "4px",
                      padding: "8px",
                    }}>
                    <Add
                      style={{ color: theme.palette.text.brand, fontWeight: "bolder" }}
                      fontSize="small"
                    />
                    <Typography
                      variant="button-md"
                      color={hasWallet ? "text.brand" : "text.btn-dark"}>
                      Create Group
                    </Typography>
                  </Box>
                </RetroButton>
              </Box>
              {hasWallet ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "12px",
                    width: "100%",
                    border: `1px solid ${theme.palette.border.dark}`,
                    borderRadius: "16px",
                    bgcolor: theme.palette.surface["container-bg"],
                  }}>
                  {!isLoading && data ? (
                    <>
                      <Typography variant="title-h1">
                        ${data.totalAssetsValue.toFixed(2)}
                      </Typography>
                      <PricePerformanceWithPercentagBlock
                        performance={data.totalPerformanceInUsd}
                        percentage={data.totalPerformance}
                      />
                    </>
                  ) : (
                    <Typography variant="title-h1">Loading...</Typography>
                  )}
                </Box>
              ) : null}
              <WalletDetail />
            </PortfolioContainer>
          </Box>
        </RetroCard>
      </Box>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}>
          <PortfolioContainer>
            <PortfolioDetail />
          </PortfolioContainer>
        </Box>
        {/* </RetroCard> */}
      </Box>
    </Box>
  );
};

export { Portfolio };
