/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { decryptionStartParams } from "utils";
import { useRedeemCode } from "hooks/useRedeemCode";
import { ROUTES } from "consts";

export const useConsumeStartParams = () => {
  const { isRedeemed } = useRedeemCode({ isAutoCheck: true });
  const [isConsumed, setIsConsumed] = useState(false);
  const navigate = useNavigatePreserveQuery();

  useEffect(() => {
    if (isRedeemed && !isConsumed && window?.Telegram?.WebApp?.initDataUnsafe?.start_param) {
      const decodedParams = decryptionStartParams(
        window?.Telegram?.WebApp?.initDataUnsafe?.start_param,
      );
      if (!decodedParams) {
        return;
      }

      let search = "";
      let pathname = "";

      if (decodedParams?.includes("tokenAddress")) {
        const url = decodedParams?.split("&")?.reduce((acc, cur) => {
          const [key, value] = cur.split("=");
          acc = acc.replace(`{${key}}`, value);
          return acc;
        }, "{tokenAddress}?chain={chain}&groupId={groupId}");

        const [p, s] = (url as string).split("?");
        pathname = ROUTES.tokenId.replace(":id", p);
        search = s;
      } else if (decodedParams?.includes("tgUserId")) {
        const [group, user] = decodedParams?.split("&");
        const [_, groupId] = group?.split("=");
        const [__, tgUserId] = user?.split("=");
        pathname = ROUTES.cabalId.replace(":id", groupId);
        localStorage.setItem("referredBy", tgUserId);
      } else {
        const [_, groupId] = decodedParams?.split("=");
        pathname = ROUTES.cabalId.replace(":id", groupId);
      }

      setIsConsumed(true);

      delete window?.Telegram?.WebApp?.initDataUnsafe?.start_param;

      navigate({
        pathname,
        search,
      });
    }
  }, [isConsumed, isRedeemed, navigate]);
};
