import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import { CreateCabalV2 } from "components/CreateCabal/CreateCabalV2";
import { useLocation, useNavigate } from "react-router-dom";
import FullPageWrapper from "components/FullPageWrapper";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { ROUTES } from "consts";

const CreatePage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { search, state: navStates } = useLocation();
  const isFromExplorer = new URLSearchParams(search).get("from") === "explorer";

  return (
    <FullPageWrapper>
      <Container>
        <Box paddingY={theme.spacing(2.5)}>
          <Button
            sx={{ padding: "0", cursor: "pointer" }}
            onClick={() => navigate(isFromExplorer ? ROUTES.explorer : ROUTES.portfolio)}>
            <BackArrow />
            <Typography variant="button-md" color="text.secondary">
              {isFromExplorer ? "Groups" : "Portfolio"}
            </Typography>
          </Button>
        </Box>
      </Container>
      <Container sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
        <CreateCabalV2 />
      </Container>
    </FullPageWrapper>
  );
};

export { CreatePage };
