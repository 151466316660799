import BigNumber from "bignumber.js";
import { BN } from "bn.js";
import { zeroAddress } from "lib/utils";
import { Address } from "ton";
import CryptoJS from "crypto-js";

export const scannerUrl = (isSandbox?: boolean, regularAddress?: boolean) => {
  if (isSandbox) {
    return `https://sandbox.tonwhales.com/explorer/address`;
  }

  if (regularAddress) {
    return `https://tonscan.org/address`;
  }

  return `https://tonscan.org/jetton`;
};

export const getUrlParam = (name: string) => {
  const query = new URLSearchParams(window.location.search);
  return query.get(name);
};

export const isValidAddress = (address: string, errorText?: string) => {
  try {
    const result = Address.parse(address);
    if (result && result.toFriendly() === zeroAddress().toFriendly()) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
};

const ten = new BigNumber(10);

export function toDecimalsBN(num: number | string, decimals: number | string) {
  return new BN(BigNumber(num).multipliedBy(ten.pow(decimals)).toFixed(0));
}

export function fromDecimals(num: number | string, decimals: number | string) {
  return BigNumber(num).div(ten.pow(decimals)).toFixed();
}

export const onConnect = () => {
  const container = document.getElementById("ton-connect-button");
  const btn = container?.querySelector("button");

  if (btn) {
    btn.click();
  }
};

export const LANGUAGES = {
  en: "English",
  zh: "Chinese",
  ko: "Korean",
  ru: "Russian",
};

export function copyToClipboard(content: string): boolean {
  try {
    navigator.clipboard.writeText(content);
    return true;
  } catch (e) {
    return false;
  }
}

const SI_SYMBOL = ["", "K", "M", "B", "T", "Quad", "Quint"];

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 2,
});

export function abbreviateNumber(number: number) {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix

  return numberFormatter.format(scaled) + suffix;
}

export function toFixedIfNecessary(value: string, dp: number) {
  return +parseFloat(value).toFixed(dp);
}

export function decodePrivateKeyHash(hash: string, tgUserId: number, iv: string) {
  // Generate the key
  const key = CryptoJS.SHA256(tgUserId.toString()).toString().slice(0, 32);

  // Convert IV and encrypted data from hex to WordArray
  const ivWordArray = CryptoJS.enc.Hex.parse(iv);
  const encryptedDataWordArray = CryptoJS.enc.Hex.parse(hash);

  // Decrypt the data
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedDataWordArray } as any,
    CryptoJS.enc.Utf8.parse(key),
    { iv: ivWordArray, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 },
  );

  // Convert decrypted data to UTF-8 string
  const privateKey = decrypted.toString(CryptoJS.enc.Utf8);

  return privateKey;
}

export function decryptionStartParams(encryptedData: string) {
  const secret = "CABAL_TOWN";
  // Generate the key (correctly as a WordArray)
  const key = CryptoJS.SHA256(secret); // Key is now a WordArray

  // Convert encrypted data from hex to WordArray
  const encryptedDataWordArray = CryptoJS.enc.Hex.parse(encryptedData);

  try {
    // Decrypt the data using ECB mode
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedDataWordArray } as any,
      key, // Use the WordArray key directly
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 },
    );

    // Convert decrypted data to UTF-8 string
    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return decryptedString;
  } catch (error) {
    console.error("Decryption error:", error);
    return null;
  }
}

export function encryptionStartParams(decryptedData: string) {
  const secret = "CABAL_TOWN";
  // Generate the key
  const key = CryptoJS.SHA256(secret);

  // Convert the input string to WordArray
  const dataWordArray = CryptoJS.enc.Utf8.parse(decryptedData);

  try {
    // Encrypt the data using ECB mode
    const encrypted = CryptoJS.AES.encrypt(dataWordArray, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert the encrypted data to hex string
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
  } catch (error) {
    console.error("Encryption error:", error);
    return null;
  }
}
