import { Box, styled } from "@mui/material";
import WEthIcon from "assets/icons/tokens/weth.avif";
import SolanaIcon from "assets/icons/chains/solana.png";
import EthereumIcon from "assets/icons/chains/ethereum.png";
import TonIcon from "assets/icons/chains/ton.png";
import BaseIcon from "assets/icons/chains/base.png";
import BeraIcon from "assets/icons/chains/bera.png";
import DefaultAvatarIcon from "assets/icons/default-avatar.png";
import { Chain } from "types";

const CHAIN_COLOR_MAP: Record<Chain, string> = {
  solana: "#232323",
  ethereum: "#6481E7",
  ton: "#338BC9",
  base: "#0052FF",
  berachain: "#FFD700",
};

export const CHAIN_BADGE_MAP: Record<string, string> = {
  solana: SolanaIcon,
  ethereum: EthereumIcon,
  ton: TonIcon,
  base: BaseIcon,
  berachain: BeraIcon,
};

export const NATIVE_TOKEN_MAP: Record<string, string> = {
  SOL: SolanaIcon,
  WSOL: SolanaIcon,
  ETH: EthereumIcon,
  WETH: WEthIcon,
  TON: TonIcon,
  BERA: BeraIcon,
};

const CoinContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const TokenImage = styled("img")<{ chain?: Chain; size: "sm" | "md" }>(
  ({ theme, chain, size }) => ({
    width: size === "sm" ? theme.spacing(5) : theme.spacing(10),
    height: size === "sm" ? theme.spacing(5) : theme.spacing(10),
    borderRadius: "50%",
    objectFit: "cover",
    objectPosition: "center",
    border: `1px solid ${chain ? CHAIN_COLOR_MAP[chain] : theme.palette.border.dark}`,
    background: theme.palette.surface.container,
  }),
);

const ChainBadge = styled("img")<{ size: "sm" | "md" }>(({ theme, size }) => ({
  position: "absolute",
  top: size === "sm" ? theme.spacing(-0.1) : theme.spacing(-0.5),
  right: size === "sm" ? theme.spacing(-0.5) : theme.spacing(-0.5),
  width: size === "sm" ? theme.spacing(2) : theme.spacing(3),
  height: size === "sm" ? theme.spacing(2) : theme.spacing(3),
}));

type CoinAvatarProps = {
  iconUri: string;
  alt?: string;
  chain?: Chain;
  size?: "sm" | "md";
  style?: React.CSSProperties;
  badgeStyle?: React.CSSProperties;
};

const CoinAvatar = ({ iconUri, alt, chain, size = "sm", style, badgeStyle }: CoinAvatarProps) => {
  const handleOnError = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    event.currentTarget.src = DefaultAvatarIcon;
  };
  return (
    <CoinContainer>
      <TokenImage
        src={iconUri}
        alt={alt}
        chain={chain}
        size={size}
        onError={handleOnError}
        style={style}
      />
      {chain && (
        <ChainBadge src={CHAIN_BADGE_MAP[chain]} alt={chain} size={size} style={badgeStyle} />
      )}
    </CoinContainer>
  );
};

export default CoinAvatar;
