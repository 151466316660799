import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { Box, Divider, MenuItem, Typography, useTheme } from "@mui/material";
import { RetroButton } from "components/RetroButton";
import { useEffect, useMemo, useRef, useState } from "react";
import { ExportPrivateKeyModal } from "./ExportPrivateKeyModal";
import { decodePrivateKeyHash } from "utils";
import { useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { ArrowDownward, ArrowUpward, Close, PaidOutlined } from "@mui/icons-material";
import { CrossChainDepositModal } from "components/Deposit";
import useToastStore from "store/toast-store/useToastStore";
import { DarkDrawer } from "components/TradeDrawer";
import { CircleButton } from "components/CircleButton";
import { RetroCard } from "components/RetroCard";
import { StyledDrawer, StyledInputV2, StyledSelect } from "./styled";
import CoinAvatar, { NATIVE_TOKEN_MAP } from "components/CoinAvatar";
import { SmallNumberView } from "components/SmallValueView/SmallValueView";
import { checkAddressChain } from "lib/utils";
import { ReactComponent as SendIcon } from "assets/icons/portfolio-send.svg";

const NATIVE_TOKEN_LIST = ["TON", "SOL", "ETH", "BERA"];

enum NetworkShortForm {
  BASE,
  SOL,
  TON,
}

const WalletTypeMaps = {
  BASE: "ethAddress",
  SOL: "solAddress",
  TON: "tonAddress",
};

const WalletDetail = () => {
  const { tgUserId, hasWallet, createWallet } = useUserStoreV2();
  const { showToast } = useToastStore();

  const [isOpennedExportKeyModal, setIsOpennedExportKeyModal] = useState(false);
  const [isOpennedDepositModal, setIsOpennedDepositModal] = useState(false);
  // const [isExpendWallets, setIsExpendWallets] = useState(false);
  // const [copiedWalletIndex, setCopiedWalletIndex] = useState<number | null>(null);
  const [isOpenSendDrawer, setIsOpenSendDrawer] = useState(false);

  const [keys, setKeys] = useState<Record<string, string | null>>({
    ethereum: null,
    solana: null,
    ton: null,
  });
  const [isAllKeysLoaded, setIsAllKeysLoaded] = useState(false);

  // Get private key hashes
  const { data: ethereumPrivateKeyData, isLoading: isLoadingEth } = useQuery({
    queryKey: ["ethPrivateKey"],
    queryFn: async () => {
      const { res } = await axiosService.getPrivateKey({
        chain: "ethereum",
      });
      return res;
    },
    enabled: !!hasWallet && !isAllKeysLoaded,
  });

  const { data: solanaPrivateKeyData, isLoading: isLoadingSol } = useQuery({
    queryKey: ["solPrivateKey"],
    queryFn: async () => {
      const { res } = await axiosService.getPrivateKey({
        chain: "solana",
      });
      return res;
    },
    enabled: !!hasWallet && !isAllKeysLoaded,
  });

  const { data: tonPrivateKeyData, isLoading: isLoadingTon } = useQuery({
    queryKey: ["tonPrivateKey"],
    queryFn: async () => {
      const { res } = await axiosService.getPrivateKey({
        chain: "ton",
      });
      return res;
    },
    enabled: !!hasWallet && !isAllKeysLoaded,
  });

  const { data: fullAssetsInfo, isLoading: isFullAssetsInfoLoading } = useQuery({
    queryKey: ["withdraw-assets-info"],
    queryFn: async () => {
      return axiosService.getUserPortfolioAssets({ limit: 10, page: 1 });
    },
  });

  const { data: fullAssetsData, isLoading: isFullAssetsDataLoading } = useQuery({
    queryKey: ["withdraw-assets-data", fullAssetsInfo],
    queryFn: async () => {
      if (!fullAssetsInfo || !fullAssetsInfo.results.length)
        return {
          results: [],
        };

      return axiosService.getUserPortfolioAssets({ limit: fullAssetsInfo?.totalResults, page: 1 });
    },
    enabled: !!fullAssetsInfo && !isFullAssetsInfoLoading,
  });

  useEffect(() => {
    if (!isLoadingSol && solanaPrivateKeyData) {
      setKeys((pre) => ({
        ...pre,
        solana: decodePrivateKeyHash(
          solanaPrivateKeyData.encryptedData,
          tgUserId!,
          solanaPrivateKeyData.iv,
        ),
      }));
    }
    if (!isLoadingTon && tonPrivateKeyData) {
      setKeys((pre) => ({
        ...pre,
        ton: decodePrivateKeyHash(tonPrivateKeyData.encryptedData, tgUserId!, tonPrivateKeyData.iv),
      }));
    }
    if (!isLoadingEth && ethereumPrivateKeyData) {
      setKeys((pre) => ({
        ...pre,
        ethereum: decodePrivateKeyHash(
          ethereumPrivateKeyData.encryptedData,
          tgUserId!,
          ethereumPrivateKeyData.iv,
        ),
      }));
    }
  }, [tonPrivateKeyData, solanaPrivateKeyData, ethereumPrivateKeyData]);

  useEffect(() => {
    if (Object.keys(keys).some((key) => keys[key] === null)) {
      setIsAllKeysLoaded(false);
    } else {
      setIsAllKeysLoaded(true);
    }
  }, [keys]);

  const handleOpenDepositeModal = () => {
    setIsOpennedDepositModal(true);
  };

  const handleCloseDepositeModal = () => {
    setIsOpennedDepositModal(false);
  };

  const handleOpenExportKeyModal = () => {
    setIsOpennedExportKeyModal(true);
  };

  const handleCloseExportKeyModal = () => {
    setIsOpennedExportKeyModal(false);
  };

  const handleOpenSendDrawer = () => {
    setIsOpenSendDrawer(true);
  };

  const handleCloseSendDrawer = () => {
    setIsOpenSendDrawer(false);
  };

  return (
    <>
      {hasWallet ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            gap: "8px",
          }}>
          {/* Deposit */}
          <RetroButton variant="white" onClick={handleOpenDepositeModal}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "4px",
                flex: "1",
              }}>
              <Typography variant="button-md">DEPOSIT</Typography>
              <ArrowDownward fontSize="small" />
            </Box>
          </RetroButton>
          {/* Send */}
          <RetroButton
            variant="white"
            disabled={
              isFullAssetsInfoLoading ||
              isFullAssetsDataLoading ||
              !fullAssetsData ||
              !fullAssetsData.results ||
              !fullAssetsData.results.length
            }
            onClick={handleOpenSendDrawer}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "4px",
                flex: "1",
              }}>
              {isFullAssetsInfoLoading || isFullAssetsDataLoading ? (
                <>
                  <Typography variant="button-md">Loading</Typography>
                  <Box height="28px" />
                </>
              ) : (
                <>
                  <Typography variant="button-md">Send</Typography>
                  <ArrowUpward fontSize="small" />
                </>
              )}
            </Box>
          </RetroButton>
          {/* Export */}
          <RetroButton
            variant="white"
            disabled={!isAllKeysLoaded}
            onClick={handleOpenExportKeyModal}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "4px",
                flex: "1",
              }}>
              {!isAllKeysLoaded ? (
                <>
                  <Typography variant="button-md">Loading</Typography>
                  <Box height="28px" />
                </>
              ) : (
                <>
                  <Typography variant="button-md">Export</Typography>
                  <PaidOutlined fontSize="small" />
                </>
              )}
            </Box>
          </RetroButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}>
          <RetroButton onClick={createWallet}>
            <Box
              sx={{
                padding: "12px 0px",
                textAlign: "center",
                width: "100%",
              }}>
              <Typography variant="title-h3">Create Wallet</Typography>
            </Box>
          </RetroButton>
        </Box>
      )}
      <ExportPrivateKeyModal
        keys={keys}
        isOpen={isOpennedExportKeyModal}
        close={handleCloseExportKeyModal}
      />
      <CrossChainDepositModal
        defaultChain="ton"
        chains={["ton", "ethereum", "solana", "base", "berachain"]}
        isOpen={isOpennedDepositModal}
        close={handleCloseDepositeModal}
        onCopySuccess={() => {
          handleCloseDepositeModal();
          showToast({
            variant: "success",
            message: "Copied address to clipboard",
            duration: 3000,
          });
        }}
      />
      <SendTokenDrawer
        data={fullAssetsData?.results ?? []}
        isOpen={isOpenSendDrawer}
        close={handleCloseSendDrawer}
      />
    </>
  );
};

const SendTokenDrawer = ({
  data,
  isOpen,
  close,
}: {
  data: any[];
  isOpen: boolean;
  close: () => void;
}) => {
  const [selectedTokenIndex, setSelectedTokenIndex] = useState(0);

  const [meme, setMemo] = useState("");
  const [address, setAddress] = useState("");
  const [isValidAddress, setIsValidAddress] = useState(false);

  const [amount, setAmount] = useState(0);
  const amountRef = useRef<HTMLInputElement>(null);

  const [isOpenSearchDrawer, setIsOpenSearchDrawer] = useState(false);
  const [isOpenConfirmDrawer, setIsOpenConfirmDrawer] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const sendingRef = useRef({ isSending: false });

  const { showToast } = useToastStore();

  useEffect(() => {
    if (amountRef.current) {
      amountRef.current?.focus();
      amountRef.current.value = "0";
      handleEnterAddress(address);
      setAmount(0);
      handleEnterMemo("");
    }
  }, [selectedTokenIndex]);

  const indexedData = useMemo(() => {
    return data
      .sort((a, b) => {
        return b.priceUsd - a.priceUsd;
      })
      .map((item, index) => {
        return {
          index,
          ...item,
        };
      });
  }, [data]);

  const theme = useTheme();

  const handleOpenSearchDrawer = () => {
    setIsOpenSearchDrawer(true);
  };

  const handleCloseSearchDrawer = () => {
    setIsOpenSearchDrawer(false);
  };

  const handleEnterAddress = (address: string) => {
    const chain = checkAddressChain(address);
    const selectedChain = ["berachain", "base", "ethereum"].includes(
      indexedData[selectedTokenIndex].chain,
    )
      ? "evm"
      : indexedData[selectedTokenIndex].chain;

    if (chain === selectedChain) {
      setIsValidAddress(true);
      setAddress(address);
    } else {
      setIsValidAddress(false);
      setAddress(address);
    }
  };

  const handleEnterMemo = (memo: string) => {
    setMemo(memo);
  };

  const handleMaxAmount = () => {
    if (amountRef.current) {
      amountRef.current.value = indexedData[selectedTokenIndex].balance;
      setAmount(indexedData[selectedTokenIndex].balance);
    }
  };

  const handleOpenConfirmDrawer = () => {
    setIsOpenConfirmDrawer(true);
  };

  const handleCloseConfirmDrawer = () => {
    setIsOpenConfirmDrawer(false);
  };

  const handleConfirm = async () => {
    if (sendingRef.current.isSending || !indexedData[selectedTokenIndex]) {
      return;
    }

    let res;
    try {
      sendingRef.current.isSending = true;
      setIsSending(true);
      if (NATIVE_TOKEN_LIST.includes(indexedData[selectedTokenIndex].symbol)) {
        res = await axiosService.sendNativeToken({
          receiverAddress: address,
          amount,
          chain: indexedData[selectedTokenIndex].chain,
          ...(meme && meme !== "" ? { memo: meme as string } : {}),
        });
      } else {
        res = await axiosService.sendMemeToken({
          receiverAddress: address,
          amount,
          chain: indexedData[selectedTokenIndex].chain,
          tokenAddress: indexedData[selectedTokenIndex].tokenAddress,
        });
      }

      if (res.status < 300) {
        showToast({
          variant: "success",
          message: `${res.message}`,
          duration: 5000,
        });
      } else {
        showToast({
          variant: "error",
          message: res.message,
          duration: 5000,
        });
      }
    } catch (error) {
      showToast({
        variant: "error",
        message: res ? res.message : error,
        duration: 5000,
      });
      console.error("Error while sending token", error);
    } finally {
      sendingRef.current.isSending = false;
      setIsSending(false);
      handleCloseConfirmDrawer();
      close();
    }
  };

  return (
    <>
      <StyledDrawer open={isOpen} anchor="bottom" onClose={() => {}} sx={{ zIndex: 200 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}>
          <Box sx={{ width: "100%", height: "100%", padding: "20px 20px 0" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
              }}>
              <CircleButton variant="white" onClick={close}>
                <Close fontSize="inherit" />
              </CircleButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                textAlign: "center",
                gap: "9px",
                marginY: "9px",
              }}>
              <SendIcon />
              <Typography variant="title-h1" color="text.primary">
                SEND TOKENS
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: "12px",
                flexDirection: "column",
              }}>
              <RetroCard>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "20px",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <Typography variant="body-default" color="text.secondary">
                      Balance:{" "}
                      {indexedData[selectedTokenIndex]?.balance ||
                      indexedData[selectedTokenIndex]?.balance === 0 ? (
                        <SmallNumberView value={indexedData[selectedTokenIndex]?.balance} />
                      ) : (
                        "$--"
                      )}
                    </Typography>
                    <Box sx={{ width: "fit-content" }}>
                      <RetroButton onClick={handleMaxAmount}>
                        <Box sx={{ padding: "4px 12px" }}>
                          <Typography variant="button-md">MAX</Typography>
                        </Box>
                      </RetroButton>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      backgroundColor: theme.palette.surface.silver,
                      borderRadius: theme.spacing(1.5),
                    }}>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                      onClick={handleOpenSearchDrawer}>
                      <StyledSelect
                        noPointer
                        value={selectedTokenIndex}
                        defaultValue={0}
                        sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
                        MenuProps={{
                          slotProps: {
                            paper: {
                              sx: {
                                borderRadius: theme.spacing(1.5),
                                backgroundColor: theme.palette.surface.silver,
                              },
                            },
                          },
                        }}
                        onChange={(event) => setSelectedTokenIndex(event.target.value as number)}
                        IconComponent={undefined}>
                        {indexedData.map((token) => (
                          <MenuItem
                            style={{ width: "100%" }}
                            selected={selectedTokenIndex === token.index}
                            value={token.index}>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: theme.spacing(1.5),
                                }}>
                                <CoinAvatar
                                  style={{
                                    width: "28px",
                                    height: "28px",
                                  }}
                                  badgeStyle={{
                                    width: "14px",
                                    height: "14px",
                                  }}
                                  iconUri={
                                    token.imageUrl ? token.imageUrl : NATIVE_TOKEN_MAP[token.symbol]
                                  }
                                  chain={token.chain}
                                  size="sm"
                                />
                                <Typography
                                  variant="body-default"
                                  color={theme.palette.text.secondary}>
                                  {token.symbol}
                                </Typography>
                              </Box>
                              {selectedTokenIndex === token.index ? null : (
                                <Typography
                                  variant="body-default"
                                  color={theme.palette.text.secondary}>
                                  <SmallNumberView value={token.balance} isUSD />
                                </Typography>
                              )}
                            </Box>
                          </MenuItem>
                        ))}
                      </StyledSelect>
                      <Divider
                        orientation="vertical"
                        variant="middle"
                        flexItem
                        sx={{
                          border: `0.5px solid ${theme.palette.surface["light-bg"]}`,
                        }}
                      />
                    </Box>
                    <StyledInputV2
                      type="number"
                      ref={amountRef}
                      defaultValue={0}
                      min={0}
                      max={indexedData[selectedTokenIndex]?.balance}
                      onChange={() => {
                        if (amountRef.current?.value !== null) {
                          if (amountRef.current) {
                            setAmount(parseFloat(amountRef.current.value));
                          }
                        }
                      }}
                    />
                  </Box>
                </Box>
              </RetroCard>
              <RetroCard>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "20px",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <Typography variant="body-default" color="text.secondary">
                      To
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}>
                    <StyledInputV2
                      style={{ textAlign: "start" }}
                      placeholder="Enter Address"
                      onChange={(event) => handleEnterAddress(event.target.value)}
                    />
                  </Box>
                  {indexedData &&
                  indexedData[selectedTokenIndex] &&
                  indexedData[selectedTokenIndex].chain === "ton" ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}>
                        <Typography variant="body-default" color="text.secondary">
                          Memo (Optional)
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}>
                        <StyledInputV2
                          style={{ textAlign: "start" }}
                          placeholder="Enter memo"
                          onChange={(event) => handleEnterMemo(event.target.value)}
                        />
                      </Box>
                    </>
                  ) : null}
                </Box>
              </RetroCard>
            </Box>
          </Box>
          <Box sx={{ margin: "12px 20px 20px", width: "100%", padding: "0 20px" }}>
            <RetroButton
              disabled={!isValidAddress || amount <= 0}
              onClick={handleOpenConfirmDrawer}>
              <Box
                sx={{
                  padding: "12px",
                }}>
                <Typography
                  variant="title-h3"
                  color={!isValidAddress || amount <= 0 ? "text.secondary" : "text.brand"}>
                  CONTINUE
                </Typography>
              </Box>
            </RetroButton>
          </Box>
        </Box>
      </StyledDrawer>

      {/* Search */}
      <DarkDrawer open={isOpenSearchDrawer} anchor="bottom" onClose={() => {}} sx={{ zIndex: 200 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "16px",
          }}>
          <Box sx={{ width: "28px" }} />
          <Typography variant="title-h2">SELECT TOKEN</Typography>
          <CircleButton variant="white" onClick={handleCloseSearchDrawer}>
            <Close fontSize="inherit" />
          </CircleButton>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}>
          {indexedData.map((token) => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                borderRadius: "16px",
                border: `1px solid ${theme.palette.border.dark}`,
                background: theme.palette.surface.silver,
                padding: "12px",
              }}
              onClick={() => {
                setSelectedTokenIndex(token.index);
                handleCloseSearchDrawer();
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "16px",
                }}>
                <CoinAvatar
                  style={{
                    width: "28px",
                    height: "28px",
                  }}
                  badgeStyle={{
                    width: "14px",
                    height: "14px",
                  }}
                  iconUri={token.imageUrl ? token.imageUrl : NATIVE_TOKEN_MAP[token.symbol]}
                  chain={token.chain}
                  size="sm"
                />
                <Typography variant="subtitle">{token.symbol}</Typography>
              </Box>
              <Typography variant="body-default">
                <SmallNumberView value={token.priceUsd} isUSD />
              </Typography>
            </Box>
          ))}
        </Box>
      </DarkDrawer>
      <DarkDrawer
        open={isOpenConfirmDrawer}
        anchor="bottom"
        onClose={() => {}}
        sx={{ zIndex: 200 }}>
        <Box>
          <Typography variant="title-h2">CONFIRM SENDING?</Typography>
        </Box>
        <Box
          sx={{
            marginTop: "16px",
            marginBottom: "32px",
            wordBreak: "break-all",
          }}>
          <Typography variant="body-md" color={theme.palette.text.secondary}>
            Sending <SmallNumberView value={amount} /> $
            {indexedData[selectedTokenIndex] ? indexedData[selectedTokenIndex].symbol : "N/A"} To{" "}
            {address}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
          }}>
          <RetroButton variant="white" disabled={isSending} onClick={handleCloseConfirmDrawer}>
            <Typography
              variant="title-h3"
              color={isSending ? "text.secondary" : "text.brand"}
              sx={{ paddingY: theme.spacing(1) }}>
              NO
            </Typography>
          </RetroButton>
          <RetroButton onClick={handleConfirm} disabled={isSending}>
            <Typography
              variant="title-h3"
              color={isSending ? "text.secondary" : "text.brand"}
              sx={{ paddingY: theme.spacing(1) }}>
              {isSending ? "SENDING..." : "YES"}
            </Typography>
          </RetroButton>
        </Box>
      </DarkDrawer>
    </>
  );
};
export { WalletDetail };
