import { Box, MenuItem, Typography, useTheme } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Select } from "components/Select";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { CabalListEmptyView, EmptyViewWithoutRetroCard } from "components/EmptyView";
import { SearchField } from "components/SearchField";
import { CabalGroupRowV2 } from "components/CabalGroup";
import { useDebounce } from "react-use";
import { ScrollableBox } from "components/ScrollableBox";
import { Jetton } from "types";
import { LoadMoreRow } from "components/LoadMoreRow";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { LoadingView } from "components/LoadingView";
import { OwnerImage } from "components/CabalGroup/styled";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { StyledSelect } from "./styled";
import { RetroButton } from "components/RetroButton";
import { Add } from "@mui/icons-material";
import { LoadingBlock, LoadingLottie } from "components/LoadingScreen";

const ExplorerPage = () => {
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();

  const groupSelectOptions = [
    { label: "All Groups", value: "all" },
    {
      label: "Created",
      value: "created",
    },
    {
      label: "Joined",
      value: "joined",
    },
  ];
  const languageSelectOptions = [
    { label: "All Languages", value: "all" },
    {
      label: "English",
      value: "en",
    },
    {
      label: "한국어",
      value: "ko",
    },
    {
      label: "中文",
      value: "zh",
    },
    {
      label: "русский",
      value: "ru",
    },
  ];
  const [selectedLanguage, setSelectedLanguage] = useState(groupSelectOptions[0].value);
  const [selectedGroupType, setSelectedGroupType] = useState(languageSelectOptions[0].value);
  const [name, setName] = useState("");
  const [page, setPage] = useState(1);

  const [debouncedName, setDebouncedName] = useState("");

  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  const { tgUserId, walletAddresses = {} } = useUserStoreV2();
  const { tonAddress } = walletAddresses;

  const queryResult = useInfiniteQuery({
    queryKey: [
      "cabal-calls",
      "cabalsExploration",
      { page, language: selectedLanguage, name: debouncedName, groupType: selectedGroupType },
    ],
    queryFn: async ({ pageParam }) => {
      return axiosService.getJettons({
        field: "cabalsExploration",
        language: selectedLanguage,
        groupType: selectedGroupType,
        selects: [
          "name",
          "tgGroupId",
          "imageUri",
          "language",
          "masterAddress",
          "createdAt",
          "symbol",
        ],
        limit: 10,
        name: debouncedName || undefined, // for empty string, remove the filter
        tgUserId,
        tonAddress,
        page: pageParam,
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },

    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  const { data, isLoading } = queryResult;

  const [, cancel] = useDebounce(
    () => {
      setDebouncedName(name);
    },
    500,
    [name],
  );

  useEffect(() => {
    if (!isShowHeader) {
      setIsShowHeader(true);
    }
  }, []);

  const navigateToCreateCabalPage = () => {
    navigate({
      pathname: "/create",
      search: `from=explorer`,
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px", height: "100%" }}>
      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(2.5),
            gap: theme.spacing(1),
            height: "100%",
            marginTop: "-6px",
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: theme.spacing(1),
            }}>
            <Typography variant="title-h3" color={theme.palette.text.primary}>
              EXPLORE ALPHA GROUPS
            </Typography>
            <Box
              sx={{
                width: "40px",
                height: "40px",
              }}>
              <RetroButton onClick={navigateToCreateCabalPage}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "12px",
                    padding: "12px",
                  }}>
                  <Add />
                </Box>
              </RetroButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center",
            }}>
            <StyledSelect
              defaultValue={groupSelectOptions[0].value}
              value={selectedGroupType}
              sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
              MenuProps={{
                slotProps: {
                  paper: {
                    sx: {
                      borderRadius: theme.spacing(1.5),
                      backgroundColor: theme.palette.surface.silver,
                    },
                  },
                },
              }}
              onChange={(event) => setSelectedGroupType(event.target.value as string)}
              IconComponent={undefined}>
              {groupSelectOptions.map((config) => (
                <MenuItem
                  selected={selectedGroupType === config.value}
                  value={config.value}
                  sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  <Typography
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                    variant="body-default"
                    color={theme.palette.text.secondary}>
                    {config.label}
                  </Typography>
                </MenuItem>
              ))}
            </StyledSelect>
            <StyledSelect
              defaultValue={languageSelectOptions[0].value}
              value={selectedLanguage}
              sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}
              MenuProps={{
                slotProps: {
                  paper: {
                    sx: {
                      borderRadius: theme.spacing(1.5),
                      backgroundColor: theme.palette.surface.silver,
                    },
                  },
                },
              }}
              onChange={(event) => setSelectedLanguage(event.target.value as string)}
              IconComponent={undefined}>
              {languageSelectOptions.map((config) => (
                <MenuItem
                  selected={selectedLanguage === config.value}
                  value={config.value}
                  sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  <Typography
                    sx={{
                      width: "100%",
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      gap: theme.spacing(1),
                    }}
                    variant="body-default"
                    color={theme.palette.text.secondary}>
                    {config.label}
                  </Typography>
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>
          {isLoading ? (
            <LoadingBlock isLoading={isLoading}>
              <Typography variant="title-h2">Loading...</Typography>
            </LoadingBlock>
          ) : !data || data.pages[0].totalResults === 0 ? (
            <EmptyViewWithoutRetroCard />
          ) : (
            <ScrollableBox>
              {data.pages
                .flatMap((page) => page.results)
                .map((jetton: Jetton) => (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                    key={jetton._id}>
                    <CabalGroupRowV2 jetton={jetton} />
                  </Box>
                ))}
              {!hasAllFetched && <LoadMoreRow ref={ref} />}
            </ScrollableBox>
          )}
        </Box>
      </RetroCard>
    </Box>
  );
};

const JoinedCabalsSection = ({ language }: { language: string }) => {
  const theme = useTheme();
  const navigate = useNavigatePreserveQuery();
  const { tgUserId, walletAddresses = {} } = useUserStoreV2();
  const { tonAddress } = walletAddresses;

  const { data: joinedCabalsData } = useQuery({
    queryKey: ["joined-cabal", language, tgUserId, tonAddress],
    queryFn: () =>
      axiosService.getJoinedCabal({
        tgUserId: tgUserId!,
        tonAddress: tonAddress!,
        language,
      }),
    enabled: !!tonAddress && !!tgUserId,
  });

  const navigateToCabalDetailPage = (tgGroupId: number) => {
    navigate(`/cabal/${tgGroupId}`);
  };

  return joinedCabalsData && joinedCabalsData.length ? (
    <Box sx={{ width: "100%", minHeight: "42px", overflowX: "scroll", overflowY: "hidden" }}>
      <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", gap: "12px" }}>
        {joinedCabalsData.map((jetton: Jetton) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "12px",
              padding: "6px 11.5px",
              height: "fit-content",
              borderRadius: "16px",
              border: `1px solid ${theme.palette.border.dark}`,
              backgroundColor: theme.palette.surface.silver,
              cursor: "pointer",
            }}
            onClick={() => navigateToCabalDetailPage(jetton.tgGroupId)}
            key={jetton._id}>
            <OwnerImage
              sx={{ width: "30px", height: "30px" }}
              src={jetton.imageUri}
              alt={jetton.name}
            />
            <Typography sx={{ display: "inline", whiteSpace: "nowrap" }} variant="button-lg">
              {jetton.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  ) : null;
};

export { ExplorerPage };
