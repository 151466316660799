import { Box, Typography } from "@mui/material";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as EmptyCallsIcon } from "../../assets/icons/emptyCalls.svg";
import { RetroCard } from "components/RetroCard";
import { ReactNode } from "react";
import { RetroButton } from "components/RetroButton";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "consts";

const EmptyView = ({
  title,
  message,
  actionButton,
  logo = <LogoIcon />,
}: {
  title: string | ReactNode;
  message: string | ReactNode;
  actionButton?: ReactNode;
  logo?: ReactNode;
}) => {
  return (
    <RetroCard>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          padding: "20px",
        }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100px",
          }}>
          {logo}
        </Box>
        <Box
          sx={{
            textAlign: "center",
            color: "#fff",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "20px",
          }}>
          <Typography variant="title-h2" mt={"6px"} paddingInline={"20px"}>
            {title}
          </Typography>
          <Typography variant="body-lg" marginBlock={"6px"}>
            {message}
          </Typography>
          {actionButton ?? null}
        </Box>
      </Box>
    </RetroCard>
  );
};

const CabalListEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="Nothing to see here!"
      message="Please come back later."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const JoinedTokenCallsEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title={`Get Exclusive Alpha here`}
      message="Once your groups drop token alpha, it will appear here — stay ahead."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
      logo={<EmptyCallsIcon />}
    />
  );
};

const TrendingTokenCallsEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="Nothing to see here!"
      message="Please come back later."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const GenericEmptyView = () => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <EmptyView
      title="Oops…"
      message="Something is wrong. Please come back later."
      actionButton={
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      }
    />
  );
};

const EmptyViewActionButton = ({
  onClick,
  children,
}: {
  onClick: () => void;
  children: string | ReactNode;
}) => {
  return (
    <RetroButton id="telegram-login" onClick={onClick}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: "12px",
          padding: "12px",
        }}>
        <Typography variant="title-h3">{children}</Typography>
      </Box>
    </RetroButton>
  );
};

const EmptyViewWithoutRetroCard = ({}) => {
  const navigate = useNavigate();
  const navigateToCabals = () => {
    navigate(ROUTES.explorer);
  };

  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "20px 0",
        overflow: "hidden",
      }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100px",
        }}>
        <LogoIcon />
      </Box>
      <Box
        sx={{
          textAlign: "center",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}>
        <Typography variant="title-h2">Nothing to see here!</Typography>
        <Typography variant="body-lg">Please come back later.</Typography>
        <EmptyViewActionButton onClick={navigateToCabals}>Explore Groups</EmptyViewActionButton>
      </Box>
    </Box>
  );
};

export {
  EmptyView,
  TrendingTokenCallsEmptyView,
  EmptyViewActionButton,
  JoinedTokenCallsEmptyView,
  CabalListEmptyView,
  GenericEmptyView,
  EmptyViewWithoutRetroCard,
};
