import { Box, Link, styled } from "@mui/material";

const TokenCallRowContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: "10px",
  border: `1px solid ${theme.palette.border.dark}`,
  background: theme.palette.surface.silver,
  borderRadius: theme.spacing(1.5),
  alignItems: "center",
  justifyContent: "space-between",
}));

const CalledByImage = styled("img")(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.border.light}`,
  objectFit: "cover",
  objectPosition: "center",
}));

const TrendingCalledByImage = styled("img")(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.border.dark}`,
  objectFit: "cover",
  objectPosition: "center",
  background: theme.palette.surface.container,
  justifyContent: "space-between",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  all: "unset",
  cursor: "pointer",
}));

export { TokenCallRowContainer, CalledByImage, TrendingCalledByImage };
