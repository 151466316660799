import { Box, styled, Typography, useTheme } from "@mui/material";
import { Container } from "components/container";
import { RetroButton } from "components/RetroButton";
import { RetroCard } from "components/RetroCard";
import { ROUTES } from "consts";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { ReactComponent as AddIcon } from "assets/icons/add.svg";
import { ReactComponent as DirectIcon } from "assets/icons/direct.svg";
import { ReactComponent as StarIcon } from "assets/icons/star.svg";
import { ReactComponent as TradeIcon } from "assets/icons/trade.svg";
import { ReactComponent as SwapIcon } from "assets/icons/swap.svg";
import { useLocation } from "react-router-dom";

const ButtonBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& svg": {
    width: "24px",
    height: "24px",
  },
});

const footerLogos = [
  {
    route: ROUTES.explorer,
    icon: <StarIcon />,
    label: "Groups",
  },
  {
    route: ROUTES.call,
    icon: <TradeIcon />,
    label: "Alpha",
  },
  {
    route: ROUTES.trade,
    icon: <SwapIcon />,
    label: "Trade",
  },
  {
    route: ROUTES.portfolio,
    icon: <DirectIcon />,
    label: "Portfolio",
  },
];
export const Footer = () => {
  const navigate = useNavigatePreserveQuery();
  const location = useLocation();
  const theme = useTheme();

  const handleClick = (route: string) => {
    navigate(route);
  };

  return (
    <Container sx={{ marginY: theme.spacing(1) }}>
      <RetroCard size="sm">
        <Box
          sx={{
            width: "100%",
            padding: theme.spacing(0.5, 1),
            display: "flex",
            gap: "10px",
            height: "66px",
          }}>
          {footerLogos.map(({ route, icon, label }) => {
            return (
              <RetroButton
                key={route}
                id={route}
                onClick={() => {
                  handleClick(route);
                }}
                disabled={location.pathname === route}>
                <ButtonBox>
                  {icon}
                  <Typography
                    variant="subtitle"
                    sx={{
                      fontSize: "10px",
                      lineHeight: "9px",
                      marginTop: theme.spacing(0.5),
                    }}>
                    {label}
                  </Typography>
                </ButtonBox>
              </RetroButton>
            );
          })}
        </Box>
      </RetroCard>
    </Container>
  );
};
