import { ArrowDropUp, Remove } from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { Brick } from "components/Brick";

const numberFormatter = new Intl.NumberFormat("en-US", {
  maximumFractionDigits: 1,
  maximumSignificantDigits: 4,
  useGrouping: false,
});

const MIN_PERCENTAGE_TO_SHOW = 0.001;

const PercentageBrick = ({ percentage }: { percentage: number }) => {
  const theme = useTheme();

  const averageToFormat =
    percentage >= MIN_PERCENTAGE_TO_SHOW || percentage <= -MIN_PERCENTAGE_TO_SHOW ? percentage : 0;

  return (
    <Brick variant={averageToFormat === 0 ? "dark" : averageToFormat > 0 ? "green" : "red"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          position: "relative",
          minWidth: "52px",
        }}>
        {averageToFormat !== 0 ? (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: percentage < 0 ? "rotate(180deg)" : "none",
            }}
          />
        ) : (
          <Remove
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              width: "12px",
              marginLeft: "4px",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {numberFormatter.format(+Math.abs(averageToFormat * 100).toFixed(1)) == "0"
            ? "0.0"
            : (+numberFormatter.format(+Math.abs(averageToFormat * 100).toFixed(1))).toFixed(1)}
          %
        </Typography>
      </Box>
    </Brick>
  );
};

const PricePerformanceBrick = ({ performance }: { performance: number }) => {
  const theme = useTheme();

  const averageToFormat =
    performance >= MIN_PERCENTAGE_TO_SHOW || performance <= -MIN_PERCENTAGE_TO_SHOW
      ? performance
      : 0;

  return (
    <Brick variant={averageToFormat === 0 ? "dark" : averageToFormat > 0 ? "green" : "red"}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          position: "relative",
          minWidth: "52px",
        }}>
        {averageToFormat !== 0 ? (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: performance < 0 ? "rotate(180deg)" : "none",
            }}
          />
        ) : (
          <Remove
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              width: "12px",
              marginLeft: "4px",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {numberFormatter.format(Number(performance.toFixed(2))) == "0"
            ? "$0.00"
            : `$${(+numberFormatter.format(Number(performance.toFixed(2)))).toFixed(2)}`.replace(
                "$-",
                "$",
              )}
        </Typography>
      </Box>
    </Brick>
  );
};

const PercentageBlock = ({ percentage }: { percentage: number }) => {
  const theme = useTheme();

  const averageToFormat =
    percentage >= MIN_PERCENTAGE_TO_SHOW || percentage <= -MIN_PERCENTAGE_TO_SHOW ? percentage : 0;

  return (
    <Box
      padding="2px 4px"
      borderRadius="8px"
      border={`1px solid ${theme.palette.border.dark}`}
      bgcolor={
        averageToFormat === 0
          ? theme.palette.surface["silver"]
          : averageToFormat > 0
          ? theme.palette.system["green"]
          : theme.palette.system["red"]
      }>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          position: "relative",
          minWidth: "52px",
        }}>
        {averageToFormat !== 0 ? (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: percentage < 0 ? "rotate(180deg)" : "none",
            }}
          />
        ) : (
          <Remove
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              width: "12px",
              marginLeft: "4px",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {numberFormatter.format(+Math.abs(averageToFormat * 100).toFixed(1)) == "0"
            ? "0.0"
            : (+numberFormatter.format(+Math.abs(averageToFormat * 100).toFixed(1))).toFixed(1)}
          %
        </Typography>
      </Box>
    </Box>
  );
};

const PricePerformanceBlock = ({ performance }: { performance: number }) => {
  const theme = useTheme();

  const averageToFormat =
    performance >= MIN_PERCENTAGE_TO_SHOW || performance <= -MIN_PERCENTAGE_TO_SHOW
      ? performance
      : 0;

  return (
    <Box
      padding="4px"
      borderRadius="8px"
      border={`1px solid ${theme.palette.border.dark}`}
      bgcolor={
        averageToFormat === 0
          ? theme.palette.surface["silver"]
          : averageToFormat > 0
          ? theme.palette.system["green"]
          : theme.palette.system["red"]
      }>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          position: "relative",
          minWidth: "52px",
        }}>
        {averageToFormat !== 0 ? (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: performance < 0 ? "rotate(180deg)" : "none",
            }}
          />
        ) : (
          <Remove
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              width: "12px",
              marginLeft: "4px",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {numberFormatter.format(Number(performance.toFixed(2))) == "0"
            ? "$0.00"
            : `$${(+numberFormatter.format(Number(performance.toFixed(2)))).toFixed(2)}`.replace(
                "$-",
                "$",
              )}
        </Typography>
      </Box>
    </Box>
  );
};

const PricePerformanceWithPercentagBlock = ({
  performance,
  percentage,
}: {
  performance: number;
  percentage: number;
}) => {
  const theme = useTheme();

  const averageToFormat =
    performance >= MIN_PERCENTAGE_TO_SHOW || performance <= -MIN_PERCENTAGE_TO_SHOW
      ? performance
      : 0;

  const averageToFormatInPercentage =
    percentage >= MIN_PERCENTAGE_TO_SHOW || percentage <= -MIN_PERCENTAGE_TO_SHOW ? percentage : 0;

  return (
    <Box
      padding="4px"
      borderRadius="8px"
      border={`1px solid ${theme.palette.border.dark}`}
      bgcolor={
        averageToFormat === 0
          ? theme.palette.surface["silver"]
          : averageToFormat > 0
          ? theme.palette.system["green"]
          : theme.palette.system["red"]
      }>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          position: "relative",
          minWidth: "52px",
        }}>
        {averageToFormat !== 0 ? (
          <ArrowDropUp
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              transform: performance < 0 ? "rotate(180deg)" : "none",
            }}
          />
        ) : (
          <Remove
            sx={{
              position: "absolute",
              left: theme.spacing(-0.75),
              width: "12px",
              marginLeft: "4px",
            }}
          />
        )}
        <Typography variant="body-sm" marginLeft={averageToFormat !== 0 ? theme.spacing(2) : 0}>
          {numberFormatter.format(Number(performance.toFixed(2))) == "0"
            ? "$0.00"
            : `$${(+numberFormatter.format(Number(performance.toFixed(2)))).toFixed(2)}`.replace(
                "$-",
                "$",
              )}{" "}
          (
          {numberFormatter.format(+Math.abs(averageToFormatInPercentage * 100).toFixed(1)) == "0"
            ? "0.0"
            : (+numberFormatter.format(
                +Math.abs(averageToFormatInPercentage * 100).toFixed(1),
              )).toFixed(1)}
          %)
        </Typography>
      </Box>
    </Box>
  );
};
export {
  PercentageBrick,
  PricePerformanceBrick,
  PercentageBlock,
  PricePerformanceBlock,
  PricePerformanceWithPercentagBlock,
};
