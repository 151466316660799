import { Box, Typography, useTheme } from "@mui/material";
import { JoinedTokenCallsEmptyView } from "components/EmptyView";
import { RetroCard } from "components/RetroCard";
import { ScrollableBox } from "components/ScrollableBox";
import { useEffect, useState } from "react";
import { TokenCallRow } from "components/TokenCall";
import { DefaultLoginBlockerContent, LoginBlockerWrapper } from "components/LoginBlocker";
import { useInfiniteQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { useInViewInfiniteQuery } from "hooks/useInViewInfiniteQuery";
import { LoadMoreRow } from "components/LoadMoreRow";
import useLayoutConfigStore from "store/layout-config-store/useLayoutConfigStore";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { LoadingBlock } from "components/LoadingScreen";

const UnauthorizedView = ({ login }: { login: () => void }) => {
  return (
    <Box
      sx={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        padding: "20px",
      }}>
      <LogoIcon />
      <Box
        sx={{
          textAlign: "center",
          color: "#fff",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "24px",
        }}>
        <DefaultLoginBlockerContent login={login} />
      </Box>
    </Box>
  );
};

const JoinedTokenCalls = ({ data, isLoadingJoinedCabalCalls, ref, hasAllFetched }: any) => {
  const { tgUserId, tgUserName, getTgUserIdV2 } = useUserStoreV2();

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        await getTgUserIdV2();
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  if (isLoadingJoinedCabalCalls) {
    return (
      <LoadingBlock isLoading={isLoadingJoinedCabalCalls}>
        <Typography variant="title-h2">Loading...</Typography>
      </LoadingBlock>
    );
  }

  if (!tgUserId || !tgUserName) {
    return <UnauthorizedView login={handleLogin} />;
  }

  // If there are token calls
  return (
    <LoginBlockerWrapper isLoginedIn={!!tgUserId && !!tgUserName} fallback={<></>}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}>
        {/* <TokenCallHeader /> */}
        <ScrollableBox>
          {data.pages
            .flatMap((page: any) => page.results)
            .map((tokenCall: any) => (
              <Box key={tokenCall._id}>
                <TokenCallRow tokenCall={tokenCall} />
              </Box>
            ))}
          {!hasAllFetched && <LoadMoreRow ref={ref} />}
        </ScrollableBox>
      </Box>
    </LoginBlockerWrapper>
  );
};

const CallPage = () => {
  const theme = useTheme();
  const { isShowHeader, setIsShowHeader } = useLayoutConfigStore();

  const [page, setPage] = useState(1);

  const queryResult = useInfiniteQuery({
    queryKey: ["aplha-calls", { page }],
    queryFn: async ({ pageParam }) => {
      return axiosService.getJoinedCabalCalls({
        limit: 10,
        page: pageParam,
        field: "joinedCabals",
        selects: [
          "name",
          "symbol",
          "iconUri",
          "confidenceInterval",
          "createdAt",
          "updatedAt",
          "userId",
          "groupId",
          "chain",
          "jetton",
          "address",
        ],
      });
    },
    getNextPageParam: (lastPage, pages) => {
      return lastPage.page + 1;
    },
    getPreviousPageParam: (firstPage, pages) => {
      return firstPage.page - 1;
    },
    initialPageParam: 1,
  });

  const { data, isLoading: isLoadingJoinedCabalCalls } = queryResult;
  const { ref, hasAllFetched } = useInViewInfiniteQuery(queryResult);

  // const [tab, setTab] = useState<"trending" | "joined-cabal">("trending");

  useEffect(() => {
    if (!isShowHeader) {
      setIsShowHeader(true);
    }
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1), height: "100%" }}>
        {!isLoadingJoinedCabalCalls &&
        (!data || !data.pages[0].results || data.pages[0].results.length === 0) ? (
          <JoinedTokenCallsEmptyView />
        ) : (
          <RetroCard>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "20px 20px 66px",
                height: "100%",
              }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}>
                <Typography variant="title-h3" color={theme.palette.text.primary}>
                  ALPHA FROM YOUR GROUPS
                </Typography>
              </Box>
              <Box minHeight="12px" />
              {/* <Box
              sx={{
                background: theme.palette.surface.container,
                borderRadius: "12px",
                border: `1px solid ${theme.palette.border.dark}`,
                height: "fit-content",
                padding: "2px 4px",
                marginBottom: "8px",
              }}>
              <Box
                sx={{
                  display: "flex",
                  gap: theme.spacing(1),
                  width: "100%",
                }}>
                <RetroButton onClick={() => setTab("trending")} disabled={tab === "trending"}>
                  <Box
                    sx={{
                      padding: "4px",
                    }}>
                    <Typography variant="button-md">All Groups</Typography>
                  </Box>
                </RetroButton>
                <RetroButton
                  onClick={() => setTab("joined-cabal")}
                  disabled={tab === "joined-cabal"}>
                  <Box
                    sx={{
                      padding: "4px",
                    }}>
                    <Typography variant="button-md">Your Groups</Typography>
                  </Box>
                </RetroButton>
              </Box>
            </Box> */}
              <JoinedTokenCalls
                data={data}
                isLoadingJoinedCabalCalls={isLoadingJoinedCabalCalls}
                ref={ref}
                hasAllFetched={hasAllFetched}
              />
              {/* {tab === "trending" && <TrendingTokenCalls />} */}
            </Box>
          </RetroCard>
        )}
      </Box>
    </>
  );
};

export { CallPage };
