import { Avatar, Box, IconButton, MenuItem, Select, Theme, Typography } from "@mui/material";
import { styled } from "@mui/system";

export const SpaceBetween = styled(Box)(({ theme }) => ({
  height: "48px",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const Label = styled(Typography)(({ theme }) => ({
  fontFamily: "Cabin Condensed",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "20px",
  letterSpacing: "0.08em",
  color: "#FFB800",
}));

export const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 0,
});

// V2
const TYPOGRAPHY_BODY_MD = (theme: Theme) => ({
  ...theme.typography["body-md"],
});

const CURVE_TYPE = {
  casual: {
    top: "49px",
    borderRadius: "100% 0% 100% 0% / 30% 100% 0% 0% ",
  },
  standard: {
    top: "19px",
    borderRadius: "100% 0% 100% 0% / 40% 100% 0% 40% ",
  },
  exclusive: {
    top: "0px",
    borderRadius: "100% 0% 100% 0% / 50% 100% 0% 60% ",
  },
};

{
  /* Upload */
}
export const UploadImageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "20px",
}));

{
  /* - Image Icon */
}
export const CabalIcon = styled(Avatar)(({ theme }) => ({
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  backgroundColor: theme.palette.surface.silver,
}));

{
  /* - Upload Button */
}
export const UploadButtonWrapper = styled(Box)(({ theme }) => ({
  width: "133px",
  height: "53px",
}));

{
  /* Name */
}
{
  /* - Input field */
}
{
  /* Token ticker */
}
{
  /* - Token ticker */
}
export const StyledLabel = styled("label")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
}));

export const StyledInput = styled("input")(({ theme }) => ({
  width: "100%",
  height: "46px",
  border: `1.5px solid ${theme.palette.border.light}`,
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "8px",
  padding: "0 12px",
  opacity: "0.5",
  color: theme.palette.text.primary,
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
  "&:focus, &:not(:placeholder-shown)": {
    opacity: "1",
    outline: "none",
  },
  "&::placeholder": {
    color: theme.palette.text.primary,
  },
}));

{
  /* Language */
}
{
  /* - Selects */
}
export const StyledSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: "46px",
  border: `1.5px solid ${theme.palette.border.light}`,
  backgroundColor: theme.palette.surface.silver,
  borderRadius: "8px",
  outline: "none",
  color: theme.palette.text.primary,
  "& .MuiSelect-icon": {
    color: "white", // Change the color here
  },
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
}));

export const StyledOption = styled(MenuItem)(({ theme }) => ({
  width: "100%",
  height: "46px",
  color: theme.palette.text["btn-dark"],
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
  "&:hover": {
    backgroundColor: theme.palette.surface["light-bg"],
  },
}));

{
  /* Curve steepness */
}
export const CurveSteepnessHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ViewButtonWrapper = styled(Box)(({ theme }) => ({
  width: "103px",
  height: "46px",
}));

export const CurveSteepnessOptionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
}));

{
  /* - Casual */
}
{
  /* - Standard */
}
{
  /* - Exclusive */
}
export const CurveSteepnessOption = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  aspectRatio: "1",
  width: "min(110px, 25vw)",
  pointerEvents: "none",
  border: `2px solid ${theme.palette.border.dark}AA`,
  borderRadius: "8px",
  overflow: "hidden",
  background: theme.palette.surface.silver,
  "&:focus": {
    outline: "none",
  },
  "&:focus-visible": {
    "box-shadow": "0 0 5px rgba(0, 123, 255, 0.5)",
  },
}));

export const Curve = styled(Box)<{ $variant: "casual" | "standard" | "exclusive" }>(
  ({ theme, $variant }) => ({
    position: "absolute",
    width: "110px",
    height: "110px",
    border: `3px solid ${theme.palette.text.secondary}`,
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    ...CURVE_TYPE[$variant],
    background: "linear-gradient(180deg, #cccccccc 0%, #595E65 100%)",
    "&:hover": {
      background: "linear-gradient(180deg, #FFC949 0%, #595E65 100%)",
    },
  }),
);

export const CurveSteepnessOptionText = styled(Typography)(({ theme }) => ({
  ...TYPOGRAPHY_BODY_MD(theme as Theme),
  color: theme.palette.text.secondary,
  pointerEvents: "none",
  position: "absolute",
  bottom: "12px",
  left: "50%",
  zIndex: 1,
  transform: "translateX(-50%)",
}));

export const CurveSteepnessOptionRadio = styled(IconButton)<{ $selected: Boolean }>(
  ({ theme, $selected }) => ({
    borderRadius: "50%",
    background: $selected ? theme.palette.brand.primary : "none",
    border: $selected
      ? `2px solid ${theme.palette.border.dark}`
      : `1.5px solid ${theme.palette.border.light}`,
    height: "24px",
    width: "24px",
    "&:focus": {
      outline: "none",
      background: $selected ? theme.palette.brand.primary : "none",
      border: $selected
        ? `2px solid ${theme.palette.border.dark}`
        : `1.5px solid ${theme.palette.border.light}`,
    },
  }),
);

export const CurveSteepnessOptionRadioContainer = styled(Box)<{ $selected: Boolean }>(
  ({ theme, $selected }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
    "& > button": $selected
      ? {
          background: theme.palette.brand.primary,
          border: `2px solid ${theme.palette.border.dark}`,
        }
      : {},
    "& > div": $selected
      ? {
          background: theme.palette.surface.silver,
          border: `2px solid ${theme.palette.border.dark}`,
          "& > div": {
            background: "linear-gradient(180deg, #FFC949 0%, #595E65 100%)",
          },
          "& > span": {
            color: theme.palette.text.primary,
          },
        }
      : {},
    "&:hover": {
      "& > div": {
        background: theme.palette.surface.silver,
        border: `2px solid ${theme.palette.border.dark}`,
        "& > div": {
          background: "linear-gradient(180deg, #FFC949 0%, #595E65 100%)",
        },
        "& > span": {
          color: theme.palette.text.primary,
        },
      },
    },
  }),
);

{
  /* Create Cabal */
}

export const CreateCabalButtonWrapper = styled(Box)<{ $valid: Boolean }>(({ theme, $valid }) => ({
  position: "fixed",
  bottom: "0",
  left: "50%",
  zIndex: 2,
  padding: "0 16px",
  marginBottom: "8px",
  height: "60px",
  transform: "translateX(-50%)",
  pointerEvents: $valid ? "auto" : "none",
  width: "100%",
}));

// Redeem Code View
export const HeaderLogo = styled("img")({
  width: "189px",
  height: "36px",
});
