import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { PaginatedData } from "types";

export const useInViewInfiniteQuery = <T extends PaginatedData<E>, E>({
  data,
  fetchNextPage,
}: UseInfiniteQueryResult<InfiniteData<T, unknown>, Error>) => {
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  const hasAllFetched =
    !!data?.pages?.length &&
    (data?.pages[data.pages?.length - 1].results?.length === 0 ||
      data?.pages[data.pages?.length - 1].page === data?.pages[0].totalPages);

  return {
    ref,
    inView,
    hasAllFetched,
  };
};
