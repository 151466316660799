import theme from "theme";
import { Container } from "components/container";
import {
  BondingCurveChartWrapper,
  BondingCurveTooltipTrigger,
  JettonImage,
  PerformanceWrapper,
} from "./styled";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { RetroCard } from "components/RetroCard";
import { useNavigate, useParams } from "react-router-dom";
import { RetroButton } from "components/RetroButton";
import { useMutation, useQuery } from "@tanstack/react-query";
import axiosService from "services/axios";
import { CabalListEmptyView } from "components/EmptyView";
import { abbreviateNumber, copyToClipboard, LANGUAGES, toFixedIfNecessary } from "utils";
import { ReactComponent as HelpIcon } from "assets/icons/warning/circle_help.svg";
import { PercentageBrick } from "components/PercentageBrick";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as BackArrow } from "assets/icons/arrow-left.svg";
import { JettonChart } from "components/Chart/JettonChart";
import {
  startOfHour,
  startOfDay,
  startOfMinute,
  startOfWeek,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMinuteOfInterval,
  eachHourOfInterval,
} from "date-fns";
import { BondingCurveType, CabalDetails, JettonPrice } from "types";
import useUserStoreV2 from "store/user-store-v2/useUserStoreV2";
import { CHAIN_CHECK_LIST, MIN_ENTRY_AMOUNT, ROUTES } from "consts";
import { JettonTradeDrawer } from "components/CabalTradeDrawer";

import { useWsPriceData } from "./hooks";
import { Brick } from "components/Brick";

import { useOnClickOutside } from "usehooks-ts";
import FullPageWrapper from "components/FullPageWrapper";
import useToastStore from "store/toast-store/useToastStore";
import { PreviewChart } from "components/Chart/PreviewChart";
import casualData from "components/CreateCabal/data/casual.json";
import standardData from "components/CreateCabal/data/standard.json";
import exclusiveData from "components/CreateCabal/data/exclusive.json";
import _groupBy from "lodash/groupBy";

import Lottie from "lottie-react";
import loadingAnimation from "../../assets/lottie/loader-cabal_town.json";
import { ContentCard, InnerContentBox } from "components/ContentCard";

import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import FeaturedTokenCall from "./FeaturedTokenCall";
import { CrossChainDepositModal } from "components/Deposit";
import { Steepness } from "components/CreateCabal/CreateCabalV2";
import { Cached, Share } from "@mui/icons-material";
import { NATIVE_TOKEN_ADDRESS_MAP, TradeProvider, useTradeContext } from "contexts/TradeContext";
import useTradeStore from "store/trade-store.ts/useTradeStore";
import { useTooltips } from "hooks/useTooltips";
import { BuyJettonReminderModal } from "./BuyJettonReminderModal";
import { DefinitionTooltip } from "components/Tooltip";
import { TooltipTrigger } from "components/Tooltip/styled";
import { LoadingBlock, LoadingView } from "components/LoadingScreen";
import { checkIsMobile, getTgShareLinkWithParams } from "lib/utils";

// Price Chart
const groupingFunction = {
  minute: startOfMinute,
  hour: startOfHour,
  day: startOfDay,
  week: startOfWeek,
};

const intervalFunction = {
  minute: eachMinuteOfInterval,
  hour: eachHourOfInterval,
  day: eachDayOfInterval,
  week: eachWeekOfInterval,
};

const groupAndFillJettonPrice = ({
  data,
  interval,
  withoutFirstData,
}: {
  data: JettonPrice[];
  interval: "minute" | "hour" | "day" | "week";
  withoutFirstData: boolean;
}) => {
  const groupedData = _groupBy(data, (d) => {
    return groupingFunction[interval](new Date(d.updatedAt)).toISOString();
  });

  const wholeInterval = intervalFunction[interval]({
    start: new Date(data[0].updatedAt),
    end: new Date(data[data.length - 1].updatedAt),
  });

  const result: JettonPrice[] = [];

  wholeInterval.forEach((d) => {
    const key = startOfMinute(d).toISOString();
    if (groupedData[key]) {
      result.push(Object.values(groupedData[key])[0]);
    } else {
      result.push({
        updatedAt: startOfMinute(d).toISOString(),
        price: result[result.length - 1].price,
      });
    }
  });
  if (withoutFirstData) {
    result.shift();
  }
  return result;
};

const bondingCurveDescriptions: Record<BondingCurveType, string> = {
  casual: "Perfect for large groups, offering steady prices to encourage broad participation.",
  standard: "Ideal for medium-sized groups, striking a balance in price changes.",
  exclusive: "Made for tight-knit, active communities with dynamic token pricing.",
};

const MaxSliderSteps: Record<Steepness, number> = {
  Casual: 2000,
  Standard: 300,
  Exclusive: 100,
};

const initialData = {
  casual: casualData,
  standard: standardData.slice(0, MaxSliderSteps["Standard"]),
  exclusive: exclusiveData.slice(0, MaxSliderSteps["Exclusive"]),
};

const BondingCurveTooltip = ({ type }: { type: BondingCurveType }) => {
  const theme = useTheme();
  return (
    <Box>
      <Box marginTop={1}>
        <Typography variant="body-sm" color={"text.secondary"}>
          {bondingCurveDescriptions[type]}
        </Typography>
      </Box>
      <Box marginTop={1} display={"flex"} justifyContent={"center"}>
        <BondingCurveChartWrapper>
          <PreviewChart data={initialData[type]} />
        </BondingCurveChartWrapper>
      </Box>
    </Box>
  );
};

const transformPriceData = (data: JettonPrice) => {
  return {
    time: new Date(data.updatedAt).getTime(),
    value: data.price,
  };
};

const CabalDetailsRenderer = ({ data }: { data: CabalDetails }) => {
  const [timeInterval, setTimeInterval] = useState<"minute" | "hour" | "day" | "week">("hour");

  const [shouldTradeDrawerOpen, setShouldTradeDrawerOpen] = useState(false);

  const [priceDataConsolidated, setPriceDataConsolidated] = useState<JettonPrice[]>([]);

  const [isJoiningGroup, setIsJoiningGroup] = useState(false);

  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  const [minimumEntryInUsd, setMinimumEntryInUsd] = useState<number | undefined>(undefined);

  const [isCreatingWallet, setIsCreatingWallet] = useState(false);

  const { tgUserName, tgUserId, walletAddresses, hasWallet, getTgUserIdV2, createWallet } =
    useUserStoreV2();

  const { tonAddress } = walletAddresses || {};

  const { showToast } = useToastStore();

  const {
    isTooltipOpen: isBondingCurveTooltipOpen,
    setIsTooltipOpen: setIsBondingCurveTooltipOpen,
    tooltipRef: bondingCurveTooltipRef,
  } = useTooltips();

  const {
    isTooltipOpen: isWinRateTooltipOpen,
    setIsTooltipOpen: setIsWinRateTooltipOpen,
    tooltipRef: winRateTooltipRef,
  } = useTooltips();

  const {
    isTooltipOpen: isPerformanceTooltipOpen,
    setIsTooltipOpen: setIsPerformanceTooltipOpen,
    tooltipRef: performanceTooltipRef,
  } = useTooltips();

  const {
    isTooltipOpen: isMemberTooltipOpen,
    setIsTooltipOpen: setIsMemberTooltipOpen,
    tooltipRef: memberTooltipRef,
  } = useTooltips();

  const {
    isTooltipOpen: isBestPerformanceTooltipOpen,
    setIsTooltipOpen: setIsBestPerformanceTooltipOpen,
    tooltipRef: bestPerformanceTooltipRef,
  } = useTooltips();

  const {
    isTooltipOpen: isEntryPriceTooltipOpen,
    setIsTooltipOpen: setIsEntryPriceTooltipOpen,
    tooltipRef: entryPriceTooltipRef,
  } = useTooltips();

  const isLoggedIn = !!tgUserId || !!tgUserName;

  const {
    isTradeInitingRef,
    isTradeIniting,
    isTrading,
    isTargetTokenBalanceLoading,
    isTargetTokenBalanceRefetching,
    refetchTargetTokenBalance,
    targetBalanceUsd,
    targetBalanceAmount,
    targetBalancePerformance,
    jettonBuyRatio,
    isNativeTokenPriceLoading,
    refetchPriceRatio,
    isPolling,
    isTonBoardcasting,
    txnHash,
  } = useTradeContext();

  const { updateUserBalance } = useTradeStore();

  const { data: rawPriceData, isLoading: isPriceDataLoading } = useQuery({
    queryKey: ["jetton-price-data", { masterAddress: data?.masterAddress, timeInterval }],
    queryFn: () =>
      axiosService.getJettonPriceV1({
        masterAddress: data?.masterAddress!,
        interval: timeInterval,
      }),
    enabled: !!data?.masterAddress,
  });

  const priceData = useMemo(() => {
    if (!rawPriceData) return rawPriceData;

    const _priceDataResult = rawPriceData?.results;
    rawPriceData.results = _priceDataResult?.filter((item) => item.price !== null);

    return rawPriceData;
  }, [rawPriceData]);

  useEffect(() => {
    if (priceData?.results && priceData.results.length > 0) {
      setPriceDataConsolidated(
        groupAndFillJettonPrice({
          data: priceData.results,
          interval: timeInterval,
          withoutFirstData: true,
        }),
      );
    }
  }, [priceData, timeInterval, setPriceDataConsolidated]);

  useEffect(() => {
    if (jettonBuyRatio) {
      setMinimumEntryInUsd(jettonBuyRatio * MIN_ENTRY_AMOUNT);
    }
  }, [jettonBuyRatio]);

  const handleLogin = async () => {
    try {
      if (!tgUserId || !tgUserName) {
        await getTgUserIdV2();
      }
    } catch (error) {
      console.error("Error while logging in", error);
    }
  };

  const { mutateAsync: joinGroupAsync } = useMutation({
    mutationFn: () =>
      axiosService.joinGroupV1({
        tokenAddress: data?.masterAddress!,
        walletAddress: tonAddress!,
        tgUserName: tgUserName!,
        txnHash,
      }),
  });

  const handleJoinGroup = async () => {
    setIsJoiningGroup(true);
    try {
      const res = await joinGroupAsync();
      if (res.status === 200) {
        showToast({ variant: "success", message: res.message, duration: 3000 });
        if (res.link) {
          if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(res.link);
          } else {
            window.open(res.link, "_blank");
          }
        }
      }
      if (res.status >= 400) {
        showToast({ variant: "error", message: res.message, duration: 3000 });
      }
    } catch (error) {
      showToast({ variant: "error", message: "Something went wrong", duration: 3000 });
    } finally {
      setIsJoiningGroup(false);
    }
  };

  const handleOnPriceData = useCallback(
    (priceData: JettonPrice) => {
      if (priceData.price === null) {
        return;
      }
      setPriceDataConsolidated((prev) => [
        ...prev,
        ...(prev.length > 0
          ? groupAndFillJettonPrice({
              data: [prev[prev.length - 1], priceData],
              interval: timeInterval,
              withoutFirstData: true,
            })
          : [priceData]),
      ]);
      if (minimumEntryInUsd !== undefined) {
        setMinimumEntryInUsd(priceData.price * MIN_ENTRY_AMOUNT);
      }
    },
    [minimumEntryInUsd, timeInterval],
  );

  useWsPriceData({
    masterAddress: data?.masterAddress!,
    onPriceData: handleOnPriceData,
  });

  const handleTradeClick = async () => {
    try {
      if (isTradeInitingRef.current || isTradeIniting) {
        return;
      }

      isTradeInitingRef.current = true;

      if (!hasWallet) {
        setIsCreatingWallet(true);
        await createWallet();
        setIsCreatingWallet(false);
      }

      setShouldTradeDrawerOpen(true);
      const { amount } = (await updateUserBalance()) ?? {};

      const isAllBalanceEmpty =
        amount &&
        CHAIN_CHECK_LIST.every(
          (key) => !amount[key as keyof typeof amount] || amount[key as keyof typeof amount] <= 0,
        );

      if (!hasWallet || isAllBalanceEmpty) {
        setShouldTradeDrawerOpen(false);
        setIsDepositModalOpen(true);
      } else {
        refetchPriceRatio();

        refetchTargetTokenBalance();
      }
    } catch (error) {
      console.error("Error while initing trade drawer", error);
    } finally {
      if (isCreatingWallet) {
        setIsCreatingWallet(false);
      }
      isTradeInitingRef.current = false;
    }
  };

  const {
    day1Performance,
    day7Performance,
    totalWins,
    totalCalls,
    membersCount,
    imageUri,
    name,
    language,
    bondingCurveType,
  } = data;

  useEffect(() => {
    refetchTargetTokenBalance();
  }, [refetchTargetTokenBalance]);

  return (
    <>
      <RetroCard>
        <Box
          sx={{
            padding: theme.spacing(2.5),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(2) }}>
              <JettonImage src={imageUri} alt={name} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="title-h3">{name}</Typography>
                <Box display={"flex"}>
                  <Box
                    sx={{ display: "flex", alignItems: "center", paddingRight: theme.spacing(1) }}>
                    <Typography
                      variant="pre-title-2"
                      sx={{ textTransform: "uppercase", lineHeight: 1 }}>
                      {LANGUAGES[language as keyof typeof LANGUAGES]}
                    </Typography>
                  </Box>
                  <Box paddingRight={theme.spacing(0.5)}> · </Box>
                  <BondingCurveTooltipTrigger
                    ref={bondingCurveTooltipRef}
                    isOpen={isBondingCurveTooltipOpen}
                    onClick={() => setIsBondingCurveTooltipOpen(!isBondingCurveTooltipOpen)}>
                    <Typography
                      variant="pre-title-2"
                      sx={{
                        cursor: "pointer",
                        textTransform: "uppercase",
                        lineHeight: 1,
                        whiteSpace: "nowrap",
                        marginRight: theme.spacing(0.5),
                      }}>
                      {bondingCurveType} Mode
                    </Typography>
                    <HelpIcon />

                    {isBondingCurveTooltipOpen && (
                      <Box
                        position={"absolute"}
                        top={24}
                        left={"50%"}
                        zIndex={10}
                        sx={{
                          transform: "translateX(-50%)",
                        }}>
                        <BondingCurveTooltip type={bondingCurveType} />
                      </Box>
                    )}
                  </BondingCurveTooltipTrigger>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                height: "42px",
                width: "40px",
              }}>
              <RetroButton
                onClick={async () => {
                  const shareLink = getTgShareLinkWithParams({
                    groupId: data.tgGroupId,
                  });
                  const isMobile = checkIsMobile();
                  if (!!navigator.share && isMobile) {
                    try {
                      await navigator.share({
                        url: shareLink,
                      });
                    } catch (e) {
                      console.error("Error sharing the link", e);
                    }
                  } else {
                    copyToClipboard(shareLink);
                    showToast({
                      variant: "success",
                      message: "Copied shared link to clipboard.",
                      duration: 3000,
                    });
                  }
                }}>
                <Share />
              </RetroButton>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap={theme.spacing(1.5)}>
            <Box sx={{ display: "flex", gap: theme.spacing(1.5) }}>
              <ContentCard title="Member" sx={{ flex: 1 }}>
                <TooltipTrigger
                  ref={memberTooltipRef}
                  isOpen={isMemberTooltipOpen}
                  onClick={() => setIsMemberTooltipOpen(!isMemberTooltipOpen)}>
                  <Box position={"absolute"} top={-13} left={"50%"}>
                    <HelpIcon />
                  </Box>
                </TooltipTrigger>
                <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  <Typography variant="title-h2" sx={{ minWidth: theme.spacing(2) }}>
                    {abbreviateNumber(membersCount)}
                  </Typography>
                </Box>
              </ContentCard>
              <ContentCard title="Win RATE (7D)" sx={{ flex: 1 }}>
                <TooltipTrigger
                  ref={winRateTooltipRef}
                  isOpen={isWinRateTooltipOpen}
                  onClick={() => setIsWinRateTooltipOpen(!isWinRateTooltipOpen)}>
                  <Box position={"absolute"} top={-13} left={"50%"}>
                    <HelpIcon />
                  </Box>
                </TooltipTrigger>
                <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
                  {totalWins === 0 && totalCalls === 0 ? (
                    <Typography variant="title-h2">N/A</Typography>
                  ) : (
                    <Typography variant="title-h2">
                      {totalWins} / {totalCalls}
                    </Typography>
                  )}
                </Box>
              </ContentCard>
            </Box>
            <ContentCard title="Alpha performance">
              <TooltipTrigger
                ref={performanceTooltipRef}
                isOpen={isPerformanceTooltipOpen}
                onClick={() => setIsPerformanceTooltipOpen(!isPerformanceTooltipOpen)}>
                <Box
                  position={"absolute"}
                  top={-13}
                  sx={{
                    transform: "translateX(75%)",
                  }}>
                  <HelpIcon />
                </Box>
              </TooltipTrigger>
              <Box sx={{ display: "flex", gap: theme.spacing(1), flexWrap: "wrap" }}>
                {[
                  {
                    title: "24H",
                    value: day1Performance,
                  },
                  {
                    title: "7D",
                    value: day7Performance,
                  },
                  // {
                  //   title: "14D",
                  //   value: day14Performance,
                  // },
                ]?.map((item) => (
                  <PerformanceWrapper>
                    <Typography variant="title-h2">{item.title}</Typography>
                    {item.value === 0 || item.value.toFixed(2) === "0.00" ? (
                      <Brick variant="dark">
                        <Typography variant="body-sm">N/A</Typography>
                      </Brick>
                    ) : (
                      <PercentageBrick percentage={item.value} />
                    )}
                  </PerformanceWrapper>
                ))}
              </Box>
            </ContentCard>
            <ContentCard title="Best performing alpha">
              <TooltipTrigger
                ref={bestPerformanceTooltipRef}
                isOpen={isBestPerformanceTooltipOpen}
                onClick={() => setIsBestPerformanceTooltipOpen(!isBestPerformanceTooltipOpen)}>
                <Box
                  position={"absolute"}
                  top={-13}
                  sx={{
                    transform: "translateX(75%)",
                  }}>
                  <HelpIcon />
                </Box>
              </TooltipTrigger>
              <Box height="12px" />
              <InnerContentBox>
                <FeaturedTokenCall cabalCall={data.bestCabalCall} />
              </InnerContentBox>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>
      <RetroCard>
        <Box
          sx={{
            padding: theme.spacing(2.5),
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}>
          <Typography variant="title-h3">ENTRY PRICE</Typography>
          <ContentCard title="ENTRY PRICE">
            <TooltipTrigger
              ref={entryPriceTooltipRef}
              isOpen={isEntryPriceTooltipOpen}
              onClick={() => setIsEntryPriceTooltipOpen(!isEntryPriceTooltipOpen)}>
              <Box
                position={"absolute"}
                top={-13}
                sx={{
                  transform: "translateX(75%)",
                }}>
                <HelpIcon />
              </Box>
            </TooltipTrigger>
            <Box display="flex" justifyContent="start" alignItems="end" gap="4px">
              <Box marginBottom="-4px">
                <Typography variant="title-h2">
                  {!isNativeTokenPriceLoading && minimumEntryInUsd !== undefined
                    ? `$${minimumEntryInUsd.toPrecision(2)}`
                    : "Loading..."}
                </Typography>
              </Box>
              <Typography variant="body-sm">
                {!isNativeTokenPriceLoading && minimumEntryInUsd !== undefined
                  ? `(10K ${data.symbol})`
                  : null}
              </Typography>
            </Box>
          </ContentCard>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "6/5",
              borderRadius: theme.spacing(3),
              border: `1px solid ${theme.palette.border.dark}`,
              overflow: "hidden",
            }}>
            <Box
              sx={{
                width: "100%",
                aspectRatio: "6/5",
                overflow: "hidden",
              }}>
              {!isPriceDataLoading && priceDataConsolidated.length > 0 ? (
                <JettonChart data={priceDataConsolidated.map(transformPriceData) ?? []} />
              ) : isPriceDataLoading ? (
                <LoadingBlock
                  isLoading={
                    !isPriceDataLoading && priceDataConsolidated.length > 0 && priceDataConsolidated
                  }>
                  <Typography variant="title-h2">Loading...</Typography>
                </LoadingBlock>
              ) : (
                <Box height={"100%"}>
                  <Box
                    padding={theme.spacing(2)}
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}>
                    <LogoIcon />
                    <Box
                      sx={{
                        textAlign: "center",
                        color: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        marginTop: "24px",
                      }}>
                      <Typography variant="title-h2">No data yet</Typography>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              width: "100%",
              justifyContent: "center",
            }}>
            {[
              { label: "1m", value: "minute" },
              { label: "1h", value: "hour" },
              { label: "1d", value: "day" },
            ].map((item) => (
              <RetroButton
                size="sm"
                onClick={() => setTimeInterval(item.value as "minute" | "hour" | "day" | "week")}
                disabled={timeInterval === item.value}>
                <Box paddingX={2}>
                  <Typography variant="body-default">{item.label}</Typography>
                </Box>
              </RetroButton>
            ))}
          </Box>
          {/* OLD */}
        </Box>
      </RetroCard>

      <RetroCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
            padding: theme.spacing(2.5),
          }}>
          <Box display={"flex"} alignItems={"center"} gap={theme.spacing(1)}>
            <Typography variant="title-h3">Your {data.symbol} holding</Typography>
            <Cached
              onClick={() => refetchTargetTokenBalance()}
              sx={{
                cursor: "pointer",
                ...((isTargetTokenBalanceLoading || isTargetTokenBalanceRefetching) && {
                  animation: "spin 3s linear infinite",
                  "@keyframes spin": {
                    "0%": {
                      transform: "rotate(360deg)",
                    },
                    "100%": {
                      transform: "rotate(0deg)",
                    },
                  },
                }),
              }}
            />
          </Box>

          <ContentCard title="USD Value" sx={{ flex: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: theme.spacing(1) }}>
              <Typography variant="title-h2">${(targetBalanceUsd ?? 0).toFixed(2)}</Typography>
              {!targetBalanceAmount || targetBalanceAmount === 0 ? null : (
                <PercentageBrick percentage={targetBalancePerformance ?? 0} />
              )}
            </Box>
          </ContentCard>
          <Box sx={{ display: "flex", gap: theme.spacing(1) }}>
            <ContentCard title="Quantity" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography
                  variant="subtitle"
                  sx={{
                    display: "flex",
                  }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {abbreviateNumber(targetBalanceAmount ?? 0)}
                  </span>
                </Typography>
              </Box>
            </ContentCard>
            <ContentCard title="P&L" sx={{ flex: 1, maxWidth: "50%" }}>
              <Box>
                <Typography variant="subtitle" sx={{ display: "flex" }}>
                  <span
                    style={{
                      overflow: "hidden",
                      maxWidth: "100%",
                      textOverflow: "ellipsis",
                    }}>
                    {(targetBalancePerformance ?? 0).toFixed(2)}
                  </span>
                  <span style={{ marginLeft: "4px" }}>USD</span>
                </Typography>
              </Box>
            </ContentCard>
          </Box>
        </Box>
      </RetroCard>
      <Box height="100px" />
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 3,
          paddingBottom: theme.spacing(1),
        }}>
        <RetroCard narrowOuter size="sm">
          <Box
            sx={{
              padding: theme.spacing(1, 1.5),
              height: "80px",
              display: "flex",
              gap: theme.spacing(1),
            }}>
            {tgUserId === data.tgUserId ||
            isTonBoardcasting ||
            (!!targetBalanceAmount && +targetBalanceAmount >= MIN_ENTRY_AMOUNT) ? (
              <RetroButton onClick={handleJoinGroup} variant="white" disabled={isJoiningGroup}>
                <Typography variant="title-h3">GO TO CHAT</Typography>
              </RetroButton>
            ) : null}
            {isLoggedIn ? (
              <RetroButton
                onClick={handleTradeClick}
                disabled={
                  !isPolling &&
                  (isTrading ||
                    isTargetTokenBalanceLoading ||
                    isTargetTokenBalanceRefetching ||
                    isTradeIniting)
                }>
                <Typography variant="title-h3">
                  {!isPolling &&
                  (isTrading ||
                    isTargetTokenBalanceLoading ||
                    isTargetTokenBalanceRefetching ||
                    isCreatingWallet ||
                    isTradeIniting)
                    ? "Loading..."
                    : tgUserId === data.tgUserId ||
                      isTonBoardcasting ||
                      (!!targetBalanceAmount && +targetBalanceAmount >= MIN_ENTRY_AMOUNT)
                    ? "Trade"
                    : "Buy Tokens to Join Group"}
                </Typography>
              </RetroButton>
            ) : (
              <RetroButton onClick={handleLogin}>
                <Typography variant="title-h3">Login</Typography>
              </RetroButton>
            )}
          </Box>
        </RetroCard>
      </Box>
      <CrossChainDepositModal
        defaultChain="ton"
        isOpen={isDepositModalOpen}
        close={() => setIsDepositModalOpen(false)}
        chains={CHAIN_CHECK_LIST}
        onCopySuccess={() => {
          setIsDepositModalOpen(false);
          showToast({ variant: "success", message: "Copied address to clipboard", duration: 3000 });
        }}
      />
      <BuyJettonReminderModal handleTradeClick={handleTradeClick} />
      <JettonTradeDrawer
        tgGroupId={data.tgGroupId}
        minEntryPrice={minimumEntryInUsd ?? 0}
        bondingCurveType={bondingCurveType}
        open={shouldTradeDrawerOpen}
        onClose={() => {
          refetchTargetTokenBalance();
          setShouldTradeDrawerOpen(false);
        }}
        onProcessed={() => {
          refetchTargetTokenBalance();
        }}
        onConfirm={() => {
          setShouldTradeDrawerOpen(false);
        }}
      />
      {/* Tooltips */}
      <DefinitionTooltip term="Member" isShow={isMemberTooltipOpen}>
        <Typography variant="body-default">Number of users in the alpha group</Typography>
      </DefinitionTooltip>
      <DefinitionTooltip term={`${bondingCurveType} Pricing`} isShow={isBondingCurveTooltipOpen}>
        <BondingCurveTooltip type={bondingCurveType} />
      </DefinitionTooltip>
      <DefinitionTooltip term="WIN RATE (7D)" isShow={isWinRateTooltipOpen}>
        <Typography variant="body-default">
          The percentage of tokens that increased in price out of all calls made in the past 7 days.
        </Typography>
      </DefinitionTooltip>
      <DefinitionTooltip term="Alpha performance" isShow={isPerformanceTooltipOpen}>
        <Typography variant="body-default">
          The average price change per call, calculated as (sell price or unrealized price – called
          price) / total calls.
        </Typography>
      </DefinitionTooltip>
      <DefinitionTooltip term="Best performing alpha" isShow={isBestPerformanceTooltipOpen}>
        <Typography variant="body-default">
          The group's top token call with the highest price increase in the past 7 days (24-hour
          delay).
        </Typography>
      </DefinitionTooltip>
      <DefinitionTooltip term="ENTRY PRICE" isShow={isEntryPriceTooltipOpen}>
        <Typography variant="body-default">
          Users must purchase 10K group tokens to join the chat. This shows the USD price of 10K
          tokens, payable in ETH, SOL, or TON.
        </Typography>
      </DefinitionTooltip>
    </>
  );
};

const CabalIdPage = () => {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ["cabal-details", id],
    queryFn: () => axiosService.getCabalDetails(id!),
    enabled: !!id,
  });

  return (
    <FullPageWrapper>
      <Container>
        <Box paddingY={theme.spacing(2.5)}>
          <Button
            sx={{ padding: "0", cursor: "pointer" }}
            onClick={() => navigate(ROUTES.explorer)}>
            <BackArrow />
            <Typography variant="button-md" color="text.secondary">
              Groups
            </Typography>
          </Button>
        </Box>
      </Container>
      <Container sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(1) }}>
        {isLoading ? (
          <LoadingView isLoading={isLoading}>
            <Typography variant="title-h2">Loading...</Typography>
          </LoadingView>
        ) : !data ? (
          <CabalListEmptyView />
        ) : (
          <TradeProvider
            chain="ton"
            nativeTokenConfig={{
              ton: {
                chain: "ton",
                symbol: "TON",
                token: data.masterAddress,
              },
              solana: {
                chain: "solana",
                symbol: "SOL",
                token: NATIVE_TOKEN_ADDRESS_MAP["solana"],
              },
              ethereum: {
                chain: "ethereum",
                symbol: "ETH",
                token: NATIVE_TOKEN_ADDRESS_MAP["ethereum"],
              },
              base: {
                chain: "base",
                symbol: "ETH",
                token: NATIVE_TOKEN_ADDRESS_MAP["base"],
              },
            }}
            targetTokenConfig={{
              ton: {
                chain: "ton",
                symbol: data.symbol,
                token: data.masterAddress,
                imageUrl: data.imageUri,
              },
            }}>
            <CabalDetailsRenderer data={data} />
          </TradeProvider>
        )}
      </Container>
    </FullPageWrapper>
  );
};

export { CabalIdPage };
