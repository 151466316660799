import { ColorType, CrosshairMode, MouseEventParams, Time } from "lightweight-charts";
import { BasicChart, IChartConfig } from "./BasicChart";
import theme from "../../theme";
import { useChart } from "hooks/useChart";
import { useEffect, useRef, useState } from "react";
import { ChartTooltipContainer } from "./ChartTooltip";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { toFixedIfNecessary } from "utils";

const configs: IChartConfig = {
  base: {
    layout: {
      background: {
        type: ColorType.Solid,
        color: theme.palette.surface.silver,
      },
      textColor: theme.palette.text["btn-dark"],
    },
    grid: {
      horzLines: {
        visible: false,
      },
      vertLines: {
        visible: false,
      },
    },
    rightPriceScale: {
      visible: false,
    },
    leftPriceScale: {
      visible: false,
    },
    localization: {
      timeFormatter: (time: any) => {
        return time.toString();
      },
    },
    timeScale: {
      tickMarkFormatter: (time: any) => {
        return time.toString();
      },
      visible: false,
      fixLeftEdge: true,
      fixRightEdge: true,
      lockVisibleTimeRangeOnResize: true,
    },
    crosshair: {
      vertLine: {
        color: theme.palette.border.light,
        width: 2,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        visible: false,
      },
      mode: CrosshairMode.Normal,
    },
    height: 0,
  },
  series: [
    {
      id: "token-price", // will affect the tooltip
      area: {
        lineColor: theme.palette.brand.primary,
        topColor: "#FFC94996",
        bottomColor: theme.palette.surface.silver,
        crosshairMarkerVisible: false,
      },
      line: {
        color: theme.palette.brand.primary,
        lineWidth: 1,
        // crosshairMarkerRadius: 10,
        crosshairMarkerVisible: false,
        crosshairMarkerBorderColor: theme.palette.text["btn-dark"],
        crosshairMarkerBackgroundColor: theme.palette.surface.light,
        crosshairMarkerBorderWidth: 3,
      },
    },
  ],
};

export const TokenDetailChart = ({ data }: { data: { time: number; value: number | null }[] }) => {
  const {
    chartRef,
    seriesRef,
    setIsMounted,
    isMounted,
    subscribeCrosshairMove,
    unsubscribeCrosshairMove,
  } = useChart();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [point, setPoint] = useState<{
    x: number;
    y: number;
    data: {
      time: number;
      value: number;
    };
  } | null>(null);

  const [isAutoPrice, setIsAutoPrice] = useState(true);

  useEffect(() => {
    if (data.length > 0) {
      chartRef.current?.timeScale().fitContent();
    }
  }, [data, chartRef]);

  useEffect(() => {
    const handleOnCrosshairMove = (params: MouseEventParams<Time>) => {
      setIsAutoPrice(false);
      if (!params.point) {
        handleSetLatestPrice();
        return;
      }

      if (!containerRef.current) {
        return;
      }

      const series = seriesRef.current["token-price"]["area"];
      const data = params.seriesData.get(series) as {
        time: number;
        value: number;
      };

      if (!data || !data.value) {
        handleSetLatestPrice();
        return;
      }

      const y = series.priceToCoordinate(data.value);

      console.log("params.point.x", params.point.x);

      setPoint({ x: params.point.x, y, data });
    };

    if (isMounted) {
      handleSetLatestPrice();
      unsubscribeCrosshairMove(handleOnCrosshairMove);
      subscribeCrosshairMove(handleOnCrosshairMove);
    }
  }, [isMounted, data]);

  useEffect(() => {
    if (point === null) {
      setIsAutoPrice(true);
    }
  }, [point]);

  useEffect(() => {
    handleSetLatestPrice();
  }, [data[data.length - 1]]);

  const handleSetLatestPrice = () => {
    if (data && data[data.length - 1]) {
      const { value, time } = data[data.length - 1];
      if (value !== null && time !== null) {
        setPoint({
          x: 0, // or any appropriate value
          y: 0, // or any appropriate value
          data: {
            value,
            time,
          },
        });
      }
    } else {
      setPoint(null);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}>
      <BasicChart
        data={data}
        configs={configs}
        chartRef={chartRef}
        seriesRef={seriesRef}
        chartContainerRef={containerRef}
        setIsMounted={setIsMounted}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          position: "absolute",
          top: "4px",
          left: "12px",
          zIndex: 10,
          width: "100%",
          height: "100%",
          pointerEvents: "none",
        }}>
        <Typography variant="title-h2">
          {point ? `$${toFixedIfNecessary(`${point.data.value}`, 6)}` : null}
        </Typography>
        <Typography variant="body-sm" color={theme.palette.brand.primary}>
          {point ? `${format(new Date(point.data.time), "hh:mmaaa,MMM d").toUpperCase()}` : null}
        </Typography>
      </Box>
    </Box>
  );
};
