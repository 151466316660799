import { useCallback } from "react";
import { StyledBlock, StyledTopText, StyledCardBody } from "pages/jetton/styled";
import LoadingImage from "components/LoadingImage";
import { Box, Typography } from "@mui/material";
import { DECIMAL_SCALER } from "consts";
import useJettonStore from "store/jetton-store/useJettonStore";
import brokenImage from "assets/icons/question.png";
import UserImg from "assets/icons/userGrey.svg";
import { TradeButton } from "../trade/styled";
import axiosService from "services/axios";
import { useTonAddress } from "@tonconnect/ui-react";
import useNotification from "hooks/useNotification";
import useUserStore from "store/user-store/useUserStore";

export const Token = () => {
  const {
    userBalance,
    jettonImage,
    name,
    jettonLoading,
    isImageBroken,
    selectedJetton,
    jettonMaster,
  } = useJettonStore();
  const { showNotification } = useNotification();
  const { tgUserName = "degenHiggs" } = useUserStore();
  const walletAddress = useTonAddress();

  const onClickJoinGame = useCallback(async () => {
    if (!tgUserName) {
      return showNotification("Please switch to telegram.", "warning");
    }

    if (!jettonMaster || !walletAddress) {
      return;
    }

    try {
      const { res } = await axiosService.joinGroup({
        masterAddress: jettonMaster,
        walletAddress,
        tgUserName,
      });

      if (res.invite_link) {
        // window.open(res.invite_link, "_blank");
      } else if (res.status === "success") {
        // window.open("tg://", "_blank");
        showNotification("Join the group successfully.", "success");
      } else {
        showNotification(res.message ?? "Telegram error", "error");
      }
    } catch (error) {
      showNotification("Failed to join the group. Please try again.", "error");
      console.error("Error joining group:", error);
      return;
    }
  }, [jettonMaster, showNotification, tgUserName, walletAddress]);

  const renderJoinGame = () => {
    if (userBalance > 0) {
      console.log(
        "jettonMaster && tgUserName && walletAddress",
        jettonMaster && tgUserName && walletAddress,
      );
      return (
        <TradeButton
          disabled={!(jettonMaster && tgUserName && walletAddress)}
          background="#FFB800"
          onClick={onClickJoinGame}>
          JOIN CABAL
        </TradeButton>
      );
    }
  };

  return (
    <StyledBlock height="100%">
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}>
        <Box sx={{ border: "2px, solid, white", height: "56px", width: "56px" }}>
          <LoadingImage
            src={!isImageBroken ? jettonImage : brokenImage}
            alt="jetton image"
            loading={jettonLoading}
          />
        </Box>
        <Box display="flex" flexDirection={"column"} flexGrow={1} sx={{ paddingLeft: "16px" }}>
          <StyledTopText>{name}</StyledTopText>
          <Box display="flex" flexDirection={"row"} gap={1}>
            <img src={UserImg} alt="user" width={"13px"} />
            <StyledCardBody>{selectedJetton?.players?.length} members in cabal</StyledCardBody>
          </Box>
        </Box>
      </Box>
      {userBalance > 0 && (
        <Typography fontSize={20} color="#FFB800" mt={1}>
          Your Amount: {(userBalance / DECIMAL_SCALER).toFixed(2)}
        </Typography>
      )}

      {renderJoinGame()}
    </StyledBlock>
  );
};
