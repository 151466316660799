import { useRef, useState } from "react";
import { useOnClickOutside } from "usehooks-ts";

export const useTooltips = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(tooltipRef, () => setIsTooltipOpen(false));

  return {
    isTooltipOpen,
    tooltipRef,
    setIsTooltipOpen,
  };
};
