import { Box, Modal, Typography } from "@mui/material";
import { CircleButton } from "components/CircleButton";
import { ModalWrapper, Overlay } from "components/portfolio";
import { RetroButton } from "components/RetroButton";
import { RetroCard } from "components/RetroCard";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ShareToFriendIcon } from "assets/icons/redeem-benefit-1.svg";

export const BuyJettonReminderModal = ({ handleTradeClick }: { handleTradeClick: () => void }) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const isShowBuyNowModal = search ? !!new URLSearchParams(search).get("isShowBuyNowModal") : false;

  const [isOpen, setIsOpen] = useState<boolean>(isShowBuyNowModal);

  const handleCloseModal = () => {
    setIsOpen(false);
    navigate({ search: "" });
  };

  const handleTrade = () => {
    handleTradeClick();
    handleCloseModal();
  };

  return isOpen ? (
    <Overlay>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={isOpen}
        onClose={handleCloseModal}>
        <ModalWrapper>
          <RetroCard>
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "100%",
                padding: "16px",
                gap: "16px",
                width: "100%",
              }}>
              <Box
                sx={{
                  position: "absolute",
                  top: "16px",
                  right: "16px",
                }}>
                <CircleButton variant="white" onClick={handleCloseModal}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    X
                  </Box>
                </CircleButton>
              </Box>
              <Box>
                <ShareToFriendIcon />
              </Box>
              <Typography variant="title-h2">BUY YOUR FIRST POSITION!</Typography>
              <Typography variant="body-md">
                To maximize your upside, we encourage you to purchase your first position of your
                token as early as possible.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  padding: "20px",
                  gap: "16px",
                  width: "100%",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}>
                  <RetroButton variant="primary" onClick={handleTrade}>
                    <Typography variant="title-h3" sx={{ padding: "12px 24px" }}>
                      BUY NOW
                    </Typography>
                  </RetroButton>
                </Box>
              </Box>
            </Box>
          </RetroCard>
        </ModalWrapper>
      </Modal>
    </Overlay>
  ) : (
    <></>
  );
};
