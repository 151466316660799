import { ROUTES } from "consts";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosService from "services/axios";
import useToastStore from "store/toast-store/useToastStore";

export const useRedeemCode = ({ isAutoCheck = false }: { isAutoCheck?: boolean }) => {
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [isCheckingRedeemed, setIsCheckingRedeemed] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const isCheckingRedeemedRef = useRef(false);
  const { showToast } = useToastStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAutoCheck && !isChecked && !isCheckingRedeemedRef.current && !isRedeemed) {
      isCheckingRedeemedRef.current = true;
      setIsCheckingRedeemed((pre) => true);

      setIsRedeemed(!!localStorage.getItem("redeemedCode"));
      setIsChecked(true);
      isCheckingRedeemedRef.current = false;
      setIsCheckingRedeemed((pre) => false);

      if (!!localStorage.getItem("redeemedCode")) {
        navigate(ROUTES.explorer);
      } else {
        navigate(ROUTES.gated);
      }
    }
  }, []);

  const redeemCode = async (code: string) => {
    let message;
    try {
      setIsCheckingRedeemed(true);
      showToast({ variant: "info", message: "Checking code..." });

      const { res }: any = await axiosService.redeemCode(code);
      message = res.message;

      if (res.status >= 200 && res.status < 300) {
        showToast({ variant: "success", message, duration: 5000 });
        localStorage.setItem("redeemedCode", code);
        setIsRedeemed(true);
        navigate(ROUTES.explorer);
      } else {
        console.error("Invalid code: ", message);
        showToast({ variant: "error", message: "Invalid code", duration: 5000 });
        localStorage.removeItem("redeemedCode");
        setIsRedeemed(false);
      }
    } catch (error) {
      console.error("Invalid code: ", error);
      showToast({ variant: "error", message: "Invalid code", duration: 5000 });
      setIsRedeemed(false);
    } finally {
      setIsCheckingRedeemed(false);
    }
  };

  return {
    isCheckingRedeemed,
    isRedeemed,
    redeemCode,
  };
};
