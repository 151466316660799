import { atom } from "recoil";

type ToastState = {
  variant: "success" | "error" | "info";
  message?: string;
  expiredTs?: number | null;
};

const toastStateAtom = atom<ToastState>({
  key: "toastStateAtom",
  default: {
    variant: "info",
    message: undefined,
    expiredTs: null,
  },
});

export { toastStateAtom };
